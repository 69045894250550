import React, { createContext, useState, useContext } from "react";

const AccSidebarContext = createContext();

export const AccSidebarProvider = ({ children }) => {
  const [isAccSidebarVisible, setIsAccSidebarVisible] = useState(false);

  const showAccSidebar = () => setIsAccSidebarVisible(true);
  const hideAccSidebar = () => setIsAccSidebarVisible(false);

  return (
    <AccSidebarContext.Provider
      value={{ isAccSidebarVisible, showAccSidebar, hideAccSidebar }}
    >
      {children}
    </AccSidebarContext.Provider>
  );
};

export const useAccSidebarToggle = () => useContext(AccSidebarContext);
