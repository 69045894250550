import { React, useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import { Link } from "react-router-dom";
// import { medicalRecords } from "../../../../services/data";
import Sidebar from "../../../common/sidebar/Sidebar";
import Footer from "../../../common/footer/Footer";
import "./HospitalRecordList.css";
import ErrorPage from "../../../common/error-page/ErrorPage";
const MedicalRecordList = () => {
  const [medicalRecordsData, setMedicalRecordsData] = useState();
  const [medicalRecordsDataErr, setMedicalRecordsDataErr] = useState(false);
  const {
    data: medicalRecordsDataRes,
    loading: medicalRecordsLoading,
    error: medicalRecordsError,
    getRequest,
  } = useFetch("/api/medicalRecords");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicalRecordsDataRes?.data) {
      setMedicalRecordsData(medicalRecordsDataRes.data);
    }
    if (medicalRecordsError) {
      setMedicalRecordsDataErr(true);
    }
  }, [medicalRecordsDataRes, medicalRecordsError]);

  return (
    <>
      <div id="medical-record-list">
        <Sidebar />
        <SearchRecord />
        <div className="container">
          {medicalRecordsDataErr && <ErrorPage />}
          {medicalRecordsData &&
            medicalRecordsData.map((record) => (
              <div
                key={record.id}
                id="record-container"
                className="form-container"
              >
                <h3>
                  {record.date} - {record.type}
                </h3>

                <p>Patient Name:{record.patientName}</p>
                <p>Phone:{record.phoneNumber}</p>
                <p>Description {record.description}</p>

                <h4>Results:</h4>
                {record?.results.map(({ name, value }) => (
                  <li key={name}>
                    {name}: {value}
                  </li>
                ))}
                <p>Explanation: {record.explanation}</p>
              </div>
            ))}
        </div>

        <Footer />
      </div>
    </>
  );
};

const SearchRecord = () => {
  const [medicalRecordsData, setMedicalRecordsData] = useState();
  const [medicalRecordsDataErr, setMedicalRecordsDataErr] = useState(false);
  const {
    data: medicalRecordsDataRes,
    loading: medicalRecordsLoading,
    error: medicalRecordsError,
    getRequest,
  } = useFetch("/api/medicalRecords");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicalRecordsDataRes?.data) {
      setMedicalRecordsData(medicalRecordsDataRes.data);
    }
    if (medicalRecordsError) {
      setMedicalRecordsDataErr(true);
    }
  }, [medicalRecordsDataRes, medicalRecordsError]);

  const [upload, setUpload] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null); // Track selected record

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRecordClick = (record) => {
    setSelectedRecord(record); // Set selected record for detailed view
  };

  const handleUpload = (e) => {
    setUpload(e.target.upload[0]);
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);

    const filteredData = medicalRecordsData.filter((record) =>
      record.phoneNumber.includes(searchTerm)
    );
    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes
  return (
    <>
      <section id="search">
        <div className="medicine-nav-container">
          <div className="search-container">
            <div className="search-bar search">
              <div className="search-wrapper">
                <div>
                  <input
                    type="text"
                    placeholder="Search for record"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  ></input>
                </div>
                <div className="search-list-container">
                  <ul className="lists">
                    {searchResults.length > 0 // Check if results exist before rendering
                      ? searchResults.map((record) => (
                          <li
                            key={record.id}
                            onClick={() => handleRecordClick(record)}
                          >
                            <Link
                              className="text-primary name"
                              to={`/hospital_record_detail/${record.id}`}
                            >
                              {record.phoneNumber}
                            </Link>
                          </li>
                        ))
                      : searchTerm && <p>No results found.</p>}{" "}
                    {/* {cartItems.length > 0 && <ViewCart />} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </section>
    </>
  );
};
export { SearchRecord };

export default MedicalRecordList;
