import React, { useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import ErrorPage from "../../../common/error-page/ErrorPage";
import { useParams } from "react-router-dom";
import { medicineData, cartItems } from "../../../../services/data.js";
import Footer from "../../../common/footer/Footer.jsx";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
import { SearchMedicine } from "../../patient/pharmacy-user-portal/PharmacyUserPortal.jsx";

import "./MedicineMainInfo.css";

const MedicineMainInfo = () => {
  const [medicineData, setMedicineData] = useState();
  const [medicineDataErr, setMedicineDataErr] = useState(false);
  const {
    data: medicineDataRes,
    loading: medicineLoading,
    error: medicineError,
    getRequest,
  } = useFetch("/api/medicine");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicineDataRes?.data) {
      setMedicineData(medicineDataRes.data);
    }
    if (medicineError) {
      setMedicineDataErr(true);
    }
  }, [medicineDataRes, medicineError]);
  const { id } = useParams();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false); //for the popup message
  const selectedMedicine = medicineData.find((medicine) => medicine.id === id);
  //   const { name, dosage, pain, image } = selectedMedicine;
  //   console.log(selectedMedicine);

  const handleAddToCartClick = () => {
    // Add code to actually add the item to the cart (not shown here)
    setShowConfirmationPopup(true); // Show the confirmation popup
  };

  const handleClosePopup = () => {
    setShowConfirmationPopup(false); // Hide the confirmation popup
  };

  const handleConfirmAddToCart = () => {
    const cartItem = { ...selectedMedicine }; // Create a copy
    cartItems.push(cartItem);
    // Add the item to the cart (if not already done in handleAddToCartClick)
    setShowConfirmationPopup(false); // Hide the confirmation popup after confirmation
    console.log(cartItem);
  };

  return (
    <>
      {medicineDataErr && <ErrorPage />}
      {medicineData && (
        <div id="medicine-detail">
          <Sidebar />
          <SearchMedicine />
          {/* <UserPortal /> */}
          <div id="head">
            <h2>Medicine Details</h2>
            <div id="content-wrapper">
              <div className="right-container">
                <img
                  src={selectedMedicine?.image}
                  alt={selectedMedicine?.name}
                />
              </div>
              <div className="left-container">
                <h3>Name: {selectedMedicine?.name}</h3>
                <p>Dosage: {selectedMedicine?.dosage}</p>
                <p>It cures from: {selectedMedicine?.pain}</p>
                <h3>Description</h3>
                <p>
                  A Ret-HC Cream is a combination of Tretinoin, Hydroquinone and
                  Hydrocortisone. It is used for the treatment of skin hyper
                  pigmentation (darkened patches or spots on the skin). This
                  medicine works by reducing the pigmentation and swelling on
                  your skin. A Ret-HC Cream can have side effects like burning
                  or stinging at the application site, itchy or dry skin etc.
                  Most of them are short-lasting and resolve on their own.
                  Consult your doctor if these side effects are getting worse or
                  persistent.
                </p>
              </div>
            </div>
          </div>
          <div className="popup">
            <div className="add-to-cart-button">
              <button onClick={handleAddToCartClick}>Add to Cart</button>
            </div>
            {showConfirmationPopup && (
              <div id="confirmation-popup" className="hidden">
                <div className="popup-container">
                  <h3 className="popup-title">Confirm Action</h3>
                  <p className="popup-message">
                    Are you sure you want to add this item to your cart?
                  </p>
                  <div className="button-container">
                    <button
                      id="confirm-button"
                      className="primary-button"
                      onClick={handleConfirmAddToCart}
                    >
                      Yes
                    </button>
                    <button
                      id="cancel-button"
                      className="secondary-button"
                      onClick={handleClosePopup}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};
export default MedicineMainInfo;
