import { useRoutes } from "react-router-dom";
import Dashboard from "./Dashboard";
import AdminRoleSelection from "../../admin/sign-up-category/AdminSignUpCategory";
import AdminLogin from "../login-form/AdminLogin";
import { useAdminAccAuth } from "../../../contexts/AdminAccountAuth";
import UserTable from "../../admin/tables/user-table/UserTable";
import AdminPostTable from "../../admin/tables/admin-post-table/AdminPostTable";
import AdminServiceTable from "../../admin/tables/admin-service-table/AdminServiceTable";
const DashboardRoutes = () => {
  const { authAdmin } = useAdminAccAuth();

  let mainRoutEl = [{ path: "/login", element: <AdminLogin /> }];
  const adminRoutEl = [
    { path: "/dashboard", element: <Dashboard /> },
    {
      path: "/register",
      element: <AdminRoleSelection />,
    },
    {
      path: "/users",
      element: <UserTable />,
    },
    {
      path: "/post",
      element: <AdminPostTable />,
    },
    {
      path: "/service",
      element: <AdminServiceTable />,
    },
    { path: "/*", element: <Dashboard /> },
  ];

  authAdmin.authenticated && (mainRoutEl = [...mainRoutEl, ...adminRoutEl]);
  const routes = useRoutes(mainRoutEl);

  return routes;
};

export default DashboardRoutes;
