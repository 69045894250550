import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Form,
  Select,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import usePost from "../../../../hooks/usePost";
import usePatch from "../../../../hooks/usePatch";
import useToast from "../../../common/toast/toast";
import useFetch from "../../../../hooks/useFetch";
import useUpload from "../../../../hooks/useUpload";
import AdminServiceForm from "../admin-service-form/AdminServiceForm";
const { Option } = Select;

const AdminServiceTable = () => {
  const { showToast } = useToast();
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("owner");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [updateRes, setUpdateRes] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  //// form modal state
  const [fileList, setFileList] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [addPost, setAddPost] = useState(false);
  //
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  //
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  //// form modal upload
  const { upload, reset: resetUpload } = useUpload("/api/service", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });
  //
  // const [postId, setPostId] = useState(null);
  const [form] = Form.useForm();

  //// post table getData
  const { data, loading, getRequest } = useFetch("/api/service");

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);
  const handleFieldChange = (value) => setSelectedField(value);

  const handleEdit = (record) => {
    setSelectedField(record.field);
    form.setFieldsValue(record);
    setEditPost(true);
    setAddPost(true);
    setIsModalVisible(true);
  };
  const handleAdd = async () => {
    setAddPost(true);
    setIsModalVisible(true);
  };

  const handleDelete = (record) => {
    // Perform  delete operation here
  };

  // form modal handlers
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      values.field = "ourService";
      // if (fileList.length === 0) {
      //   form.setFields([
      //     {
      //       name: "image",
      //       errors: ["Please upload an image!"],
      //     },
      //   ]);

      //   return;
      // }

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("image", file.originFileObj);
        }
      });

      // Perform the post request or other handling
      await upload(formData);

      setIsModalVisible(false);
    } catch (errorInfo) {}
  };

  useEffect(() => {
    getRequest({ field: "ourService" });
  }, [createRes]);
  useEffect(() => {
    if (createLoading) {
      showToast(createLoading.message?.replace("AxiosError:", ""));
    }
    if (createRes) {
      createRes.msg && showToast(createRes.msg);
      !createRes.msg && showToast(createRes.status);
    }
    if (createLoading || createRes) resetUpload();
  }, [createLoading, createLoading, createRes]);

  const handleModalCancel = () => setIsModalVisible(false);

  const filteredData = useMemo(() => {
    return data?.services?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [data?.services, searchText, selectedColumn, selectedField]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData?.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["OWNER"].toUpperCase()}
            </div>

            <div className="tableSubCols details">Title: {record["TITLE"]}</div>
            <div className="tableSubCols details">
              Available Date : {record["AVAILABLE DATE"]}
            </div>
          </> //0911429007
        ),
      },
      {
        title: "Service Details",
        key: "serviceDetails",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["CATEGORY"]}</div>
            <div className="tableSubCols details">
              Description: {record["DESCRIPTION"]}
            </div>
            <div className="tableSubCols details">
              Detail: {record["DETAIL"]}
            </div>
          </>
        ),
      },
      {
        title: "Service Details",
        key: "serviceDetails",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["SERVICE NAME"]}</div>
            <div className="tableSubCols details">
              Service Type: {record["SERVICE TYPE"]}
            </div>
            <div className="tableSubCols details">
              Specialization Type: {record["SPECIALIZATION TYPE"]}
            </div>
          </>
        ),
      },
    ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) =>
        ![
          "updatedAt",
          "createdAt",
          "link",
          "Dosage",
          "OWNER",
          "TITLE",
          "AVAILABLE DATE",
          "CATEGORY",
          "DESCRIPTION",
          "DETAIL",
          "SERVICE NAME",
          "SERVICE TYPE",
          "SPECIALIZATION TYPE",
          "FIELD",
        ].includes(key)
    );
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0).toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title:
        key === "createdAt" || key === "updatedAt"
          ? editCols(key)
          : key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    // dynamicColumns.push({
    //   // title: "Actions",
    //   // key: "actions",
    //   render: (text, record) => (
    //     <span>
    //       {/* <Button type="link" onClick={() => handleEdit(record)}>
    //         Edit
    //       </Button> */}
    //       <Popconfirm
    //         title="Are you sure you want to delete this user?"
    //         onConfirm={() => handleDelete(record)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         {/* <Button type="link" danger>
    //           Delete
    //         </Button> */}
    //       </Popconfirm>
    //     </span>
    //   ),
    // });

    return [...commonColumns, ...dynamicColumns];
  };

  const customColumns = useMemo(() => generateColumns(data?.services), [data]);

  return (
    <div className="container-table">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
          >
            {customColumns.map(
              (col) =>
                col.key !== "actions" && (
                  <Option key={col.key} value={col.key}>
                    {col.title}
                  </Option>
                )
            )}
          </Select>
        </Col>

        <Col flex="auto">
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => handleAdd()}
              icon={<PlusOutlined />}
            >
              Add Service
            </Button>
          </div>
        </Col>
      </Row>
      <DynamicTable
        data={paginatedData}
        tableActions={[]}
        loading={loading}
        customColumns={customColumns}
        scroll={{ x: 600 }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: filteredData?.length,
          onChange: handlePageChange,
        }}
      />

      <Modal
        title="Add Service"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <AdminServiceForm
          form={form}
          onPreview={onPreview}
          onChange={onChange}
          fileList={fileList}
          handleModalOk={handleModalOk}
        />
      </Modal>
    </div>
  );
};

export default AdminServiceTable;
