import React, { useState, useEffect } from "react";
import Sidebar from "../../../common/sidebar/Sidebar";
import Footer from "../../../common/footer/Footer";
import "./PatientMedicationDetails.css";
const PatientMedicationDetails = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const storedPrescriptions =
      JSON.parse(localStorage.getItem("prescriptions")) || [];
    setPrescriptions(storedPrescriptions);
    console.log(prescriptions);
  }, []);
  return (
    <>
      <div id="patient-medication-details">
        <Sidebar />
        <div className="container">
          <div className="form-container">
            <h2>Your Medications</h2>
            {prescriptions.map((prescription, i) => (
              <ul
                id="medication-container"
                className="border box-shadow py-2"
                key={i}
              >
                <li>
                  <strong> Medication</strong> {prescription.medication}
                </li>
                <li>
                  <strong> Dosage</strong> {prescription.dosage}
                </li>
                <li>
                  <strong> Instructions</strong> {prescription.instructions}
                </li>
              </ul>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PatientMedicationDetails;
