import React, { useEffect, useState } from "react";
import "./PatientGeneralFormHistory.css";
import Sidebar from "../../../common/sidebar/Sidebar";
import Footer from "../../../common/footer/Footer";
const formatKey = (key) => {
  return key
    .replace(/_/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
const PatientGeneralFormHistory = () => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const savedData = localStorage.getItem("patientGeneralData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
      console.log(formData);
    }
  }, []);

  if (!formData) {
    return <div>No data available.</div>;
  }

  return (
    <>
      <Sidebar />
      <div id="PatientGeneralFormHistory">
        <div className="container">
          <h3>Patient History</h3>
          <div>
            {Object.entries(formData).map(([key, value]) => (
              <div id="record-container" key={key}>
                <strong>{formatKey(key)}:</strong>{" "}
                {typeof value === "object" ? (
                  <div style={{ marginLeft: "20px" }}>
                    {Object.entries(value).map(([subKey, subValue]) => (
                      <div key={subKey}>
                        <strong>{formatKey(subKey)};</strong> {subValue}
                      </div>
                    ))}
                  </div>
                ) : value == "" ? (
                  "No"
                ) : (
                  value
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PatientGeneralFormHistory;
