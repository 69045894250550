import React, { useState } from "react";
import Modal from "../../../common/modal/Modal";
import "./PatientForm.css";
import { generalClinicalGuidanceFormData } from "../../../../services/data";
import { Link, useNavigate } from "react-router-dom";

const PatientForm = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [formData, setFormData] = useState({});

  const saveDataToLocalStorage = (data) => {
    try {
      const jsonData = JSON.stringify(data);

      // Save the JSON data to local storage
      localStorage.setItem("patientFormData", jsonData);
    } catch (error) {
      // Handle any errors that occur during JSON.stringify or localStorage.setItem
      console.error("Error saving data to local storage:", error);
    }
  };

  // Function to handle checkbox click and open modal
  const handleCheckboxClick = (symptom, data) => {
    // Ensure data is always an array
    const optionsArray = Array.isArray(data) ? data : [data];

    // Set the modal content
    setModalContent({ symptom, data: optionsArray });

    // Open the modal
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    // Prepare the form data
    const formDataUpdate = modalContent.data.reduce((acc, option) => {
      return { ...acc, [option.name]: null }; // Set the initial value as null
    }, {});

    // Update the form data with only the clicked checkbox data
    setFormData((prevData) => ({
      ...prevData,
      [modalContent.symptom]: formDataUpdate,
    }));

    // Save form data to local storage
    saveDataToLocalStorage(formDataUpdate);

    // Close the modal
    setIsOpen(false);
  };

  // Function to handle form submission and save form data
  const handleFormSubmit = () => {
    saveDataToLocalStorage(formData);
    navigate("/patient_history");
  };

  // Function to handle input change in form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div id="patientGeneralForm">
        <div className="patient-form-container">
          <div className="form-heading">
            <form className="form" onSubmit={handleFormSubmit}>
              <div className="form-label">
                <h3>Brief Medical History</h3>
              </div>
              {generalClinicalGuidanceFormData.map((categoryData, index) => (
                <div className="form-wrapper patient-form-wrapper" key={index}>
                  <div className="title">
                    {" "}
                    <h2>{categoryData.symptomCategory}</h2>{" "}
                  </div>
                  {categoryData.data.map((item, idx) => (
                    <div key={idx} className="content">
                      <div className="radio-wrapper">
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleCheckboxClick(item.name, item.options)
                          }
                        />
                        <label>{item.name}</label>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <Modal
                isOpen={isOpen}
                closeModal={handleCloseModal}
                symptom={modalContent.symptom}
                data={modalContent.data}
                onSave={handleCloseModal}
              />

              <button type="submit" className="btn patientFormData-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientForm;
