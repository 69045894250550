import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../../common/footer/Footer";
import Sidebar from "../../../common/sidebar/Sidebar";
// import { medicalProfessionalsData } from "../../../../services/data";
import ProfileImg from "../../../../image/service-img-5.png";
import useFetch from "../../../../hooks/useFetch";
import ErrorPage from "../../../common/error-page/ErrorPage";
import {
  FaCommentDots,
  FaThumbsUp,
  FaGem,
  FaCheckCircle,
} from "react-icons/fa";

import "./DoctorList.css";

const DoctorList = () => {
  const [medicalProfessionalsData, setMedicalProfessionalsData] = useState();
  const [medicalProfessionalsDataErr, setMedicalProfessionalsDataErr] =
    useState(false);
  const {
    data: medicalProfessionalsDataRes,
    loading: medicalProfessionalsLoading,
    error: medicalProfessionalsError,
    getRequest,
  } = useFetch("/api/medicalProfessionals");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    console.log("medical", medicalProfessionalsDataRes);
    if (medicalProfessionalsDataRes?.data) {
      setMedicalProfessionalsData(medicalProfessionalsDataRes.data);
    }
    if (medicalProfessionalsError) {
      setMedicalProfessionalsDataErr(true);
    }
  }, [medicalProfessionalsDataRes, medicalProfessionalsError]);

  // const data = JSON.parse(localStorage.getItem("doctorData"));
  // console.log(data);
  return (
    <>
      <div id="doctorList">
        <Sidebar />

        <div className="container">
          <SearchDoctor />
          {medicalProfessionalsDataErr && <ErrorPage />}
          {medicalProfessionalsData &&
            medicalProfessionalsData.map((item, i) => {
              const { firstName, lastName, email, Phone, active } = item;

              return (
                <section
                  id="find-right-doctor"
                  className="border-bottom border box-shadow"
                  key={i}
                >
                  <article className="doctor-card card border container-grid-2-1">
                    <div className="flex-row">
                      <div className="circle-img">
                        <img
                          className="profile-image"
                          src={ProfileImg}
                          alt="Doctor Profile Image"
                        />
                      </div>

                      <div className=" ">
                        <h2 className="doctor-name">
                          <Link href="">
                            {" "}
                            Dr. {firstName} {lastName}
                          </Link>
                          <span>
                            <span
                              className={
                                { active }
                                  ? "active-sign mx-2"
                                  : "inactive-sign mx-2"
                              }
                            ></span>
                          </span>
                        </h2>
                        <p className="doctor-specialty">General Physician</p>
                        <p className="doctor-experience">54 years experience</p>
                        <h3 className="patient-stories">
                          <span>
                            <FaCommentDots className="text-green" />
                          </span>
                          108 Patient Stories
                        </h3>
                      </div>
                    </div>

                    <div>
                      <div className="grid-2-1 border">
                        <div className="recommendation card-content">
                          <Link>
                            <span className="bg-green mini-box">
                              <FaThumbsUp className="text-light" /> 93%
                            </span>
                            Patient Recommendation
                          </Link>
                        </div>

                        <div className="excellence-rating card-content">
                          <Link>
                            <span className="bg-blue mini-box">
                              <FaGem className="text-light" /> 4.0
                            </span>
                            Hospital Excellence Rating
                          </Link>
                        </div>
                      </div>

                      <div className="card-content border">
                        <Link href="#">
                          Electronics City • Practo Hospitals - Neo
                        </Link>
                        <br />
                        <Link href="#">
                          <span className="text-green">Open</span> • Updated
                          today
                        </Link>
                        <br />
                        <Link href="#">~ ₹700 Consultation Fees</Link> <br />
                      </div>

                      <div className="availability-info card-content border">
                        <h3 className="text-green">NEXT AVAILABLE AT</h3>

                        <div className="available-slots grid-2-1">
                          <div>
                            <small className="mx-1">11:30 AM, 29th Feb</small>
                            <Link href="#" className="btn  btn-contact">
                              Contct Clinic
                            </Link>
                          </div>

                          <div>
                            <small className="mx-1">11:30 AM, 29th Feb</small>
                            <Link href="#" className="btn  btn-book">
                              Book Clinic
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </section>
              );
            })}
        </div>
        <Footer />
      </div>
    </>
  );
};
const SearchDoctor = () => {
  const [upload, setUpload] = useState("");
  const [searchListClicked, setSearchListClicked] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(null); // Track selected Doctor

  const [medicalProfessionalsData, setMedicalProfessionalsData] = useState();
  const [medicalProfessionalsDataErr, setMedicalProfessionalsDataErr] =
    useState(false);
  const {
    data: medicalProfessionalsDataRes,
    loading: medicalProfessionalsLoading,
    error: medicalProfessionalsError,
    getRequest,
  } = useFetch("/api/medicalProfessionals");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    console.log("medical", medicalProfessionalsDataRes);
    if (medicalProfessionalsDataRes?.data) {
      setMedicalProfessionalsData(medicalProfessionalsDataRes.data);
    }
    if (medicalProfessionalsError) {
      setMedicalProfessionalsDataErr(true);
    }
  }, [medicalProfessionalsDataRes, medicalProfessionalsError]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDoctorClick = (doctor) => {
    setSelectedDoctor(doctor); // Set selected Doctor for detailed view
    setSearchListClicked("hidden");
  };

  const handleUpload = (e) => {
    setUpload(e.target.upload[0]);
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);

    const filteredData = medicalProfessionalsData.filter((doctor) =>
      doctor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes
  return (
    <>
      <section id="search">
        <div className="medicine-nav-container">
          <div className="search-container">
            <div className="search-bar search">
              <div className="search-wrapper">
                <div>
                  <input
                    type="text"
                    placeholder="Search for Doctor"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  ></input>
                </div>
                <div className="search-list-container">
                  <ul className={`${"lists"} ${searchListClicked}`}>
                    {searchResults.length > 0 // Check if results exist before rendering
                      ? searchResults.map((doctor) => (
                          <li
                            key={doctor.id}
                            onClick={() => handleDoctorClick(doctor)}
                          >
                            <Link
                              className="text-primary name"
                              to={`/doctor_info/${doctor.id}`}
                            >
                              {doctor.name}
                            </Link>
                          </li>
                        ))
                      : searchTerm && <p>No results found.</p>}{" "}
                    {/* {cartItems.length > 0 && <ViewCart />} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </section>
    </>
  );
};
export { SearchDoctor };
export default DoctorList;
