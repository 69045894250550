import React, { useState } from "react";
import "./Modal.css";

const Modal = ({ isOpen, closeModal, symptom, data }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (categoryName, subIdx, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [categoryName]: {
        ...prevData[categoryName],
        [subIdx]: value,
      },
    }));
  };

  const renderData = (data) => {
    if (!data) return null;
    {
      console.log(data.name);
    }
    return data.map((category, index) => (
      <div key={index}>
        {console.log(category.name)}
        <h2>{category.name}</h2>
        {category.items &&
          category.items.map((item, subIdx) => (
            <div key={subIdx}>
              {Array.isArray(item.options) ? (
                <div>
                  <h5>{item.name}</h5>
                  {item.options.map((option, optIdx) => (
                    <div className="checkbox-group" key={optIdx}>
                      {option.includes("Box to fill") ? (
                        <input
                          type="text"
                          id={`${category.name}_${subIdx}_${optIdx}`}
                          name={`${category.name}_${subIdx}`}
                          value={formData[category.name]?.[subIdx] || ""}
                          placeholder={option}
                          onChange={(e) =>
                            handleChange(category.name, subIdx, e.target.value)
                          }
                        />
                      ) : (
                        <>
                          <input
                            type="radio"
                            id={`${category.name}_${subIdx}_${optIdx}`}
                            name={`${category.name}_${subIdx}`}
                            value={option}
                            onChange={(e) =>
                              handleChange(
                                category.name,
                                subIdx,
                                e.target.value
                              )
                            }
                          />
                          <label
                            htmlFor={`${category.name}_${subIdx}_${optIdx}`}
                          >
                            {option}
                          </label>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h3>{item.name}</h3>

                  <div className="checkbox-group">
                    {item.includes("Box to fill") ? (
                      <input
                        type="text"
                        id={`${category.name}_${subIdx}`}
                        name={`${category.name}_${subIdx}`}
                        value={formData[category.name]?.[subIdx] || ""}
                        placeholder={item}
                        onChange={(e) =>
                          handleChange(category.name, subIdx, e.target.value)
                        }
                      />
                    ) : (
                      <>
                        <input
                          type="radio"
                          id={`${category.name}_${subIdx}`}
                          name={`${category.name}`}
                          value={item}
                          onChange={(e) =>
                            handleChange(category.name, subIdx, e.target.value)
                          }
                        />
                        <label htmlFor={`${category.name}_${subIdx}`}>
                          {item}
                        </label>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    ));
  };

  const handleSubmit = () => {
    // Save formData to localStorage
    localStorage.setItem("modalFormData", JSON.stringify(formData));
    // Log saved data to console
    console.log("Saved modal Data:", formData);
    // Close modal
    closeModal();
  };

  return (
    <>
      {isOpen && (
        <div id="modal">
          <div className="modal-container">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-body">
                  <span className="modal-close" onClick={closeModal}>
                    ×
                  </span>
                  <h4>{symptom}</h4>
                  {renderData(data)}
                </div>
              </div>
              <button onClick={handleSubmit} className="btn">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
