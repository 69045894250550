import { React } from "react";

import Footer from "../../components/common/footer/Footer.jsx";
import Sidebar from "../../components/common/sidebar/Sidebar.jsx";
function DiagnosticPage({ DiagnosticPageComp }) {
  return (
    <>
      <Sidebar />
      <DiagnosticPageComp />
      <Footer />
    </>
  );
}

export default DiagnosticPage;
