import React from "react";
import PostTable from "../../../common/post-table/postTable";
const AdminPostTable = () => {
  const postOptionData = [
    { key: "service", value: "service", label: "Service" },
    { key: "article", value: "article", label: "Article" },
    { key: "testimonial", value: "testimonial", label: "Testimonial" },
    { key: "consult", value: "consult", label: "Consult" },
    { key: "specialist", value: "specialist", label: "Specialist" },
    {
      key: "patientCategories",
      value: "patientCategories",
      label: "Patient Categories",
    },
    {
      key: "diagnosticMaterial",
      value: "diagnosticMaterial",
      label: "Diagnostic Material",
    },
  ];

  return <PostTable postOptionData={postOptionData} />;
};

export default AdminPostTable;
