import React, { useState, useEffect, useRef } from "react";
import logo from "../../../../image/logo.png";
import useFetch from "../../../../hooks/useFetch.js";
import ErrorPage from "../../../common/error-page/ErrorPage";
import { Link, useParams } from "react-router-dom";
// import { medicineData, cartItems } from "../../../../services/data.js";
import Footer from "../../../common/footer/Footer.jsx";
import Header from "../../../common/header/Header.jsx";

import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

import { FaBars } from "react-icons/fa";
import "./PharmacyUserPortal.css";
import { IoIosNotifications } from "react-icons/io";
const PharmacyUserPortal = ({ onBarsClick }) => {
  // const [query, setQuery] = useState("");

  //
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedMedicine, setSelectedMedicine] = useState(null); // Track selected medicine

  return (
    <>
      <div id="user-portal">
        <Header />

        <SearchMedicine />

        <ImgSlider />
        <Footer />
      </div>
    </>
  );
};

const SearchMedicine = () => {
  const [upload, setUpload] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMedicine, setSelectedMedicine] = useState(null); // Track selected medicine
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [medicineData, setMedicineData] = useState();
  const [medicineDataErr, setMedicineDataErr] = useState(false);
  const {
    data: medicineDataRes,
    loading: medicineLoading,
    error: medicineError,
    getRequest,
  } = useFetch("/api/medicines");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    console.log("medicine", medicineDataRes);
    if (medicineDataRes?.data) {
      setMedicineData(medicineDataRes.data);
    }
    if (medicineError) {
      setMedicineDataErr(true);
    }
  }, [medicineDataRes, medicineError]);

  const searchBarRef = useRef(null);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (medicine) => {
    setSelectedMedicine(medicine); // Set selected medicine for detailed view
  };

  const handleUpload = (e) => {
    setUpload(e.target.upload[0]);
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);

    const filteredData = medicineData.filter((medicine) =>
      medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);
  return (
    <>
      {medicineDataErr && <ErrorPage />}
      {medicineData && (
        <section id="user-portal">
          <div className="medicine-nav-container">
            <div className="search-container">
              <div className="search-bar search">
                <div ref={searchBarRef} className="search-wrapper">
                  <div>
                    <input
                      type="text"
                      placeholder="Search for medicine"
                      value={searchTerm}
                      onFocus={() => setIsDropdownVisible(true)}
                      onChange={handleSearchTermChange}
                    ></input>
                  </div>
                  {isDropdownVisible && (
                    <div className="search-list-container">
                      <ul className="lists">
                        {searchResults.length > 0 // Check if results exist before rendering
                          ? searchResults.map((medicine) => (
                              <Link
                                key={medicine.medicineId}
                                to={`/app/medicine/${medicine.id}`}
                              >
                                <li
                                  key={medicine.id}
                                  onClick={() => handleMedicineClick(medicine)}
                                  className="med-list name"
                                >
                                  {medicine.name}
                                </li>
                              </Link>
                            ))
                          : searchTerm && <p>No results found.</p>}{" "}
                        {/* {cartItems.length > 0 && <ViewCart />} */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div id="choose_file">
                <label htmlFor="upload-file">
                  <span className="btn-upload">Upload a Prescription</span>{" "}
                </label>
                <input
                  id="upload-file"
                  className="inputs"
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={handleUpload}
                />
              </div>
            </div>

            <div className="right-functionalities">
              <section>
                <div className="notification-wrapper">
                  <div>
                    <Link to="/notificationCenter" className="notification">
                      <IoIosNotifications />
                    </Link>
                  </div>
                </div>
              </section>
              <div className="user-cart-btn">
                <Link to="/viewcart" id="cart-btn">
                  View Cart
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const ImgSlider = () => {
  const [slideImages, setSlideImages] = useState([
    { image: "../../../images/med3.jpg" },
    { image: "../../../images/med1.jpg" },
    { image: "../../../images/med22.png" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % slideImages.length;
    setCurrentIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex =
      (currentIndex - 1 + slideImages.length) % slideImages.length; // Handle negative index
    setCurrentIndex(prevIndex);
  };

  return (
    <section className="imageSection">
      <div className="sectionCenter">
        <div className="imagePart1">
          {slideImages.map((image, imageIndex) => (
            <div
              key={imageIndex}
              style={{
                display: imageIndex === currentIndex ? "block" : "none",
              }}
              className="imagePart"
            >
              <img src={image.image} alt={`Slide ${imageIndex + 1}`} />
            </div>
          ))}
        </div>
        <button className="prev" onClick={handlePrev}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={handleNext}>
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
};

export default PharmacyUserPortal;
export { SearchMedicine, ImgSlider };
