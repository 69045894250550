//
///////////////////////////// posts

// service

// import { types } from "tar";

// import {} from "../../public/images/service-img-1.png";
export const serviceData = [
  {
    postId: 1,
    img: "../images/Gen_guid.PNG",
    title: "GENERAL CLINICAL GUIDANCE",
    content: "Trusted health information",
    link: "service",
  },
  {
    postId: 2,
    img: "../images/Doc.png",
    title: "HOSPITAL AND DR. SUGGESTION",
    content: "Save time and find top-rated doctor.",
    link: "hospital_and_dr_suggestion",
  },
  {
    postId: 3,
    img: "../images/pharm.png",
    title: "PHARMACY",
    content: "Get the medications you need quickly and easily.",
    link: "medicine_pharmacy_suggestion",
  },
  {
    postId: 4,
    img: "../images/diagnostic-1.jpg",
    title: "DIAGNOSTIC CENTER",
    content: "Find the right diagnostic center near you.",
    link: "diagnostic_center_suggestion",
  },
  {
    postId: 5,
    img: "../images/homecare.png",
    title: "HOME CARE",
    content: "Explore quality home care options.",
    link: "home_care",
  },
  {
    postId: 6,
    img: "../images/fitness.png",
    title: "FITNESS CENTER",
    content: "Get fit and be the healthiest you",
    link: "fitness_center",
  },
  {
    postId: 7,
    img: "../images/nutritionist.png",
    title: "NUTRITION",
    content: "Fuel your body, optimize your health",
    link: "nutritionist",
  },
  {
    postId: 8,
    img: "../images/physiotherapy.png",
    title: "PHYSIOTHERAPY",
    content: "Get the personalized care you deserve",
    link: "physiotherapy",
  },
  {
    postId: 9,
    img: "../images/psychiatric.png",
    title: "PSYCHIATRIC COUNSELING",
    content: "Invest in your mental well-being.",
    link: "psychiatric_counseling",
  },
  {
    postId: 10,
    img: "../images/consultant.png",
    title: "CONSULTANCY WITH DIRECT CALL",
    content: "Connect with a consultant instantly",
    link: "consultant_direct_call",
  },
  {
    postId: 11,
    img: "../images/equipments.png",
    title: "MEDICAL EQUIPMENT",
    content: "Unlocking equipment possibilities!",
    link: "medical_equipment_suggestion",
  },
  {
    postId: 12,
    img: "../images/guesthouse.png",
    title: "GUEST HOUSE",
    content: "Rest easy, we've got you covered!",
    link: "near_by_guest_house_suggestion",
  },
  {
    postId: 13,
    img: "../images/travel.png",
    title: "MEDICAL TRAVEL AGENCY",
    content: "Experience world-class healthcare",
    link: "medical_travel_agency",
  },
  {
    postId: 14,
    img: "../images/article.png",
    title: "FREE MEDICAL INFORMATION",
    content: "Your free resource for health knowledge",
    link: "free_medical_information",
  },
];
export const consultData = [
  {
    postId: 1,
    img: "../images/consult-img-1.webp",
    title: "MenstrualOrPregnancy",
    content: "CONSULTNOW",
    link: "period_doubts_or_pregnancy",
  },
  {
    postId: 2,
    img: "../images/consult-img-2.webp",
    title: "AcneVulgaris",
    content: "CONSULTNOW",
    link: "acne_pimple_or_skin_issues",
  },
  {
    postId: 3,
    img: "../images/consult-img-3.svg",
    title: "SexualDysfunction",
    content: "CONSULTNOW",
    link: "performance_issues_in_bed",
  },
  {
    postId: 4,
    img: "../images/consult-img-4.webp",
    title: "ColdCoughOrFever",
    content: "CONSULTNOW",
    link: "cold_cough_or_fever",
  },
  {
    postId: 5,
    img: "../images/consult-img-5.svg",
    title: "ChildhoodDisease",
    content: "CONSULTNOW",
    link: "child_not_feeling_well",
  },
  {
    postId: 6,
    img: "../images/consult-img-6.webp",
    title: "MoodDisorder",
    content: "CONSULTNOW",
    link: "depression_or_anxiety",
  },
];
export const specialistData = [
  {
    postId: 1,
    img: "../images/book-img-1.1.jpg",
    title: "Dentist",
    content: "Teething troubles? Schedule a dental checkup",
    link: "dentist",
  },
  {
    postId: 2,
    img: "../images/gyne.png",
    title: "GynecologistObstetrician",
    content: "Explore for women's health, pregnancy and infertility treatments",
    link: "gynecologist_obstetrician",
  },
  {
    postId: 3,
    img: "../images/nu.PNG",
    title: "DietitianNutrition",
    content:
      "Get guidance on eating right, weight management and sports nutrition",
    link: "dietitian_nutrition",
  },
  {
    postId: 4,
    img: "../images/physiotherapist.png",
    title: "Physiotherapist",
    content: "Pulled a muscle? Get it treated by a trained physiotherapist",
    link: "physiotherapist",
  },
  {
    postId: 5,
    img: "../images/sergion.png",
    title: "GeneralSergion",
    content: "Need to get operated ? Find sergion",
    link: "physiotherapist",
  },
];
export const articleData = [
  {
    postId: 1,
    img: "../images/article-img-1.jpg",
    title: "CORONAVIRUS",
    content: "12 Coronavirus Myths and Facts That You Should Be Aware Of",
    detail: { name: "Dr. Diana Borgio" },
    link: "coronavirus",
  },
  {
    postId: 2,
    img: "../images/article-img-2.jpg",
    title: "VITAMINSANDSUPPLEMENTS",
    content: "Eating Right to Build Immunity Against Cold and Viral Infections",
    detail: { name: "Dr. Diana Borgio" },
    link: "vitamins_and_supplements",
  },
];
export const testimonialData = [
  {
    postId: 1,
    image: "https://www.course-api.com/images/people/person-1.jpeg",
    detail: { name: "maria ferguson" },
    title: "office manager",
    content:
      "I used to dread scheduling appointments with my doctor's office, but this makes it so easy!  I can see top rated doctors and near hospitals in just a few clicks",
  },
  {
    postId: 2,
    image: "https://www.course-api.com/images/people/person-4.jpeg",
    detail: { name: "john doe" },
    title: "regular guy",
    content:
      "This app allows me to stay connected with my healthcare. I can ask my doctor questions through secure messaging and get prompt responses, which gives me peace of mind.",
  },
  {
    postId: 3,
    image: "https://www.course-api.com/images/people/person-3.jpeg",
    detail: { name: "peter smith" },
    title: "product designer",
    content:
      "This app has empowered me to take a more active role in my healthcare. The self-management tools and educational resources have helped me improve my overall health and well-being.",
  },
  {
    postId: 4,
    image: "https://www.course-api.com/images/people/person-2.jpeg",
    detail: { name: "susan andersen" },
    title: "the boss",
    content:
      "I highly recommend this app to anyone looking for a more convenient and connected healthcare experience. It has streamlined my communication with my doctor and made managing my health much easier.",
  },
];
export const patientCategoriesData = [
  {
    postId: 1,
    img: "../images/consult-img-1.webp",
    content: "Adult",
    link: "patient",
  },
  {
    postId: 2,
    img: "../images/consult-img-2.webp",
    content: "Child",
    link: "formChild",
  },
  {
    postId: 3,
    img: "../images/consult-img-3.svg",
    content: "Sexual problems", // May want to conspostIder a more descriptive content
    link: "patient",
  },
  {
    id: 4,
    img: "../images/consult-img-4.webp",
    content: "Gynecology and obstetrics", // May want to consider a more descriptive content
    link: "patient",
  },
];
export const diagnosticMaterialData = [
  {
    postId: 1,

    title: "MRI (Magnetic Resonance Imaging)",
    image: "../../images/D6-MRI.png",
    details: [
      {
        name: "Usage",
        detail:
          "Creates detailed images of organs, soft tissues, bones, and other internal structures",
      },
      {
        name: "Sample can this equipment analyze",
        detail:
          "Doesn't directly analyze samples. Uses magnetic fields and radio waves to measure the response of water molecules within the body",
      },
      {
        name: "Kind of data it produce",
        detail:
          "High-resolution, 3D images of internal structures with excellent soft tissue contrast",
      },
      {
        name: "Special Preparation",
        detail:
          "Metal implants or claustrophobia may be limitations. Certain medications or claustrophobia may require sedation.",
      },
      {
        name: "Safety Consideration",
        detail:
          "Generally safe, but not suitable for people with certain metal implants or claustrophobia.",
      },
      {
        name: "Operating Principle",
        detail:
          "Uses strong magnetic fields to align protons (atomic particles) in the body's water molecules.",
      },
    ],
  },

  {
    postId: 2,
    title: "X-Ray",
    image: "../../images/D1-X-ray.png",
    details: [
      {
        name: "Usage",
        detail:
          "Creates images of bones and some soft tissues. Used for diagnosing fractures, infections, arthritis, and some chest or abdominal conditions.",
      },
      {
        name: "Sample can this equipment analyze",
        detail:
          "Doesn't directly analyze samples. Uses X-rays to measure the different densities of tissues within the body.",
      },
      {
        name: "Kind of data it produce",
        detail: "Flat, 2D image of the body part being X-rayed.",
      },
      {
        name: "Special Preparation",
        detail:
          "Minimal preparation usually required. Removal of jewelry or clothing with metal fasteners may be necessary.",
      },
      {
        name: "Safety Consideration",
        detail:
          "Uses ionizing radiation, which carries a small risk of cancer with repeated exposure. Pregnant women should avoid X-rays unless absolutely necessary.",
      },
      {
        name: "Operating Principle",
        detail:
          "X-rays are passed through the body. Denser tissues absorb more X-rays, while less dense tissues allow them to pass through more easily.",
      },
    ],
  },
  {
    postId: 3,
    title: "CT Scan (Computed Tomography Scan)",
    image: "../../images/D1-X-ray.png",
    details: [
      {
        name: "Usage",
        detail:
          "Creates detailed cross-sectional images of the body. Used for diagnosing internal injuries, bone fractures, tumors, and abnormalities in blood vessels.",
      },
      {
        name: "Sample can this equipment analyze",
        detail:
          "Doesn't directly analyze samples. Uses X-rays to measure the different densities of tissues within the body.",
      },
      {
        name: "Kind of data it produce",
        detail:
          "Detailed 2D or 3D cross-sectional images of internal structures.",
      },
      {
        name: "Special Preparation",
        detail:
          "Metal implants may require adjustments depending on the scan area.",
      },
      {
        name: "Safety Consideration",
        detail:
          "Uses ionizing radiation, which carries a small risk of cancer with repeated exposure. Contrast dye may cause allergic reactions.",
      },
      {
        name: "Operating Principle",
        detail:
          "X-rays are passed through the body at multiple angles. A computer then analyzes the varying degrees of X-ray absorption by different tissues to create detailed cross-sectional images.",
      },
    ],
  },
];
//////////////////////////////////
export const medicineData = [
  {
    medicineId: 1,
    name: "Penicillin G Potassium",
    type: "Injection",
    dosage: "Varies depending on infection type",
    frequency: "As prescribed by a doctor",
    purpose: "Bacterial infections",
    detail: {
      sideEffects: ["Allergic reactions", "Diarrhea", "Nausea"],
      contraindications: [
        "Allergy to penicillin",
        "Kidney disease",
        "Pregnancy",
      ],
    },
    storage: "Store in a refrigerator, protect from light.",
    image: "../../images/medinfo1.png",
    link: "med1",
  },
  {
    medicineId: 2,
    name: "Aspirin",
    type: "Tablet",
    dosage: "325-500mg every 4-6 hours (max 4g/day)",
    frequency: "Every 4-6 hours",
    purpose: "Headaches, muscle aches, fever",
    detail: {
      sideEffects: ["Stomach pain", "Heartburn", "Nausea"],
      contraindications: ["Bleeding disorders", "Asthma", "Gout"],
    },
    storage: "Keep at room temperature, away from moisture.",
    image: "../../images/medinfo2.png",
    link: "med2",
  },
  {
    medicineId: 3,
    name: "Metformin",
    type: "Tablet",
    dosage: "500mg or 850mg twice daily",
    frequency: "Twice daily",
    purpose: "Manages blood sugar in type 2 diabetes",
    detail: {
      sideEffects: ["Nausea", "Vomiting", "Diarrhea"],
      contraindications: ["Kidney disease", "Liver disease", "Heart disease"],
    },
    storage: "Store at room temperature, away from moisture and heat.",
    image: "../../images/medinfo3.png",
    link: "med3",
  },
  {
    medicineId: 4,
    name: "Ibuprofen",
    type: "Tablet",
    dosage: "200-400mg every 4-6 hours (max 1200mg/day)",
    frequency: "Every 4-6 hours",
    purpose: "Headaches, muscle aches, fever, menstrual cramps",
    detail: {
      sideEffects: ["Dizziness", "Nausea", "Stomach pain"],
      contraindications: ["Ulcers", "Bleeding problems", "Heart disease"],
    },
    storage: "Store at room temperature, away from moisture and heat.",
    image: "../../images/medinfo4.png",
    link: "med4",
  },
  {
    medicineId: 5,
    name: "Acetaminophen",
    type: "Tablet",
    dosage: "325-500mg every 4-6 hours (max 4g/day)",
    frequency: "Every 4-6 hours",
    purpose: "Headaches, muscle aches, fever",
    detail: {
      sideEffects: ["Rash", "Nausea", "Jaundice"],
      contraindications: [
        "Liver disease",
        "Alcoholism",
        "Allergy to acetaminophen",
      ],
    },
    storage: "Keep in a cool, dry place, out of reach of children.",
    image: "../../images/medinfo5.png",
    link: "med5",
  },
  // Add more medicine objects with the same structure here
];
export const medicalRecords = [
  {
    medicalRecordId: 1,
    date: "2024-05-10",
    type: "Lab Results",
    description: "Blood Test - Complete Blood Count (CBC)",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "CBC results are within normal range.",
    patientName: "John Doe",
    phoneNumber: "0915551212",
  },
  {
    medicalRecordId: 2,
    date: "2024-05-02",
    type: "Test Results",
    description: "X-Ray - Chest",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation:
      "The X-ray shows no signs of pneumonia or other lung problems.",
    patientName: "Jane Smith",
    phoneNumber: "0915553434",
  },
  {
    medicalRecordId: 3,
    date: "2024-04-25",
    type: "Consultation",
    description: "Initial consultation with Dr. Lee",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "Discussed general health concerns and medical history.",
    patientName: "Michael Brown",
    phoneNumber: "0915557878",
  },
  {
    medicalRecordId: 4,
    date: "2024-04-18",
    type: "Prescription",
    description: "Medication prescribed: Amoxicillin",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "Amoxicillin prescribed for treatment of bacterial infection.",
    patientName: "Sarah Garcia",
    phoneNumber: "0915559090",
  },
  {
    medicalRecordId: 5,
    date: "2024-04-10",
    type: "Immunization",
    description: "Flu shot administered",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "Seasonal flu vaccination received.",
    patientName: "DavmedicalRecordId Miller",
    phoneNumber: "0915552121",
  },
  {
    medicalRecordId: 6,
    date: "2024-03-22",
    type: "Lab Results",
    description: "Blood test for cholesterol",

    results: [
      { name: "totalCholesterol", value: 180 },
      { name: "LDL", value: 120 },
      { name: "HDL", value: 50 },
    ],

    explanation:
      "Discuss cholesterol levels with your doctor at next appointment.",
    patientName: "Emily Hernandez",
    phoneNumber: "0915554343",
  },
  {
    medicalRecordId: 7,
    date: "2024-03-15",
    type: "Appointment",
    description: "Annual physical exam",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "General health checkup performed. All vital signs normal.",
    patientName: "Robert Williams",
    phoneNumber: "0915556565",
  },
  {
    medicalRecordId: 8,
    date: "2024-03-08",
    type: "Procedure",
    description: "Minor surgery performed",

    results: [
      { name: "hemoglobin", value: 14.2 },
      { name: "hematocrit", value: 42.0 },
      { name: "whiteBloodCells", value: 7.5 },
    ],

    explanation: "Procedure details available in your discharge summary.",
    patientName: "Jennifer Moore",
    phoneNumber: "0915558787",
  },
];
export const medicalProfessionalsData = [
  {
    medicalProfessionalsId: 1,
    firstName: "Mohammed",
    lastName: "Adem",
    email: "doctor@gmail.com",

    specialty: [
      {
        name: "Cardiology",
        experience: 10,
      },
    ],

    phone: "+251911234567",
    active: false,
  },
  {
    medicalProfessionalsId: 2,
    firstName: "Awol",
    lastName: "Ahemed",
    email: "doctor@gmail.com",

    specialty: [
      {
        name: "Dermatology",
        experience: 5,
      },
    ],

    phone: "+251987654321",
    active: false,
  },
];
export const hospitalData = [
  {
    hospitalId: "1",
    name: "Black-Lion Hospital",
    image: "../../images/blackLionLogo.png",
    location: "4-Killo",
    phone: "251-45-25-65-23",

    services: [
      {
        id: "A",
        name: "MRI (Magnetic Resonance Imaging)",
        image: "../../images/D6-MRI.png",
        details: [
          {
            name: "Usage",
            detail:
              "Creates detailed images of organs, soft tissues, bones, and other internal structures",
          },
          {
            name: "Sample can this equipment analyze",
            detail:
              "Doesn't directly analyze samples. Uses magnetic fields and radio waves to measure the response of water molecules within the body",
          },
          {
            name: "Kind of data it produce",
            detail:
              "High-resolution, 3D images of internal structures with excellent soft tissue contrast",
          },
          {
            name: "Special Preparation",
            detail:
              "Metal implants or claustrophobia may be limitations. Certain medications or claustrophobia may require sedation.",
          },
          {
            name: "Safety Consideration",
            detail:
              "Generally safe, but not suitable for people with certain metal implants or claustrophobia.",
          },
          {
            name: "Operating Principle",
            detail:
              "Uses strong magnetic fields to align protons (atomic particles) in the body's water molecules.",
          },
        ],
      },

      {
        id: "B",
        name: "X-Ray",
        image: "../../images/D1-X-ray.png",
        details: [
          {
            name: "Usage",
            detail:
              "Creates images of bones and some soft tissues. Used for diagnosing fractures, infections, arthritis, and some chest or abdominal conditions.",
          },
          {
            name: "Sample can this equipment analyze",
            detail:
              "Doesn't directly analyze samples. Uses X-rays to measure the different densities of tissues within the body.",
          },
          {
            name: "Kind of data it produce",
            detail: "Flat, 2D image of the body part being X-rayed.",
          },
          {
            name: "Special Preparation",
            detail:
              "Minimal preparation usually required. Removal of jewelry or clothing with metal fasteners may be necessary.",
          },
          {
            name: "Safety Consideration",
            detail:
              "Uses ionizing radiation, which carries a small risk of cancer with repeated exposure. Pregnant women should avoid X-rays unless absolutely necessary.",
          },
          {
            name: "Operating Principle",
            detail:
              "X-rays are passed through the body. Denser tissues absorb more X-rays, while less dense tissues allow them to pass through more easily.",
          },
        ],
      },
    ],
  },

  {
    hospitalId: "2",
    name: "Paul Millennium Medical College's Hospital",
    image: "../../images/paulHospital.png",
    location: "6-Killo",
    phone: "251-12-25-65-23",

    services: [
      {
        id: "B",
        name: "X-Ray",
        image: "../../images/D1-X-ray.png",
        details: [
          {
            name: "Usage",
            detail:
              "Creates images of bones and some soft tissues. Used for diagnosing fractures, infections, arthritis, and some chest or abdominal conditions.",
          },
          {
            name: "Sample can this equipment analyze",
            detail:
              "Doesn't directly analyze samples. Uses X-rays to measure the different densities of tissues within the body.",
          },
          {
            name: "Kind of data it produce",
            detail: "Flat, 2D image of the body part being X-rayed.",
          },
          {
            name: "Special Preparation",
            detail:
              "Minimal preparation usually required. Removal of jewelry or clothing with metal fasteners may be necessary.",
          },
          {
            name: "Safety Consideration",
            detail:
              "Uses ionizing radiation, which carries a small risk of cancer with repeated exposure. Pregnant women should avoid X-rays unless absolutely necessary.",
          },
          {
            name: "Operating Principle",
            detail:
              "X-rays are passed through the body. Denser tissues absorb more X-rays, while less dense tissues allow them to pass through more easily.",
          },
        ],
      },
    ],
  },
];
////
export const medicineImage = [
  {
    id: "1",
    image: "../../images/sample-medicine.png",
  },
  {
    id: "2",
    image: "../../images/sample-medicine2.png",
  },
  {
    id: "3",
    image: "../../images/sample-medicine3.png",
  },
];
export let cartItems = [];
export let notificationList = [];
//forms // sidebar // footer
export const generalClinicalGuidanceFormData = [
  {
    symptomCategory: "General Symptoms",
    data: [
      {
        name: "Fever",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Intensity", items: ["High grade", "Low grade"] },
          { name: "Pattern", items: ["Intermittent", "Persistent"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Loss of appetite",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Fatigue",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Pattern", items: ["Intermittent", "Persistent"] },
        ],
      },
      {
        name: "Weight loss",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Is the weight loss",
            items: ["Intentional", "Unintentional"],
          },
          { name: "How much", items: ["Box to fill number"] },
        ],
      },
      {
        name: "Night sweating",
        options: [{ name: "Duration", items: ["Days", "Week", "Month"] }],
      },
    ],
  },
  {
    symptomCategory: "Chest",
    data: [
      {
        name: "Cough",
        options: [
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Pattern", items: ["Intermittent", "Persistent"] },
          {
            name: "Characteristic",
            items: ["Dry", "Productive", "Barking", "Hacking"],
          },
          {
            name: "Sputum",
            items: [
              { name: "Amount", options: ["Small", "Moderate", "Large"] },
              {
                name: "Color",
                options: ["Clear/white", "Yellow/green", "Blood tinged"],
              },
            ],
          },
          { name: "Timing", items: ["Night", "Morning", "Throughout the day"] },
          {
            name: "Aggravating factor",
            items: [
              "Dust",
              "Cold",
              "Pollen or allergen",
              "Exposure to",
              "Chronic coughed",
              "New environment",
              "Animal",
              "Occupation Exposure to chemical, fume",
            ],
          },
        ],
      },
      {
        name: "Shortness of breath",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "In what condition it occurs",
            items: ["Exercise", "Walk", "Rest"],
          },
          { name: "Pattern", items: ["Persistent", "Intermittent"] },
          { name: "Timing", items: ["Morning", "Evening"] },
          { name: "Past history of shortness of breath", items: ["Yes", "No"] },
          { name: "Does it aggravate in lying down", items: ["Yes", "No"] },
          { name: "Does it wake you from sleep", items: ["Yes", "No"] },
          {
            name: "Quality of the discomfort",
            items: ["Chest tightness", "Air hunger", "Heavy & rapid breath"],
          },
          { name: "Previous episode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Wheezing",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Description of the sound",
            items: ["High pitch", "Musical", "Continuous"],
          },
          { name: "Associated with Cough", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Chest pain",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Pattern", items: ["Intermittent", "Persistent"] },
          {
            name: "Location",
            items: [
              "Upper chest",
              "Epigastric",
              "Substernal",
              "Retrosternal",
              "Radiation to",
              "Back",
              "Neck",
              "Shooting across the arm",
              "Jaw",
              "Abdomen",
              "Shoulder",
            ],
          },
          {
            name: "Character of the pain",
            items: [
              "Tightness",
              "Gripping",
              "Crashing",
              "Pressing",
              "Burning",
              "Stabbing",
            ],
          },
          { name: "Severity score", items: ["Box to fill [1-10]"] },
          {
            name: "Relieving factor",
            items: ["Rest", "Posture", "Oxygen", "Food", "Medication"],
          },
          {
            name: "Exacerbating",
            items: ["Exercise", "Breathing", "Movement", "Coughing"],
          },
          { name: "Previous episode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Palpitation",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Aggravated by movement", items: ["Yes", "No"] },
          {
            name: "Description of palpitations",
            items: ["Irregular heartbeat", "Rapid heartbeat"],
          },
          { name: "Previous episode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Syncope (Fainting)",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Once per week",
              "Twice per week",
              "Thrice per week",
              "More than thrice a day",
              "Only occasional",
            ],
          },
          { name: "For how long does it last", items: ["Box to fill"] },
          {
            name: "Triggering factor",
            items: ["Stress/anxiety", "Exercise", "Overheat"],
          },
          { name: "Previous episode", items: ["Yes", "No"] },
        ],
      },
    ],
  },
  {
    symptomCategory: "Gastro Intestine",
    data: [
      {
        name: "Abdominal Pain",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Severity Score", items: ["Box to fill [1-10]"] },
          {
            name: "Location",
            items: [
              "Epigastric region",
              "Right upper region of the abdomen",
              "Left upper region of the abdomen",
              "Right lower region of the abdomen",
              "Left lower region of the abdomen",
              "Around the umbilicus",
            ],
          },
          {
            name: "Characterize",
            items: [
              "Sharp",
              "Aching",
              "Stabbing",
              "Burning",
              "Colicky Cramping",
            ],
          },
          {
            name: "Radiation",
            items: [
              "To the back",
              "To the flank",
              "To the Lower abdomen",
              "To the chest",
              "To the shoulder",
            ],
          },
          {
            name: "AggravatingFactor",
            items: [
              "Movement",
              "Eating",
              "Drinking",
              "Respiratory movement",
              "Posture",
              "Immediately after meal",
              "2-3hr after meal",
            ],
          },
          {
            name: "RelievingFactor",
            items: ["Rest", "Eating", "Drinking", "Posture", "Avoiding eating"],
          },
        ],
      },
      {
        name: "Abdominal Distention",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Progression", items: ["Rapid", "Gradual"] },
          {
            name: "Characteristic",
            items: [
              "Generalized with bloating feeling",
              "Pounding",
              "Shortness of breath during lying",
              "Unable to bend",
            ],
          },
          {
            name: "Location",
            items: [
              "Left Lower abdomen",
              "Right Lower abdomen",
              "The whole abdomen",
            ],
          },
        ],
      },
      {
        name: "Abdominal Swelling",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Location",
            items: [
              "Inguinal",
              "Umbilical",
              "Epigastric",
              "Femoral",
              "Scrotal",
              "Around incision site",
            ],
          },
          {
            name: "ChangeInSwellingDuring",
            items: ["Standing up", "Walking", "Straining", "Cough", "Lie"],
          },
          {
            name: "Characteristic",
            items: ["Reducible by hand", "Irreducible by hand"],
          },
        ],
      },
      {
        name: "Difficulty Swallowing",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Type", items: ["Liquid", "Food", "Both"] },
          {
            name: "Description",
            items: [
              "Sensation of food sticking",
              "Pain",
              "Choking",
              "Heartburn",
            ],
          },
          { name: "ExacerbatingFactor", items: ["Food", "Lying down"] },
        ],
      },
      {
        name: "Nausea And Vomiting",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Once a day",
              "Twice a day",
              "Thrice a day",
              "More than thrice a day",
            ],
          },
          { name: "Pattern", items: ["Persistent", "Intermittent"] },
          { name: "EffortfulVomiting", items: ["Yes", "No"] },
          { name: "PainfulVomiting", items: ["Yes", "No"] },
          { name: "Content", items: ["Food", "Bile", "Blood"] },
          { name: "Color", items: ["Yellow-green", "Coffee ground", "Bloody"] },
          {
            name: "Timing",
            items: ["Morning", "Immediately after meal", "1 hour post meal"],
          },
          {
            name: "AggravatingFactor",
            items: ["Movement/motion", "Eating", "Stress", "Medication"],
          },
          {
            name: "RelievingFactor",
            items: ["Medication", "Rest", "Certain food"],
          },
          {
            name: "PossibleHistory",
            items: ["Intoxication", "Contamination of food", "Pregnancy"],
          },
        ],
      },
      {
        name: "Diarrhea",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Once a day",
              "Twice a day",
              "Thrice a day",
              "More than thrice a day",
            ],
          },
          { name: "Pattern", items: ["Persistent", "Intermittent"] },
          { name: "Color", items: ["Watery", "Mucus / pus", "Tarry", "Blood"] },
        ],
      },
      {
        name: "Rectal Bleed",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Once a day",
              "Twice a day",
              "Thrice a day",
              "More than thrice a day",
            ],
          },
          { name: "Color", items: ["Bright red", "Dark red"] },
        ],
      },
      {
        name: "Yellowish Discoloration Of TheEye",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "AssociatedWith",
            items: ["Pruritus", "Clay-colored stool", "Dark urine"],
          },
        ],
      },
      {
        name: "History",
        options: [
          { name: "Alcohol", items: ["Yes", "No"] },
          { name: "GallBladderDisease", items: ["Yes", "No"] },
          { name: "HepatitisOrLiverDisease", items: ["Yes", "No"] },
          { name: "BloodTransfusion", items: ["Yes", "No"] },
          { name: "IVDrugUse", items: ["Yes", "No"] },
          { name: "SexualHistory", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Constipation",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "RecentDietaryChange", items: ["Yes", "No"] },
        ],
      },
    ],
  },

  {
    symptomCategory: "Genitourinary",
    data: [
      {
        name: "Urgency",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Times per day", items: ["Box to fill number"] },
          { name: "Times per night", items: ["Box to fill number"] },
        ],
      },
      {
        name: "Frequency",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Times per day", items: ["Box to fill number"] },
          { name: "Times per night", items: ["Box to fill number"] },
        ],
      },
      {
        name: "Poor Flow",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Intermittent Stream",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Dribbling",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Sensation of Poor Bladder Emptying",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Difficulty of Passing Urine",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Hesitancy / Difficulty of Initiation",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Blood in Urine",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Incontinence",
            items: ["Onset", "Gradual", "Sudden", "Duration"],
          },
          {
            name: "Urine Output",
            items: [
              "Decrease urine volume",
              "Increase urine volume",
              "Absence of urine",
            ],
          },
        ],
      },
      {
        name: "Incontinency",
        options: [
          {
            name: "Onset",
            items: ["Gradual", "Sudden"],
          },
          {
            name: "Duration",
            items: ["Days", "Week", "Month"],
          },
          {
            name: "Characteristics",
            items: [
              "Occur during laughing, sneezing, or exercise",
              "Sudden and strong urge to urinate followed by incontinency",
              "Unable to fully empty properly followed by incontinency",
            ],
          },
        ],
      },
      {
        name: "Urine Output",
        options: [
          {
            name: "Output",
            items: [
              "Decrease urine volume",
              "Increase urine volume",
              "Absence of urine",
            ],
          },
        ],
      },
      {
        name: "Kidney Stone",
        options: [
          { name: "Did any stone appear in urine", items: ["Yes", "No"] },
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Genital Symptoms",
        options: [
          {
            name: "Any genital itching, discharge, or lesions",
            items: ["Yes", "No"],
          },
          {
            name: "Presence of any genital pain or discomfort",
            items: ["Yes", "No"],
          },
          {
            name: "Any recent changes in genital symptoms",
            items: ["Yes", "No"],
          },
          {
            name: "Associated Symptom: Suprapubic pain and swelling",
            items: ["Yes", "No"],
          },
          { name: "Pain during urination", items: ["Yes", "No"] },
          { name: "Flank pain", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Associated Symptoms",
        options: [
          {
            name: "Super Pubic Pain and Swelling",
            items: ["Yes", "No"],
          },
          {
            name: "Pain during Urination",
            items: ["Yes", "No"],
          },
          {
            name: "Flank Pain",
            items: ["Yes", "No"],
          },
        ],
      },
      {
        name: "Perianal Condition",
        options: [
          {
            name: "Swelling perianal",
            items: [
              "Return by itself",
              "Return manually",
              "Not return in any way",
            ],
          },
          {
            name: "Type of swelling",
            items: [
              "Soft",
              "Tender",
              "Warm",
              "With or without discharge",
              "Difficulty to seat",
            ],
          },
          {
            name: "Pain",
            items: [
              "External",
              "Internal",
              "Burning sensation during defecation (cutting or tearing type)",
            ],
          },
          {
            name: "Bleeding",
            items: ["Bright color", "Dark & mixed with stool"],
          },
          { name: "Is there itching or pruritus", items: ["Yes", "No"] },
        ],
      },
    ],
  },
  {
    symptomCategory: "Neurologic symptom ",
    data: [
      {
        name: "Headache",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Every day",
              "Twice a week",
              "Thrice a week",
              "Weekly",
              "Occasional",
            ],
          },
          { name: "Pattern", items: ["Persistent", "Intermittent"] },
          { name: "Intensity", items: ["Mild", "Moderate", "Severe"] },
          {
            name: "Characteristics",
            items: ["Throbbing", "Pulsating", "Dull", "Sharp"],
          },
          {
            name: "Location",
            items: [
              "Generalized headache",
              "Frontal part of headache",
              "Partial headache",
              "One-sided headache",
              "Backside head",
            ],
          },

          {
            name: "Triggering Factor",
            items: [
              "Stress",
              "Lack of sleep",
              "Certain food",
              "Strong odor or light",
              "Prolonged screen time",
            ],
          },
          {
            name: "Associated With",
            items: [
              "Vomiting and nausea",
              "Sensitivity to light and sound",
              "Visual disturbance",
            ],
          },
        ],
      },
      {
        name: "Syncope(fainting)",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Once per week",
              "Twice per week",
              "Thrice per week",
              "More than thrice a day",
              "Only occasional",
            ],
          },
          {
            name: "For how long does it last",
            items: ["Box to fill"],
          },
          {
            name: "TriggeringFactor",
            items: ["Stress/anxiety", "Exercise", "Overheat"],
          },
          { name: "PreviousEpisode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Weakness/Paralysis",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Which Part Of TheBody",
            items: [
              {
                name: "Right side",
                options: ["Face only", "Upper limb", "Lower limb"],
              },
              {
                name: "Left side",
                options: ["Face only", "Upper limb", "Lower limb"],
              },
            ],
          },
          { name: "Incontinence", items: ["Yes", "No"] },
          { name: "Previous Episode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Uncontrolled Body Movement/Seizure",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Frequency",
            items: [
              "Every day",
              "Twice a week",
              "Thrice a week",
              "Weekly",
              "Occasional",
            ],
          },
          {
            name: "For how long does it last",
            items: ["Box to fill (minutes)"],
          },
          {
            name: "TriggeringFactor",
            items: [
              "Stress",
              "Sleep disturbance",
              "Strong light",
              "Alcohol",
              "Certain drugs",
              "During menstrual cycle",
              "Illness/fever",
            ],
          },

          {
            name: "During the uncontrolled movement",
            items: [
              { name: "Is There Incontinence", options: ["Yes", "No"] },
              {
                name: "Is there loss of consciousness/ absence of responsiveness",
                options: ["Yes", "No"],
              },
            ],
          },

          {
            name: "After the uncontrolled movement",
            items: [
              "Weakness",
              "Drowsiness",
              "Confusion",
              "Headache",
              "Muscle soreness",
            ],
          },
        ],
      },
      {
        name: "Confusion",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
        ],
      },
      {
        name: "Sleeping Disorder",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "TriggeringFactor",
            items: [
              "Disturbance with noise",
              "Disturbance with light",
              "Irregular sleep schedule",
              "Shifting work type",
              "Taking coffee",
              "Taking alcohol",
              "Stress",
              "Depression",
              "Psychological trauma",
              "Any taken medication",
              "Any known disease",
            ],
          },
          { name: "PreviousEpisode", items: ["Yes", "No"] },
        ],
      },
      {
        name: "Memory And Cognition",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "AssociatedWith",
            items: ["Concentration", "Attention", "Thinking"],
          },
          { name: "PreviousEpisode", items: ["Yes", "No"] },
        ],
      },
    ],
  },
  {
    symptomCategory: "Breast",
    data: [
      {
        name: "Swelling (Lumps)",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          { name: "Characteristics", items: ["Soft", "Hard", "Painful"] },
        ],
      },
      {
        name: "Pain",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Site",
            items: [
              {
                name: "Right side",
                options: [
                  "Upper part of the breast",
                  "Lower part of the breast",
                ],
              },
              {
                name: "Left side",
                options: [
                  "Upper part of the breast",
                  "Lower part of the breast",
                ],
              },
              { name: "Both", options: ["Both breasts"] },
            ],
          },
        ],
      },

      {
        name: "Nipple Discharge",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Is the Discharge",
            items: ["One breast only", "Both breasts"],
          },
          {
            name: "Type of Discharge",
            items: ["Watery", "Greenish", "Bloody", "Pus", "Milky"],
          },
          { name: "Amount", items: ["Small", "Massive"] },
          {
            name: "Is there any Ulcer Associated with the Discharge",
            items: ["Yes", "No"],
          },
          {
            name: "Any Change in the Nipple",
            items: [
              "Depressed",
              "Deviated",
              "Destructed",
              "Duplicated",
              "Discoloration",
              "Displacement",
              "Destroyed (Totally Absent)",
            ],
          },
        ],
      },

      {
        type: "nested object",
        name: "Other Important History",

        options: [
          {
            name: "Menstrual History",
            items: [
              {
                name: "Did you see your period early (<12 years)",
                options: ["Yes", "No"],
              },
              {
                name: "Did you see menopause at late age (>55 years)",
                options: ["Yes", "No"],
              },
              { name: "Is your period regular", options: ["Yes", "No"] },
              { name: "Is your period irregular", options: ["Yes", "No"] },
            ],
          },
          {
            name: "Obstetric History",
            items: [
              {
                name: "How many children do you have",
                options: ["Box to fill"],
              },
              { name: "Did you breastfeed", options: ["Yes", "No"] },
            ],
          },
          {
            name: "Family and Medical History",
            items: [
              {
                name: "Do you have History of Trauma to Your Breast",
                options: ["Yes", "No"],
              },
              {
                name: "Do you have Family History of Breast/Ovarian Cancer",
                options: ["Yes", "No"],
              },
              {
                name: "Do you have Previous History of Breast/Ovarian Cancer",
                options: ["Yes", "No"],
              },
              {
                name: "Do you have History of Hormonal Replacement Therapy",
                options: ["Yes", "No"],
              },
              {
                name: "Do you have Symptoms like Fever and Chills",
                options: ["Yes", "No"],
              },
              {
                name: "Do you have Swelling under Your Armpit",
                options: ["Yes", "No"],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    symptomCategory: "Muscle and bone",
    data: [
      {
        name: "Pain",
        options: [
          {
            name: "Location",
            items: [
              "Back pain",
              "Neck pain",
              "Shoulder pain",
              "Knee pain",
              "Hip pain",
              "Elbow pain",
              "Wrist and hand pain",
              "Ankle and foot pain",
            ],
          },
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Characteristics of the Pain",
            items: ["Sharp", "Dull", "Throbbing"],
          },
          {
            name: "Aggravating Factor",
            items: [
              "Any activities",
              "Exercise",
              "Poor posture",
              "Cold weather",
              "Stress",
            ],
          },
          {
            name: "Associated Symptoms",
            items: [
              "Stiffness",
              "Swelling around the area",
              "Warm around the area",
              "Redness around the area",
            ],
          },
        ],
      },
      {
        name: "Joint Symptom",
        options: [
          {
            name: "Location",
            items: [
              "Knee",
              "Hip",
              "Shoulder",
              "Elbow",
              "Wrist",
              "Ankle",
              "Fingers joint",
            ],
          },
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Aggravating Factor",
            items: [
              "Any activities",
              "Exercise",
              "Poor posture",
              "Cold weather",
              "Stress",
            ],
          },
          {
            name: "Is there Limitation of Movement in the Area",
            items: ["Yes", "No"],
          },
          {
            name: "Any Clicking, Locking, or Giving Way of Joints",
            items: ["Yes", "No"],
          },
          {
            name: "Associated Symptoms",
            items: [
              "Stiffness",
              "Swelling around the area",
              "Warm around the area",
              "Redness around the area",
            ],
          },
          {
            name: "Is there any Trauma or Injury Occurred in the Area",
            items: ["Yes", "No"],
          },
        ],
      },
      {
        name: "Muscle Symptoms",
        options: [
          {
            name: "Location",
            items: [
              "Neck muscle",
              "Calf muscle",
              "Upper back muscle",
              "Lower back muscle",
              "Leg muscle",
              "Arm muscle",
              "Thigh muscle",
              "Foot muscle",
            ],
          },
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Aggravating Factor",
            items: [
              "Any activities",
              "Exercise",
              "Poor posture",
              "Cold weather",
              "Stress",
            ],
          },
          {
            name: "Is there any Trauma or Injury Occurred in the Area",
            items: ["Yes", "No"],
          },
        ],
      },
      {
        name: "Neck Swelling",
        options: [
          { name: "Onset", items: ["Gradual", "Sudden"] },
          { name: "Duration", items: ["Days", "Week", "Month"] },
          {
            name: "Progression",
            items: ["Slow growing", "Rapid growing", "Sudden change"],
          },
          { name: "Pain", items: ["Painful", "Pain less"] },
          {
            name: "Pressure Symptom",
            items: [
              "Shortness of breath",
              "Difficulty of swallowing",
              "Hoarseness of voice",
            ],
          },
        ],
      },
    ],
  },
];
export const hospitalFields = [
  {
    name: "hospitalName",
    label: "Hospital name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },

  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
  {
    name: "hospitalType",
    label: "Hospital Type",
    type: "select",
    options: [
      { value: "cardiology", label: "Cardiology" },
      { value: "neurology", label: "Neurology" },
      { value: "oncology", label: "Oncology" },
      // ... add more options for specialties
    ], // Array of options for hospital types
  },
  {
    name: "specialty",
    label: "Specialty",
    type: "select",
    options: [
      { value: "general", label: "General" },
      { value: "childrens", label: "Children's" },
      { value: "cardiac", label: "Cardiac" },
      // ... add more options for hospital types
    ], // Array of options for specialties
  },
];
export const pharmacyFields = [
  {
    name: "pharmacyName",
    label: "Pharmacy name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },

  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
];
export const diagnosticsFields = [
  {
    name: "diagnosticName",
    label: "Diagnostic center name",
    type: "text",
    required: true,
  },
  { name: "city", label: "City", type: "text" },
  {
    name: "contactEmail",
    label: "Email",
    type: "email",
    required: true,
  },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password", required: true },
  {
    name: "confirmPassword",
    label: "Confirm Password",
    type: "password",
    required: true,
  },
];
export const doctorFields = [
  { name: "firstName", label: "First Name", type: "text" },
  { name: "lastName", label: "Last Name", type: "text" },
  { name: "licenseNumber", label: "License Number", type: "number" },
  {
    name: "specialty",
    label: "Specialty",
    type: "select",
    options: [
      { value: "", label: "" },
      { value: "familyMedicine", label: "Family Medicine" },
      { value: "internalMedicine", label: "Internal Medicine" },
      { value: "pediatrics", label: "Pediatrics" },
    ],
  },
  { name: "email", label: "Email", type: "email" },
  { name: "phone", label: "Phone", type: "phone" },
  { name: "password", label: "Password", type: "password" },
  { name: "confirmPassword", label: "Confirm Password", type: "password" },
];
export const patientSignUpFields = [
  { label: "Name", name: "fullName", type: "text", required: true },
  { label: "Birth Year", name: "birthYear", type: "date" },
  {
    label: "Sex",
    name: "sex",
    type: "select",
    options: ["", "male", "female"],
  },
  { label: "Email", name: "email", type: "email" },
];
//
export const PatientGeneralFormData = {
  title: "GENERAL CLINICAL GUIDANCE",
  adultFields: [
    {
      type: "radioGroup",
      label: "Are you alcoholic?",
      name: "alcoholic",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "Do you have a history of diseases that are listed below?",
      name: "diabetes",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "Hypertension",
      name: "hypertension",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "Cardiac illness",
      name: "cardiac",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "radioGroup",
      label: "If female, are you pregnant?",
      name: "pregnant",
      options: ["Yes", "No"],
      conditional: true,
    },
    {
      type: "textarea",
      label: "Medication history",
      name: "medication_history",
    },
    {
      type: "textarea",
      label: "Past medical history",
      name: "past_medical_history",
    },
  ],
  childFields: [
    {
      type: "radioGroup",
      label: "Brief medical history",
      name: "smoker",
      options: ["Yes", "No"],
      required: true,
    },
    {
      type: "radioGroup",
      label: "Do you have a history of diseases that are listed below?",
      name: "diabetes",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "HIV/AIDS",
      name: "hivAids",
      options: ["Yes", "No"],
    },
    {
      type: "radioGroup",
      label: "History of drug allergy",
      name: "drugAllergy",
      options: ["Yes", "No"],
    },
  ],
};
export const sidebarMainData = [
  { path: "index", label: "Index" },
  { path: "home", label: "Home" },
  { path: "find-doctor", label: "Find doctor" },
  { path: "find_right_doctor", label: "Find Right doctor" },
  { path: "health_feed", label: "Health feed" },
  { path: "order_Medicine", label: "Order Medicine" },
  { path: "press", label: "Press" },
  { path: "security", label: "Security" },
  { path: "video-consult", label: "Video Consult" },
];
export const sidebarFooterData = [
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-search"></i>
        <span className="sidebar-span-text">Find Doctors</span>-Book an
        appointment
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-play"></i>
        <span className="sidebar-span-text">Video Consult</span>-Consult top
        doctors
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-pills"></i>
        <span className="sidebar-span-text">Medicine</span>-YESMADE Pharmacy
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-flask"></i>
        <span className="sidebar-span-text">Lab Tests</span>-Book tests &
        checkup
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-scalpel"></i>
        <span className="sidebar-span-text">Surgeries</span>-Expert surgical
        care
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-download"></i>Download the app
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-clock"></i>Faster & complete healthcare experience
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-book-open"></i>Read health articles
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-file-medical-alt"></i>View medical records
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-book"></i>Read about medicines
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-user-md"></i>YESMADE for Providers
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-shield-alt"></i>ABDM - Data security
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-question-circle"></i>Help
      </>
    ),
  },
  {
    path: "#",
    label: (
      <>
        <i className="fas fa-phone-alt"></i>Contact us
      </>
    ),
  },
  {
    path: "#",
    label: "Terms of service",
  },
];
export const footerData = {
  yesMade: {
    title: "YESMADE",
    items: ["About", "Blog", "Careers", "Press", "Contact Us"],
  },
  forDoctors: {
    title: "For Doctors",
    items: ["YESMADE Consult", "YESMADE Health Feed", "YESMADE Profile"],
  },

  forHospitals: {
    title: "For Hospitals",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  forPatients: {
    title: "For Patients",
    items: [
      "Symptom guidance",
      "Hospital suggestion",
      "Doctor suggestion",
      "Pharmacy suggestion",
      "Diagnostic center suggestion",
      "Home Care suggestion",
      "Nutrition suggestion",
      "Physiotherapy suggestion",
      "Medical equipment needed for home care",
      "Medical travel agency suggestion",
    ],
  },
  forDiagnosis: {
    title: "For Diagnosis",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  forPharmacy: {
    title: "For Pharmacy",
    items: [
      "Insta by YESMADE",
      "Qikwell by YESMADE",
      "YESMADE Profile",
      "YESMADE Reach",
      "YESMADE Drive",
    ],
  },
  more: {
    title: "More",
    items: [
      "Help",
      "Developers",
      "Privacy Policy",
      "Terms & Conditions",
      "PCS T&C",
      "Healthcare Directory",
      "YESMADE Health Wiki",
    ],
  },
  social: {
    title: "Social",
    items: ["Facebook", "Twitter", "LinkedIn", "Youtube", "Github"],
  },
};

export const drSpecialties = [
  { key: "dermatology", value: "dermatology", label: "Dermatology" },
  { key: "cardiology", value: "cardiology", label: "Cardiology" },
  { key: "neurology", value: "neurology", label: "Neurology" },
  { key: "pediatrics", value: "pediatrics", label: "Pediatrics" },
  { key: "orthopedics", value: "orthopedics", label: "Orthopedics" },
];
export const selectLoginData = [
  {
    label: "Patient",
    key: "patient",
    value: "patient",
  },
  {
    label: "Doctor",
    key: "doctor",
    value: "doctor",
  },
  {
    label: "Hospital",
    key: "hospital",
    value: "hospital",
  },
  {
    label: "Pharmacy",
    key: "pharmacy",
    value: "pharmacy",
  },
  {
    label: "Diagnostics",
    key: "diagnostic_center",
    value: "diagnostic_center",
  },
];
export const locationLists = [
  "Arada",
  "Arat Kilo",
  "Asko",
  "Ayat",
  "Bole",
  "Bole Bulbula",
  "CMC",
  "Gofa",
  "Gulele",
  "Haya Hulet",
  "Jemo",
  "Kazanchis",
  "Kolfe",
  "Kotebe",
  "Lafto",
  "Lebu",
  "Lideta",
  "Megenagna",
  "Meknisa",
  "Meskel Square",
  "Mexico",
  "Old Airport",
  "Piazza",
  "Qality",
  "Sar Bet",
  "Shiro Meda",
  "Sidist Kilo",
  "Summit",
  "Urael",
  "Wello Sefer",
  "Yeka",
  "Addis Ketema",
  "Akaki Kality",
  "Nifas Silk-Lafto",
  "Bole Medhane Alem",
  "Gurd Shola",
  "Gerji",
  "Gelan Condominium",
  "Gotera",
  "Zenebework",
  "Alem Bank",
  "Bambis",
  "Kera",
  "Saris",
  "Dembel City Center",
  "Sarbet",
];
export const formPostFields = [
  { name: "title", label: "Title", placeholder: "Title" },
  { name: "author", label: "Author", placeholder: "Author" },
];
export const pharmacyMedicineFormFields = [
  {
    name: "name",
    label: "Medicine Name",
    placeholder: "Medicine Name",
  },
  { name: "storage", label: "Storage", placeholder: "storage" },
  { name: "purpose", label: "purpose", placeholder: "purpose" },
  { name: "frequency", label: "Frequency", placeholder: "Frequency" },
  { name: "type", label: "Type", placeholder: "Type" },
];
// hospitals and diagnostic service from
export const diagnosticServiceFormFields = [
  {
    name: "serviceName",
    label: "Service Name",
    placeholder: "Enter the service name",
  },
  { name: "title", label: "Title", placeholder: "Enter the title" },
  { name: "owner", label: "Owner Name ", placeholder: "" },
  {
    name: "specializationType",
    label: "Specialization Type",
    placeholder: "Enter the specialization type",
  },
];
export const categoryFormOptions = [
  { key: "serviceArea1", value: "serviceArea1", label: "Service Area 1" },
  { key: "serviceArea2", value: "serviceArea2", label: "Service Area 2" },
  { key: "serviceArea3", value: "serviceArea3", label: "Service Area 3" },
];
export const fieldFormOptions = [
  { key: "ourService", value: "ourService", label: "Our Service" },
  {
    key: "hospitalService",
    value: "hospitalService",
    label: "Hospital Service",
  },
  {
    key: "diagnosticService",
    value: "diagnosticService",
    label: "Diagnostic Service",
  },
];
export const serviceTypeFormOptions = [
  { key: "General", value: "General", label: "General" },
  { key: "Specialized", value: "Specialized", label: "Specialized" },
  { key: "Emergency", value: "Emergency", label: "Emergency" },
  { key: "Imaging", value: "Imaging", label: "Imaging" },
  { key: "LabTests", value: "Lab Tests", label: "Lab Tests" },
  {
    key: "CardiologyTests",
    value: "Cardiology Tests",
    label: "Cardiology Tests",
  },
  { key: "NeurologyTests", value: "Neurology Tests", label: "Neurology Tests" },
];

// export const pharmacyPostFields = [
//   { name: "title", label: "Title", placeholder: "Title" },
//   {
//     name: "medicineName",
//     label: "Medicine Name",
//     placeholder: "Medicine Name",
//   },
//   { name: "description", label: "Description", placeholder: "Description" },
//   {
//     name: "usage",
//     label: "Usage/Indications",
//     placeholder: "Usage/Indications",
//   },
// ];

// export const hospitalPostFields = [
//   { name: "title", label: "Title", placeholder: "Title" },
//   { name: "serviceName", label: "Service Name", placeholder: "Service Name" },
//   { name: "description", label: "Description", placeholder: "Description" },
//   {
//     name: "contactInfo",
//     label: "Contact Information",
//     placeholder: "Contact Information",
//   },
// ];

// export const diagnosticPostFields = [
//   { name: "title", label: "Title", placeholder: "Title" },
//   { name: "serviceName", label: "Service Name", placeholder: "Service Name" },
//   { name: "description", label: "Description", placeholder: "Description" },
//   { name: "procedure", label: "Procedure", placeholder: "Procedure" },
// ];

export const userData = [
  {
    id: 1,
    userId: "1",
    telegramId: "12345",
    username: "alice123",
    phone: "1234567890",
    role: "doctor",
    fullName: "Alice Johnson",
    email: "alice.johnson@example.com",
    address: "123 Main St",
    latitude: 40.7128,
    longitude: -74.006,
    age: 30,
    branchNumber: "BN001",
    specialty: "Cardiology",
    experience: 5,
    licenseNumber: "LN12345",
    organizationName: "HealthCare Inc.",
    status: "active",
    type: "admin",
  },
  {
    id: 2,
    userId: "2",
    telegramId: "67890",
    username: "bob456",
    phone: "9876543210",
    role: "nurse",
    fullName: "Bob Smith",
    email: "bob.smith@example.com",
    address: "456 Elm St",
    latitude: 34.0522,
    longitude: -118.2437,
    age: 28,
    branchNumber: "BN002",
    specialty: "Pediatrics",
    experience: 3,
    licenseNumber: "LN67890",
    organizationName: "City Hospital",
    status: "active",
    type: "user",
  },
  {
    id: 3,
    userId: "3",
    telegramId: "11121",
    username: "carol789",
    phone: "5556667777",
    role: "pharmacist",
    fullName: "Carol Lee",
    email: "carol.lee@example.com",
    address: "789 Pine St",
    latitude: 37.7749,
    longitude: -122.4194,
    age: 35,
    branchNumber: "BN003",
    specialty: "Oncology",
    experience: 7,
    licenseNumber: "LN11121",
    organizationName: "Medicorp",
    status: "active",
    type: "user",
  },
  {
    id: 4,
    userId: "4",
    telegramId: "22232",
    username: "dave101",
    phone: "4445556666",
    role: "doctor",
    fullName: "Dave Brown",
    email: "dave.brown@example.com",
    address: "321 Oak St",
    latitude: 40.73061,
    longitude: -73.935242,
    age: 42,
    branchNumber: "BN004",
    specialty: "Neurology",
    experience: 12,
    licenseNumber: "LN22232",
    organizationName: "NeuroHealth",
    status: "active",
    type: "admin",
  },
  {
    id: 5,
    userId: "5",
    telegramId: "33343",
    username: "eve202",
    phone: "3334445555",
    role: "nurse",
    fullName: "Eve White",
    email: "eve.white@example.com",
    address: "654 Cedar St",
    latitude: 51.5074,
    longitude: -0.1278,
    age: 29,
    branchNumber: "BN005",
    specialty: "Emergency",
    experience: 4,
    licenseNumber: "LN33343",
    organizationName: "Global Hospital",
    status: "active",
    type: "user",
  },
  {
    id: 6,
    userId: "6",
    telegramId: "44454",
    username: "frank303",
    phone: "2223334444",
    role: "pharmacist",
    fullName: "Frank Green",
    email: "frank.green@example.com",
    address: "987 Birch St",
    latitude: 48.8566,
    longitude: 2.3522,
    age: 38,
    branchNumber: "BN006",
    specialty: "Orthopedics",
    experience: 10,
    licenseNumber: "LN44454",
    organizationName: "Orthopedic Center",
    status: "active",
    type: "admin",
  },
  {
    id: 7,
    userId: "7",
    telegramId: "55565",
    username: "grace404",
    phone: "1112223333",
    role: "doctor",
    fullName: "Grace Taylor",
    email: "grace.taylor@example.com",
    address: "321 Maple St",
    latitude: 34.0522,
    longitude: -118.2437,
    age: 31,
    branchNumber: "BN007",
    specialty: "Dermatology",
    experience: 6,
    licenseNumber: "LN55565",
    organizationName: "SkinCare Clinic",
    status: "active",
    type: "user",
  },
  {
    id: 8,
    userId: "8",
    telegramId: "66676",
    username: "hank505",
    phone: "4445556666",
    role: "nurse",
    fullName: "Hank Walker",
    email: "hank.walker@example.com",
    address: "654 Spruce St",
    latitude: 35.6895,
    longitude: 139.6917,
    age: 27,
    branchNumber: "BN008",
    specialty: "Geriatrics",
    experience: 2,
    licenseNumber: "LN66676",
    organizationName: "Senior Care",
    status: "active",
    type: "user",
  },
  {
    id: 9,
    userId: "9",
    telegramId: "77787",
    username: "ivy606",
    phone: "5556667777",
    role: "pharmacist",
    fullName: "Ivy Wilson",
    email: "ivy.wilson@example.com",
    address: "987 Willow St",
    latitude: 55.7558,
    longitude: 37.6173,
    age: 34,
    branchNumber: "BN009",
    specialty: "Endocrinology",
    experience: 8,
    licenseNumber: "LN77787",
    organizationName: "Endocrine Health",
    status: "active",
    type: "admin",
  },
  {
    id: 10,
    userId: "10",
    telegramId: "88898",
    username: "jack707",
    phone: "6667778888",
    role: "doctor",
    fullName: "Jack Martinez",
    email: "jack.martinez@example.com",
    address: "123 Cherry St",
    latitude: 39.9042,
    longitude: 116.4074,
    age: 45,
    branchNumber: "BN010",
    specialty: "Gastroenterology",
    experience: 15,
    licenseNumber: "LN88898",
    organizationName: "Digestive Health",
    status: "active",
    type: "user",
  },
  {
    id: 11,
    userId: "11",
    telegramId: "99909",
    username: "kate808",
    phone: "7778889999",
    role: "nurse",
    fullName: "Kate Robinson",
    email: "kate.robinson@example.com",
    address: "456 Walnut St",
    latitude: 41.8781,
    longitude: -87.6298,
    age: 26,
    branchNumber: "BN011",
    specialty: "Anesthesiology",
    experience: 3,
    licenseNumber: "LN99909",
    organizationName: "Anesthesia Associates",
    status: "active",
    type: "admin",
  },
  {
    id: 12,
    userId: "12",
    telegramId: "00010",
    username: "leo909",
    phone: "8889990000",
    role: "pharmacist",
    fullName: "Leo Scott",
    email: "leo.scott@example.com",
    address: "789 Fir St",
    latitude: 40.4168,
    longitude: -3.7038,
    age: 37,
    branchNumber: "BN012",
    specialty: "Rheumatology",
    experience: 9,
    licenseNumber: "LN00010",
    organizationName: "Joint Care Center",
    status: "active",
    type: "user",
  },
  {
    id: 13,
    userId: "13",
    telegramId: "11112",
    username: "maya1010",
    phone: "9990001111",
    role: "doctor",
    fullName: "Maya Hernandez",
    email: "maya.hernandez@example.com",
    address: "321 Sycamore St",
    latitude: 52.52,
    longitude: 13.405,
    age: 40,
    branchNumber: "BN013",
    specialty: "Pulmonology",
    experience: 11,
    licenseNumber: "LN11112",
    organizationName: "Lung Health Clinic",
    status: "active",
    type: "admin",
  },
  {
    id: 14,
    userId: "14",
    telegramId: "22223",
    username: "nick1111",
    phone: "0001112222",
    role: "nurse",
    fullName: "Nick King",
    email: "nick.king@example.com",
    address: "654 Palm St",
    latitude: 59.9343,
    longitude: 30.3351,
    age: 33,
    branchNumber: "BN014",
    specialty: "Nephrology",
    experience: 5,
    licenseNumber: "LN22223",
    organizationName: "Kidney Care Center",
    status: "active",
    type: "user",
  },
  {
    id: 15,
    userId: "15",
    telegramId: "33334",
    username: "olivia1212",
    phone: "1112223334",
    role: "pharmacist",
    fullName: "Olivia Perez",
    email: "olivia.perez@example.com",
    address: "987 Poplar St",
    latitude: 55.6761,
    longitude: 12.5683,
    age: 36,
    branchNumber: "BN015",
    specialty: "Hematology",
    experience: 7,
    licenseNumber: "LN33334",
    organizationName: "Blood Care Clinic",
    status: "active",
    type: "admin",
  },
  {
    id: 16,
    userId: "16",
    telegramId: "44445",
    username: "paul1313",
    phone: "2223334445",
    role: "doctor",
    fullName: "Paul Clark",
    email: "paul.clark@example.com",
    address: "123 Cypress St",
    latitude: 35.6895,
    longitude: 139.6917,
    age: 50,
    branchNumber: "BN016",
    specialty: "Urology",
    experience: 20,
    licenseNumber: "LN44445",
    organizationName: "UroHealth Center",
    status: "active",
    type: "user",
  },
  {
    id: 17,
    userId: "17",
    telegramId: "55556",
    username: "quinn1414",
    phone: "3334445556",
    role: "nurse",
    fullName: "Quinn Davis",
    email: "quinn.davis@example.com",
    address: "456 Hickory St",
    latitude: 37.7749,
    longitude: -122.4194,
    age: 29,
    branchNumber: "BN017",
    specialty: "Ophthalmology",
    experience: 4,
    licenseNumber: "LN55556",
    organizationName: "Vision Care Center",
    status: "active",
    type: "admin",
  },
  {
    id: 18,
    userId: "18",
    telegramId: "66667",
    username: "rachel1515",
    phone: "4445556667",
    role: "pharmacist",
    fullName: "Rachel Martinez",
    email: "rachel.martinez@example.com",
    address: "789 Redwood St",
    latitude: 40.7128,
    longitude: -74.006,
    age: 41,
    branchNumber: "BN018",
    specialty: "Pathology",
    experience: 12,
    licenseNumber: "LN66667",
    organizationName: "Pathology Group",
    status: "active",
    type: "user",
  },
  {
    id: 19,
    userId: "19",
    telegramId: "77778",
    username: "sam1616",
    phone: "5556667778",
    role: "doctor",
    fullName: "Sam Evans",
    email: "sam.evans@example.com",
    address: "123 Ash St",
    latitude: 48.8566,
    longitude: 2.3522,
    age: 43,
    branchNumber: "BN019",
    specialty: "Radiology",
    experience: 15,
    licenseNumber: "LN77778",
    organizationName: "Radiology Clinic",
    status: "active",
    type: "admin",
  },
  {
    id: 20,
    userId: "20",
    telegramId: "88889",
    username: "tina1717",
    phone: "6667778889",
    role: "nurse",
    fullName: "Tina Young",
    email: "tina.young@example.com",
    address: "456 Willow St",
    latitude: 55.7558,
    longitude: 37.6173,
    age: 32,
    branchNumber: "BN020",
    specialty: "Pediatrics",
    experience: 5,
    licenseNumber: "LN88889",
    organizationName: "Child Care Hospital",
    status: "active",
    type: "user",
  },
];
//
///////////////////////////////////////////////////////
// TABLE DATA
export const diagnosticColumnData = [
  {
    title: "Info",
    key: "info",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["phone"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL : {record["email"]}</div>
        <div className="tableSubCols details">
          Username: {record["username"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
      </>
    ),
  },
  {
    title: "Detail",
    key: "detail",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading ">{record["specialty"]}</div>
        <div className="tableSubCols details">
          Role {record["role"]?.toUpperCase()}
        </div>

        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["licenseNumber"]}
        </div>

        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Organizational Info",
    key: "organizationalInfo",
    render: (text, record) => (
      <>
        {" "}
        <div className="tableSubCols heading  ">
          {record["organizationName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["branchNumber"]}
        </div>
      </>
    ),
  },
];
export const hospitalColumnData = [
  {
    title: "Info",
    key: "info",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["phone"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL : {record["email"]}</div>
        <div className="tableSubCols details">
          Username: {record["username"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
      </>
    ),
  },
  {
    title: "Detail",
    key: "detail",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading ">{record["specialty"]}</div>
        <div className="tableSubCols details">
          Role {record["role"]?.toUpperCase()}
        </div>

        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["licenseNumber"]}
        </div>

        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Organizational Info",
    key: "organizationalInfo",
    render: (text, record) => (
      <>
        {" "}
        <div className="tableSubCols heading  ">
          {record["organizationName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["branchNumber"]}
        </div>
      </>
    ),
  },
];
export const pharmacyColumnData = [
  {
    title: "Info",
    key: "info",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["phone"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">EMAIL : {record["email"]}</div>
        <div className="tableSubCols details">
          Username: {record["username"]}
        </div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
      </>
    ),
  },
  {
    title: "Detail",
    key: "detail",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading ">{record["specialty"]}</div>
        <div className="tableSubCols details">
          Role {record["role"]?.toUpperCase()}
        </div>

        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["licenseNumber"]}
        </div>

        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Organizational Info",
    key: "organizationalInfo",
    render: (text, record) => (
      <>
        {" "}
        <div className="tableSubCols heading  ">
          {record["organizationName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          BRANCH NUMBER: {record["branchNumber"]}
        </div>
      </>
    ),
  },
];
export const doctorColumnData = [
  {
    title: "Personal Info",
    key: "personalInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">
          SPECIALTY: {record["specialty"]}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">
          LICENSE NUMBER: {record["licenseNumber"]}
        </div>

        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Contact Info",
    key: "contactInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "identificationInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["role"]}</div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
      </>
    ),
  },
];

export const patientColumnData = [
  {
    title: "Personal Info",
    key: "personalInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Contact Info",
    key: "contactInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "identificationInfo",
    render: (text, record) => (
      <>
        {" "}
        <div className="tableSubCols heading">
          {record["role"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details ">USER ID: {record["userId"]}</div>
      </>
    ),
  },
];
export const staffColumnData = [
  {
    title: "Personal Info",
    key: "personalInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">
          {record["fullName"]?.toUpperCase()}
        </div>
        <div className="tableSubCols details">AGE: {record["age"]}</div>
        <div className="tableSubCols details">SEX: {record["sex"]}</div>
        <div className="tableSubCols details">
          EXPERIENCE: {record["experience"]}
        </div>
        <div className="tableSubCols details">STATUS: {record["status"]}</div>
      </>
    ),
  },
  {
    title: "Contact Info",
    key: "contactInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading "> {record["phone"]}</div>
        <div className="tableSubCols details">EMAIL: {record["email"]}</div>
        <div className="tableSubCols details">
          TELEGRAM ID: {record["telegramId"]}
        </div>
        <div className="tableSubCols details">
          USERNAME: {record["username"]}
        </div>
      </>
    ),
  },
  {
    title: "Identification Info",
    key: "identificationInfo",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading"> {record["type"]}</div>
        <div className="tableSubCols details">USER ID: {record["userId"]}</div>
        <div className="tableSubCols details">ROLE: {record["role"]}</div>
      </>
    ),
  },
];
//////
export const excludedOrganizationsColumnData = [
  "age",
  "branchNumber",
  "createdAt",
  "details",
  "email",
  "experience",
  "fullName",
  "id",
  "latitude",
  "licenseNumber",
  "longitude",
  "organizationName",
  "phone",
  "role",
  "specialty",
  "status",
  "telegramId",
  "updatedAt",
  "userId",
  "username",
];
export const excludedDoctorData = [
  "age",
  "createdAt",
  "details",
  "email",
  "experience",
  "fullName",
  "id",
  "latitude",
  "licenseNumber",
  "longitude",
  "phone",
  "role",
  "sex",
  "specialty",
  "status",
  "telegramId",
  "updatedAt",
  "userId",
  "username",
];
export const excludedStaffData = [
  "age",
  "createdAt",
  "details",
  "email",
  "fullName",
  "id",
  "phone",
  "type",
  "role",
  "sex",
  "status",
  "telegramId",
  "updatedAt",
  "userId",
  "username",
];
