import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PatientGeneralFormData } from "../../../../services/data";
import "./PatientGeneralForm.css"; // Assuming you have CSS file for styling
import "../../../../App.css";
import { useNavigate } from "react-router-dom";

const PatientForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let dataToRender =
    id === "formChild"
      ? PatientGeneralFormData.childFields
      : PatientGeneralFormData.adultFields;

  const [formData, setFormData] = useState({
    smoker: "",
    alcoholic: "",
    diabetes: "",
    hypertension: "",
    cardiac: "",
    hivAids: "",
    drugAllergy: "",
    medication_history: "",
    pregnant: "",
    past_medical_history: "",
  });

  const handleChange = (event, fieldName) => {
    const newFormData = { ...formData, [fieldName]: event.target.value };
    setFormData(newFormData);
    localStorage.setItem("patientGeneralData", JSON.stringify(newFormData));
  };

  const handleInputClick = (event, fieldName) => {
    const clickedFieldData = { [fieldName]: formData[fieldName] };
    localStorage.setItem("clickedFieldData", JSON.stringify(clickedFieldData));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("patientGeneralData", JSON.stringify(formData));
    console.log("Form submitted with data:", formData);
    navigate("/patient_general_form_history");
  };

  const renderField = (field) => {
    const { type, label, name, options, required } = field;

    return (
      <div key={name} className="form-wrapper patient-form-wrapper">
        <div className="title">
          <label htmlFor={name}>{label}</label>
        </div>
        <div className="content">
          {type === "radioGroup" ? (
            options.map((option, i) => (
              <div key={i} className="radio-wrapper">
                <input
                  type="radio"
                  id={`${name}-${option}`}
                  name={name}
                  value={option}
                  checked={formData[name] === option}
                  onChange={(e) => handleChange(e, name)}
                  onClick={(e) => handleInputClick(e, name)}
                  required={required}
                />
                <label htmlFor={`${name}-${option}`}> {option} </label>
              </div>
            ))
          ) : type === "textarea" ? (
            <textarea
              id={name}
              name={name}
              value={formData[name]}
              onChange={(e) => handleChange(e, name)}
              onClick={(e) => handleInputClick(e, name)}
            />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="patientGeneralForm">
        <div className="patient-form-container">
          <div className="form-heading">
            <h2>GENERAL CLINICAL GUIDANCE</h2>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-label">
              <h3>Brief Medical History</h3>
            </div>

            {dataToRender.map((field) => renderField(field))}

            <button className="btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PatientForm;
