import React, { useState, useEffect, useMemo } from "react";
import PostTable from "../../../common/post-table/postTable";
const DoctorPostTable = () => {
  const postOptionData = [
    { key: "article", value: "article", label: "Article" },
  ];
  return <PostTable postOptionData={postOptionData} />;
};

export default DoctorPostTable;
