import { React } from "react";

import Footer from "../../components/common/footer/Footer.jsx";
import Sidebar from "../../components/common/sidebar/Sidebar.jsx";
function Profile({ ProfileComp }) {
  return (
    <>
      <Sidebar />
      <ProfileComp />
      <Footer />
    </>
  );
}

export default Profile;
