import React from "react";
import { Form, Input, Select, Button } from "antd";
import { locationLists } from "../../../services/data.js";
import { LocationRequest } from "../location-selector/LocationSelector.jsx";
import { handleFilterNumber } from "../../../utils/helper.js";
import WorkTimePicker from "../work-time-picker/WorkTimePicker.jsx";
const DiagnosticsSignUp = ({
  form,
  handleSubmit,
  submitAllowed = true,
  setSubmitAllowed,
  createLoading,
  PhoneNumberInput,
}) => {
  return (
    <Form
      className="sign-up-form"
      form={form}
      layout="vertical"
      onChange={() => setSubmitAllowed(true)}
      onFinish={handleSubmit}
    >
      {PhoneNumberInput && <PhoneNumberInput form={form} />}
      <Form.Item
        label="Diagnostic center name"
        name="organizationName"
        rules={[
          {
            required: true,
            message: "Please fill the diagnostic center name!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please fill your address!" }]}
      >
        <Select showSearch>
          {locationLists.map((place) => (
            <Select.Option key={place} value={place}>
              {place}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Branch Number"
        name="branchNumber"
        rules={[{ required: true, message: "Please fill your Branch Number!" }]}
        onKeyPress={handleFilterNumber}
        placeholder="Branch Number"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please fill a valid email!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please fill the password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[{ required: true, message: "Please confirm your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <WorkTimePicker form={form} />

      <Form.Item
        label="Location"
        name="location"
        rules={[{ required: true, message: "Please fill your location" }]}
      >
        <LocationRequest form={form} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={createLoading}>
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DiagnosticsSignUp;
