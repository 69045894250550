import React from "react";
import { Upload, Button, Form, Input, Select, Textarea } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import "./PostForm.css";
import { formPostFields } from "../../../services/data";

const { Option } = Select;

const PostsForm = ({
  form,
  handleModalOk,
  onChange,
  onPreview,
  fileList,
  postOptionData,
}) => {
  return (
    <>
      <Form
        className=" "
        onFinish={handleModalOk}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Select Post options"
          name="field"
          rules={[
            {
              required: true,
              message: "Please select an option!",
            },
          ]}
        >
          <Select placeholder="Please select an option">
            {postOptionData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {formPostFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={[
              {
                required: true,
                message: `Please fill the ${field.label.toLowerCase()}!`,
              },
            ]}
          >
            <Input placeholder={field.placeholder} />
          </Form.Item>
        ))}

        <Form.Item
          label="Content"
          name="content"
          rules={[
            {
              required: true,
              message: "Please insert content!",
            },
          ]}
        >
          <Input.TextArea placeholder="Please insert content" />
        </Form.Item>

        <Form.Item label="Post image" name="image">
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false} // Prevent auto-upload
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}> Upload</Button>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </>
  );
};

export default PostsForm;
