import { React, useState, useEffect } from "react";

import logo from "../../../image/logo.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "../../../App.css";
import "./Header.css";
import { useAccSidebarToggle } from "../../../contexts/accSidebarVisibility";

function Header({ onBarsClick }) {
  const { showAccSidebar } = useAccSidebarToggle();
  //
  return (
    <header id="header">
      <nav id="nav-bar">
        <div className="nav-container">
          <div id="first-nav">
            <div className="container">
              <div className="first-child">
                <FaBars className="btn-bars" onClick={onBarsClick} />
                <img className="logo" src={logo} alt="" />
              </div>

              <ul className="navigation-list">
                {/* <li>
                  <a href="index.html">Index</a>
                </li> */}
                <li>
                  <a href="home.html">Home</a>
                </li>
                <li>
                  <a href="find-doctor.html">Doctor Search</a>
                </li>
                <li>
                  <a href="health_feed.html">Health Feed</a>
                </li>
                <li>
                  <a href="order_Medicine.html">Order Medicine</a>
                </li>

                <li>
                  <a href="video-consult.html">Consult</a>
                </li>
              </ul>

              <li>
                {" "}
                <div className="header-account-img" onClick={showAccSidebar}>
                  <img src="../images/acc-img/sample.png" alt="" />
                </div>
              </li>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
