import React, { useEffect, useState } from "react";
import "./PatientHistory.css";
const PatientHistory = () => {
  const [formData, setFormData] = useState({});
  const [modalFormDatas, setModalFormData] = useState({});

  useEffect(() => {
    const storedData = localStorage.getItem("patientFormData");
    console.log("Stored Data:", storedData); // Check stored data
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);
  useEffect(() => {
    const modalStoredData = localStorage.getItem("modalFormData");
    console.log("Stored modal Data:", modalStoredData); // Check stored data
    if (modalStoredData) {
      setModalFormData(JSON.parse(modalStoredData));
    }
  }, []);

  return (
    <>
      <div id="patient-history">
        <div className="container">
          <h2>Form Data Display</h2>
          {Object.keys(formData).map((symptom, index) => (
            <div
              className="form-data-item box-shadow border py-2 form-container"
              key={index}
            >
              <h1>{symptom}</h1>
              <ul className="ul">
                {Object.keys(modalFormDatas).map((key, idx) => (
                  <li className="li" key={idx}>
                    <h4>{key}:</h4>
                    <ul className="nested-ul">
                      {Object.entries(modalFormDatas[key]).map(
                        ([subKey, value]) => (
                          <li className="nested-li" key={subKey}>
                            {value}
                          </li>
                        )
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PatientHistory;
