import React from "react";
import {
  PlusOutlined,
  ShopOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

const AccSidebarComp = ({ role }) => {
  const RenderSidebarMenu = () => {
    switch (role) {
      case "patient":
        return PatientMenu();
      case "doctor":
        return DoctorMenu();
      case "hospital":
        return HospitalMenu();
      case "pharmacy":
        return PharmacyMenu();
      case "diagnostic_center":
        return DiagnosticsMenu();
      default:
        return <></>;
    }
  };
  return RenderSidebarMenu();
};
const DoctorMenu = () => {
  return (
    <>
      <li>
        <Link to={`/app/doctor_Profile`}>
          <UserOutlined /> <span>Profile</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/doctor_post_detail`}>
          <FileTextOutlined /> <span>Posts</span>
        </Link>
      </li>
    </>
  );
};
const PharmacyMenu = () => {
  return (
    <>
      <li>
        <Link to={`/app/pharmacy_profile`}>
          <UserOutlined /> <span>Profile</span>
        </Link>
      </li>
      {/* <li>
        <Link to={`/app/create_post`}>
          <FileTextOutlined /> <span>Posts</span>
        </Link>
      </li> */}
      <li>
        <Link to={`/app/pharmacy_medicine_detail`}>
          <PlusOutlined /> <span>Add Medicine</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/pharmacistCart`}>
          <ShopOutlined /> <span>Orders</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/notificationCenter`}>
          <ShopOutlined /> <span>Notifications</span>
        </Link>
      </li>
    </>
  );
};
const PatientMenu = () => {
  return (
    <>
      <li>
        <Link to={`/app/patient_Profile`}>
          <UserOutlined /> <span>Profile</span>
        </Link>
      </li>
    </>
  );
};
const HospitalMenu = () => {
  return (
    <>
      <li>
        <Link to={`/app/hospital_profile `}>
          <UserOutlined /> <span>Profile</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/hospital_service_detail`}>
          <FileTextOutlined /> <span>Services</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/hospital_add_doctors_detail`}>
          <FileTextOutlined /> <span>Add Doctors</span>
        </Link>
      </li>
    </>
  );
};
const DiagnosticsMenu = () => {
  return (
    <>
      <li>
        <Link to={`/app/diagnostic_Profile`}>
          <UserOutlined /> <span>Profile</span>
        </Link>
      </li>
      <li>
        <Link to={`/app/diagnostic_service_detail`}>
          <FileTextOutlined /> <span>Add Service</span>
        </Link>
      </li>
    </>
  );
};

export default AccSidebarComp;
