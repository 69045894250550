import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { medicalProfessionalsData } from "../../../../services/data.js";
import Footer from "../../../common/footer/Footer.jsx";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
import { SearchDoctor } from "../doctor-list/DoctorList.jsx";
import ProfileImg from "../../../../image/service-img-5.png";
import useFetch from "../../../../hooks/useFetch.js";
import {
  FaCommentDots,
  FaThumbsUp,
  FaGem,
  FaCheckCircle,
} from "react-icons/fa";
import "../doctor-list/DoctorList.css";

const DoctorDetail = () => {
  const [medicalProfessionalsData, setmedicalProfessionalsData] = useState();
  const [medicalProfessionalsDataErr, setmedicalProfessionalsDataErr] =
    useState(false);
  const {
    data: medicalProfessionalsDataRes,
    loading: medicalProfessionalsLoading,
    error: medicalProfessionalsError,
    getRequest,
  } = useFetch("/api/medicalProfessionals");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicalProfessionalsDataRes?.data) {
      setmedicalProfessionalsData(medicalProfessionalsDataRes.data);
    }
    if (medicalProfessionalsError) {
      setmedicalProfessionalsDataErr(true);
    }
  }, [medicalProfessionalsDataRes, medicalProfessionalsError]);

  const { id } = useParams();
  const selectedDoctor = medicalProfessionalsData.find(
    (doctor) => doctor.id == id
  );
  const data = JSON.parse(localStorage.getItem("doctorData"));

  return (
    <>
      <div id="doctorInfo">
        <Sidebar />
        <SearchDoctor />

        <section
          id="find-right-doctor"
          className="border-bottom border box-shadow"
        >
          <div className="container">
            <div className="">
              <article className="doctor-card card border container-grid-2-1">
                <div className="flex-row">
                  <div className="circle-img">
                    <img
                      className="profile-image"
                      src={ProfileImg}
                      alt="Doctor Profile Image"
                    />
                  </div>

                  <div className=" ">
                    <h2 className="doctor-name">
                      <a href=""> Dr. {selectedDoctor.name} </a>
                      <span>
                        <span
                          className={
                            data.active ? "active-sign" : "inactive-sign"
                          }
                        ></span>
                      </span>
                    </h2>
                    <p className="doctor-specialty">General Physician</p>
                    <p className="doctor-experience">54 years experience</p>
                    <h3 className="patient-stories">
                      <span>
                        <FaCommentDots className="text-green" />
                      </span>
                      108 Patient Stories
                    </h3>
                  </div>
                </div>

                <div>
                  <div className="grid-2-1 border">
                    <div className="recommendation card-content">
                      <a>
                        <span className="bg-green mini-box">
                          <FaThumbsUp className="text-light" /> 93%
                        </span>
                        Patient Recommendation
                      </a>
                    </div>

                    <div className="excellence-rating card-content">
                      <a>
                        <span className="bg-blue mini-box">
                          <FaGem className="text-light" /> 4.0
                        </span>
                        Hospital Excellence Rating
                      </a>
                    </div>
                  </div>

                  <div className="card-content border">
                    <a href="#">Electronics City • Practo Hospitals - Neo</a>
                    <br />
                    <a href="#">
                      <span className="text-green">Open</span> • Updated today
                    </a>
                    <br />
                    <a href="#">~ ₹700 Consultation Fees</a> <br />
                  </div>

                  <div className="availability-info card-content border">
                    <h3 className="text-green">NEXT AVAILABLE AT</h3>

                    <div className="available-slots grid-2-1">
                      <div>
                        <small className="mx-1">11:30 AM, 29th Feb</small>
                        <Link className="btn btn-primary  btn-contact">
                          Contct Clinic
                        </Link>
                      </div>

                      <div>
                        <small className="mx-1">11:30 AM, 29th Feb</small>
                        <Link className="btn btn-primary btn-book">
                          Book Clinic
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default DoctorDetail;
