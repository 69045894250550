import { React, useState, useEffect, useRef } from "react";
import useFetch from "../../../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../../common/sidebar/Sidebar";
// import {
//   hospitalData,
// diagnosticMaterialData,
// } from "../../../../services/data";
import "./DiagnosticPortal.css";
import ErrorPage from "../../../common/error-page/ErrorPage";

function DiagnosticPortal() {
  const [hospitalData, setHospitalData] = useState();
  const [hospitalDataErr, setHospitalDataErr] = useState(false);
  const {
    data: hospitalDataRes,
    loading: hospitalLoading,
    error: hospitalError,
    getRequest: getHospitalData,
  } = useFetch("/api/hospitalDetails");
  useEffect(() => {
    getHospitalData();
  }, []);
  useEffect(() => {
    console.log("hospital", hospitalDataRes);
    if (hospitalDataRes?.data) {
      setHospitalData(hospitalDataRes.data);
    }
    if (hospitalError) {
      setHospitalDataErr(true);
    }
  }, [hospitalDataRes, hospitalError]);
  const hospitalId = 2;

  const hospitalFilterData = hospitalData?.find(
    (hosp) => hosp?.hospitalId == hospitalId
  );
  return (
    <>
      {hospitalDataErr && <ErrorPage />}
      {hospitalData && (
        <div id="diagnosticPortal">
          <Sidebar />
          <SearchEquipment />
          <section className="diagnostic-portal">
            <h1 className="diagnostic-title">{hospitalFilterData.name}</h1>
            <div className="medicine-grid">
              {hospitalFilterData.services.map((equipment) => {
                return (
                  <MedicineCard
                    key={equipment.hospitalId}
                    material={equipment}
                  />
                );
              })}
            </div>
          </section>
          <div className="clr"></div>
          <div className="last-button">
            <Link
              to={`/app/equipmentForm/${2}`}
              className="post-medicine-button"
            >
              Post a New Diagnostic Material
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
const SearchEquipment = () => {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null); // Track selected medicine
  const [material, setMaterial] = useState(diagnosticMaterialData);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const searchBarRef = useRef(null);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (material) => {
    setSelectedMaterial(material); // Set selected medicine for detailed view
  };

  useEffect(() => {
    console.log(searchTerm);
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);

    const filteredData = diagnosticMaterialData.filter((material) =>
      material.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes

  const updateMedicines = (newMaterial) => {
    setMaterial([...material, newMaterial]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);

  return (
    <>
      <section id="searchEquipment">
        <div className="equipment-nav-container">
          <div className="search-container">
            <div className="search-bar search">
              <div ref={searchBarRef} className="search-wrapper">
                <div>
                  <input
                    type="text"
                    placeholder="Search for Equipment"
                    value={searchTerm}
                    onFocus={() => setIsDropdownVisible(true)}
                    onChange={handleSearchTermChange}
                  ></input>
                </div>

                <div className="search-list-container">
                  <ul className="lists">
                    {searchResults.length > 0 // Check if results exist before rendering
                      ? searchResults.map((equipment) => (
                          <Link to={`/app/equipment/${equipment.postId}`}>
                            <li
                              className="mat-list"
                              key={equipment.postId}
                              onClick={() => handleMedicineClick(equipment)}
                            >
                              {equipment.title}
                            </li>
                          </Link>
                        ))
                      : searchTerm && <p>No results found.</p>}{" "}
                    {/* {cartItems.length > 0 && <ViewCart />} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const MedicineCard = ({ material }) => {
  return (
    <div className="medicine-card">
      <img src={material.image} alt="material.name"></img>
      <h2>Name: {material.name}</h2>
      <p>Usage: {material.usage}</p>
    </div>
  );
};

export default DiagnosticPortal;
export { SearchEquipment };
