import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DiagnosticProfile.css";
import ProfileImg from "../../../../image/service-img-5.png";
import { Modal, Form, Card, Button } from "antd";
import usePatch from "../../../../hooks/usePatch";
import useFetch from "../../../../hooks/useFetch";
import DiagnosticEditProfileForm from "../diagnostic-edit-profile-form/DiagnosticEditProfileForm";

const DiagnosticProfile = () => {
  const [diagnosticData, setDiagnosticData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  //crud
  const { patchData } = usePatch(`/api/users/${diagnosticData?.id}`);
  const { data, loading, getRequest } = useFetch("/api/users");
  //
  const handleEdit = async () => {
    if (diagnosticData) {
      form.setFieldsValue({
        fullName: diagnosticData.fullName,
        licenseNumber: diagnosticData.licenseNumber,
        experience: diagnosticData.experience,
        specialty: diagnosticData.specialty,
        email: diagnosticData.email,
        location: diagnosticData.location,
        workplaces: diagnosticData.workplaces, // Make sure this matches your data structure
      });
    }
    setIsModalVisible(true);
  };
  //
  useEffect(() => {
    setDiagnosticData(data?.users);
  }, [diagnosticData, data]);
  useEffect(() => {
    getRequest({ role: "diagnostic_center" });
  }, []);
  //
  const handleModalCancel = () => setIsModalVisible(false);
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await patchData(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div id="diagnostic-profile">
        <div className="container">
          <div className="form-container">
            {
              <Card
                className="antd-card"
                hoverable
                style={{
                  width: 280,
                }}
                cover={<></>}
              >
                {" "}
                <img
                  className=" "
                  src={ProfileImg}
                  alt="Diagnostic Profile Image"
                />
                <p>
                  <strong>Diagnostic Name:</strong>{" "}
                  {diagnosticData?.organizationName}
                </p>
                <p>
                  <strong>Address :</strong> {diagnosticData?.address}
                </p>
                <p>
                  <strong>Branch Number :</strong>{" "}
                  {diagnosticData?.branchNumber}
                </p>
                <p>
                  <strong>Email:</strong> {diagnosticData?.email}
                </p>
                <Button className="" onClick={() => handleEdit()}>
                  Edit Profile
                </Button>
                <Modal
                  title="Edit Post"
                  visible={isModalVisible}
                  onOk={handleModalOk}
                  onCancel={handleModalCancel}
                >
                  <DiagnosticEditProfileForm
                    form={form}
                    handleModalOk={handleModalOk}
                  />
                </Modal>
              </Card>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default DiagnosticProfile;
