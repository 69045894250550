import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useLocation } from "../../../contexts/locationProvider";
import "./LocationSelector.css";
import { Button } from "antd";

export const LocationRequest = ({ form }) => {
  const { location, setLocation } = useLocation();
  const [error, setError] = useState(null);
  const [manualSelect, setManualSelect] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    if (location) form?.setFieldsValue({ location });
  }, [location]);

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setLocation(newLocation);
          setError(null);
          setManualSelect(false);
        },
        () => {
          setError("Unable to retrieve your location. Please select manually.");
        }
      );
    } else {
      setError(
        "Geolocation is not supported by this browser. Please select manually."
      );
    }
  };

  const handleGetLocation = () => {
    requestLocation();
  };

  const handleManualLocationSelect = (lat, lng) => {
    const newLocation = {
      latitude: lat,
      longitude: lng,
    };
    setLocation(newLocation);
    setMarkerPosition([lat, lng]);
    setManualSelect(false);
    setError(null);
  };

  const handleManualSelectClick = () => {
    setManualSelect(true);
  };

  return (
    <div id="LocationSelector">
      <div className="form-group">
        <label>
          <h4>Location</h4>
        </label>
        <div className="location-btn-wrapper">
          <Button
            type="primary"
            className="btn-location"
            id="current-location"
            name="current-location"
            onClick={handleGetLocation}
          >
            Current Location
          </Button>
          <Button
            type="primary"
            className="btn-location"
            id="location"
            name="location"
            onClick={handleManualSelectClick}
          >
            Select Location
          </Button>
        </div>

        {location && (
          <div>
            <h4>Your Location:</h4>
            <p>Latitude: {location.latitude}</p>
            <p>Longitude: {location.longitude}</p>
          </div>
        )}

        {error && <p>{error}</p>}
        {manualSelect && (
          <ManualLocationSelector
            onLocationSelect={handleManualLocationSelect}
          />
        )}
      </div>
    </div>
  );
};

const ManualLocationSelector = ({ onLocationSelect }) => {
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider: provider,
        style: "bar",
        showMarker: true,
        retainZoomLevel: false,
        animateZoom: true,
        autoClose: true,
        searchLabel: "Enter address",
      });

      mapRef.current.addControl(searchControl);

      mapRef.current.on("geosearch/showlocation", (result) => {
        const { x, y } = result.location;
        onLocationSelect(y, x);
      });
    }
  }, [onLocationSelect]);

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        onLocationSelect(e.latlng.lat, e.latlng.lng);
      },
    });

    return null;
  };

  return (
    <MapContainer
      center={[9.005401, 38.763611]}
      zoom={13}
      style={{ height: "400px", width: "100%" }}
      ref={mapRef}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapClickHandler />
    </MapContainer>
  );
};
