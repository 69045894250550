import React from "react";
import { Form, TimePicker as AntdTimePicker } from "antd";

const WorkTimePicker = ({ form }) => {
  const handleStartTimeChange = (time) => {
    const endTime = form.getFieldValue("workTime")?.endTime;
    form.setFieldsValue({ workTime: { startTime: time, endTime } });
  };

  const handleEndTimeChange = (time) => {
    const startTime = form.getFieldValue("workTime")?.startTime;
    form.setFieldsValue({ workTime: { startTime, endTime: time } });
  };

  return (
    <Form.Item label="Work Time" name="workTime">
      <Form.Item
        name={["workTime", "startTime"]}
        noStyle
        rules={[{ required: true, message: "Please select the start time!" }]}
      >
        <AntdTimePicker
          format="h:mm A"
          use12Hours
          minuteStep={15}
          onChange={handleStartTimeChange}
        />
      </Form.Item>
      <Form.Item
        name={["workTime", "endTime"]}
        noStyle
        rules={[{ required: true, message: "Please select the end time!" }]}
      >
        <AntdTimePicker
          format="h:mm A"
          use12Hours
          minuteStep={15}
          onChange={handleEndTimeChange}
          style={{ marginLeft: 10 }}
        />
      </Form.Item>
    </Form.Item>
  );
};

export default WorkTimePicker;
