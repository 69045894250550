// PhoneNumberInput.jsx
import React from "react";
import { Form } from "antd";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./PhoneInput.css";

const PhoneNumberInput = ({ form }) => {
  const validatePhoneNumber = (rule, value) => {
    if (!value) {
      return Promise.reject(new Error("Phone number is required"));
    }

    const isValid = isValidPhoneNumber(value, "ET"); // Use the appropriate country code
    if (!isValid) {
      return Promise.reject(new Error("Invalid phone number format"));
    }
    return Promise.resolve();
  };

  const handlePhoneNumberChange = (formattedNumber) => {
    form.setFieldsValue({ phone: formattedNumber });
  };

  return (
    <Form.Item
      name="phone"
      label="Phone Number"
      rules={[
        { required: true, message: "Please enter your phone number" },
        { validator: validatePhoneNumber },
      ]}
      hasFeedback
    >
      <PhoneInput
        placeholder="Enter phone number"
        country="ET" // Set initial country (optional)
        defaultCountry="ET" // Set default country (optional)
        regions={["ET"]} // Restrict to specific regions (optional)
        className="ant-input custom-phone-input"
        onChange={handlePhoneNumberChange}
      />
    </Form.Item>
  );
};

export default PhoneNumberInput;
