import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePost from "../../../hooks/usePost.js";
import { Form } from "antd";

import useToast from "../../common/toast/toast.js";
import PhoneNumberInput from "../../common/phoneInput/PhoneInput.jsx";
import DoctorForm from "../../common/sign-up-forms/Doctor.jsx";
import PatientForm from "../../common/sign-up-forms/Patient.jsx";
import HospitalForm from "../../common/sign-up-forms/Hospital.jsx";
import PharmacyForm from "../../common/sign-up-forms/Pharmacy.jsx";
import DiagnosticsForm from "../../common/sign-up-forms/Diagnostics.jsx";
import StaffForm from "../../common/sign-up-forms/Staff.jsx";

const AdminRegistration = ({ role }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [form] = Form.useForm();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  // const [currentComponent, setCurrentComponent] = useState(FORM_COMP);
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createRedirect, setCreateRedirect] = useState(null);

  const { post, reset: resetPost } = usePost("/adminApi/create", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });

  const handleSubmit = async (values) => {
    values.role = role;
    console.log(role, values);
    await form.validateFields();

    await post(values);

    if (createRedirect) {
      navigate(createRedirect);
    }
  };

  const disableSubmitButton = () => {
    setSubmitAllowed(false);
  };
  const enableSubmitButton = () => {
    setSubmitAllowed(true);
  };

  useEffect(() => {
    if (createError) {
      showToast(createError.message?.replace("AxiosError:", ""));
      enableSubmitButton();
    }
    if (createRes) showToast(createRes.status);
    if (createLoading) disableSubmitButton();

    if (createError || createRes) resetPost();
  }, [createLoading, createError, createRes]);

  const formProps = {
    form,
    handleSubmit,
    submitAllowed,
    setSubmitAllowed,
    createLoading,
    PhoneNumberInput,
  };

  const renderForm = () => {
    switch (role) {
      case "patient":
        return <PatientForm {...formProps} />;
      case "doctor":
        return <DoctorForm {...formProps} />;
      case "hospital":
        return <HospitalForm {...formProps} />;
      case "pharmacy":
        return <PharmacyForm {...formProps} />;
      case "diagnostic_center":
        return <DiagnosticsForm {...formProps} />;
      case "staff":
        return <StaffForm {...formProps} />;
      default:
        return null;
    }
  };
  return <>{renderForm(role)}</>;
};

export default AdminRegistration;
