import { React, useState, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch.js";
import { useParams } from "react-router-dom";

// import {
//   hospitalData,
//   diagnosticMaterialData,
// } from "../../../../../services/data";
import "./EquipmentPost.css";

function EquipmentPost() {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const [hospitalData, setHospitalData] = useState();
  const [hospitalDataErr, setHospitalDataErr] = useState(false);
  const {
    data: hospitalDataRes,
    loading: hospitalLoading,
    error: hospitalError,
    getRequest: getHospitalData,
  } = useFetch("/api/hospitalDetails");
  useEffect(() => {
    getHospitalData();
  }, []);
  useEffect(() => {
    console.log("hospital", hospitalDataRes);
    if (hospitalDataRes?.data) {
      setHospitalData(hospitalDataRes.data);
    }
    if (hospitalError) {
      setHospitalDataErr(true);
    }
  }, [hospitalDataRes, hospitalError]);

  const { id } = useParams();
  const selectedMaterial = hospitalData?.find((hos) => hos?.hospitalId == id);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    usage: "",
    sample: "",
    dataProduced: "",
    preparation: "",
    safety: "",
    image: "../../images/D4-PET-Scan.png",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newMaterialData = {
      id: Date.now().toString(), // Generate a unique ID
      name: formData.name,
      usage: formData.usage,
      sample: formData.sample,
      dataProduced: formData.dataProduced,
      preparation: formData.preparation,
      safety: formData.safety,
      principle: formData.principle,
      image: formData.image, // This might need additional processing for the image file
    };
    console.log(newMaterialData);
    const { services } = selectedMaterial;

    services.push(newMaterialData);
    diagnosticMaterialData.push(newMaterialData);
    console.log(diagnosticMaterialData);
    // updateMedicines(newMedicineData);
    // setFormData({
    //   id: "",
    //   name: "",
    //   dosage: "",
    //   pain: "",
    //   image: "",
    //   link: "",
    // });
  };
  return (
    <>
      <div id="equipmentPost">
        <div className="container">
          <div className="equipment-form-container">
            <form className="form" onSubmit={handleSubmit}>
              <h2>POST A NEW EQUIPMENT</h2>

              <div>
                <label htmlFor="name">
                  <strong>Equipment Name</strong>
                </label>{" "}
                <br />
                <input
                  name="name"
                  id="name"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="usage">
                  <strong>Usage</strong>
                </label>{" "}
                <br />
                <input
                  name="usage"
                  id="usage"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="name">
                  <strong>Sample can this equipment analyze</strong>
                </label>{" "}
                <br />
                <input
                  type="text"
                  name="sample"
                  id="sample"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="image">
                  <strong>Image</strong>
                </label>{" "}
                <br />
                {/* <input type="file" name="image" onChange={(e) => {}} /> */}
                <input
                  type="text"
                  value={"../../images/D4-PET-Scan.png"}
                  name="image"
                  onChange={(e) => {}}
                />
              </div>
              <div>
                <label htmlFor="name">
                  <strong>Kind of data does it produced</strong>
                </label>{" "}
                <br />
                <input name="dataProduced" onChange={handleChange}></input>
              </div>
              <div>
                <label htmlFor="name">
                  <strong>Special preparation</strong>
                </label>{" "}
                <br />
                <input
                  type="text"
                  name="preparation"
                  id="preparation"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="name">
                  <strong>Safety Consideration</strong>
                </label>{" "}
                <br />
                <input
                  type="text"
                  name="safety"
                  id="safety"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="name">
                  <strong>Operating Principle</strong>
                </label>{" "}
                <br />
                <input
                  type="text"
                  name="principle"
                  id="principle"
                  onChange={handleChange}
                />
              </div>

              <div className="post-submit">
                <button className="btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EquipmentPost;
