import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Spin } from "antd";
import { locationLists } from "../../../../services/data.js";
import { handleFilterNumber } from "../../../../utils/helper.js";
import { LocationRequest } from "../../../common/location-selector/LocationSelector.jsx";
import useFetch from "../../../../hooks/useFetch.js";
const DiagnosticEditProfileForm = ({ form, handleModalOk }) => {
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);

  const { data, getRequest } = useFetch("/api/users/search");

  useEffect(() => {
    if (data?.data) {
      setSearchData(data?.data);
    }
  }, [data]);

  const handleSearch = async (query) => {
    setSearching(true);
    await getRequest({ role: "diagnostic", query });
    setSearching(false);
  };
  return (
    <Form
      className="sign-up-form"
      form={form}
      layout="vertical"
      onFinish={handleModalOk}
    >
      <Form.Item
        label="Diagnostic Name"
        name="organizationName"
        rules={[
          { required: true, message: "Please fill your Diagnostic Name!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please fill the address!" }]}
      >
        <Select showSearch>
          {locationLists.map((place) => (
            <Select.Option key={place}>{place}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Branch Number"
        name="branchNumber"
        rules={[{ required: true, message: "Please fill your Branch Number!" }]}
        onKeyPress={handleFilterNumber}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "Please fill your Email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Location"
        name="location"
        rules={[{ required: true, message: "Please fill your location" }]}
      >
        <LocationRequest form={form} />
      </Form.Item>
    </Form>
  );
};
export default DiagnosticEditProfileForm;
