import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../../common/sidebar/Sidebar";
import { medicineData } from "../../../../services/data";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import ErrorPage from "../../../common/error-page/ErrorPage";
import Notification from "../../patient/patient-notification/PatientNotification";
import { cartItems } from "../../../../services/data";
import "./PharmacyPortal.css";

function PharmacyPortal() {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMedicine, setSelectedMedicine] = useState(null); // Track selected medicine
  const [medicines, setMedicines] = useState(medicineData);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [medicineData, setMedicineData] = useState();
  const [medicineDataErr, setMedicineDataErr] = useState(false);
  const {
    data: medicineDataRes,
    loading: medicineLoading,
    error: medicineError,
    getRequest,
  } = useFetch("/api/medicine");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicineDataRes?.data) {
      setMedicineData(medicineDataRes.data);
    }
    if (medicineError) {
      setMedicineDataErr(true);
    }
  }, [medicineDataRes, medicineError]);

  const searchBarRef = useRef(null);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (medicine) => {
    setSelectedMedicine(medicine); // Set selected medicine for detailed view
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);

    const filteredData = medicineData.filter((medicine) =>
      medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes

  const updateMedicines = (newMedicine) => {
    setMedicines([...medicines, newMedicine]);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);
  return (
    <>
      <Sidebar />
      <Notification /> {medicineDataErr && <ErrorPage />}
      {medicineData && (
        <div id="pharmacy-portal">
          <section id="search">
            <div className="medicine-nav-container">
              <div className="search-container">
                <div className="search-bar search">
                  <div ref={searchBarRef} className="search-wrapper">
                    <div>
                      <input
                        type="text"
                        placeholder="Search for medicine"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        onFocus={() => setIsDropdownVisible(true)}
                      ></input>
                    </div>
                    <div className="search-list-container">
                      <ul className="lists">
                        {searchResults.length > 0 // Check if results exist before rendering
                          ? searchResults.map((medicine) => (
                              <Link to={`/medicine/${medicine.id}`}>
                                <li
                                  key={medicine.id}
                                  onClick={() => handleMedicineClick(medicine)}
                                  className="med-list name"
                                >
                                  {medicine.name}
                                </li>
                              </Link>
                            ))
                          : searchTerm && <p>No results found.</p>}{" "}
                        {/* {cartItems.length > 0 && <ViewCart />} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="notify">
                <div id="cart-btn">
                  <Link to="/pharmacistCart">View Cart List</Link>
                </div>
                <div className="count-btn">
                  <Link to="/pharmacistCart">{cartItems.length}</Link>
                </div>
              </div>
            </div>
          </section>
          <section className="pharmacy-portal">
            <h1 className="pharmacy-title">Hibret Pharmacy</h1>
            <div className="medicine-grid">
              {medicineData.map((medicine) => {
                return <MedicineCard key={medicine.id} medicine={medicine} />;
              })}
            </div>
          </section>
          <div className="post-medicine-button">
            <Link to="/medicineForm">Post a New Medicine</Link>
          </div>
        </div>
      )}
    </>
  );
}

const MedicineCard = ({ medicine }) => {
  return (
    <div className="medicine-card">
      <img src={medicine.image} alt="medicine.name"></img>
      <h2>Name: {medicine.name}</h2>
      <p>Intake Amount: {medicine.dosage}</p>
      <p>It cures From: {medicine.pain}</p>
    </div>
  );
};

export default PharmacyPortal;
