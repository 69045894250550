import { toast } from "react-toastify";

const useToast = () => {
  const showToast = (message) => {
    toast(message);
  };

  return { showToast };
};

export default useToast;
