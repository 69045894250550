import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals.js";
import { SpinnerProvider } from "./contexts/spinnerContext.js";
import { SessionProvider } from "./contexts/sessionData.js";
import { LocationProvider } from "./contexts/locationProvider.js";
import { AccSidebarProvider } from "./contexts/accSidebarVisibility.js";
import { AccAuthProvider } from "./contexts/userAccountAuth.js";
import { AdminAccAuthProvider } from "./contexts/AdminAccountAuth.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SpinnerProvider>
      <SessionProvider>
        <AdminAccAuthProvider>
          <LocationProvider>
            <AccSidebarProvider>
              <AccAuthProvider>
                <App />
              </AccAuthProvider>
            </AccSidebarProvider>
          </LocationProvider>
        </AdminAccAuthProvider>
      </SessionProvider>
    </SpinnerProvider>
  </React.StrictMode>
);

reportWebVitals();

window.onerror = function (message, source, lineno, colno, error) {
  console.error("Global error caught: ", {
    message,
    source,
    lineno,
    colno,
    error,
  });
};

window.onunhandledrejection = function (event) {
  console.error("Unhandled promise rejection: ", event.reason);
};
