import { React, useState, useEffect } from "react";

import "./Splash.css";

const Splash = () => {
  return (
    <>
      <div id="splash">
        <div className=" container">
          <div className="form-container">
            <div className="splash">
              <div className="card-img">
                <img src="./logo.png" alt="No Image" />
              </div>

              <div className="card-contet">
                <h1>YESMED</h1>
                <small>
                  Empowering healthcare excellence, one patient at a time.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
