import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./HospitalProfile.css";
import ProfileImg from "../../../../image/service-img-5.png";
import { Modal, Form, Card, Button } from "antd";
import usePatch from "../../../../hooks/usePatch";
import useFetch from "../../../../hooks/useFetch";
import HospitalEditProfileForm from "../hospital-edit-profile-form/HospitalEditProfileForm";

const HospitalProfile = () => {
  const [hospitalData, setHospitalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  //crud
  const { patchData } = usePatch(`/api/users/${hospitalData?.id}`);
  const { data, loading, getRequest } = useFetch("/api/users");
  //
  const handleEdit = async () => {
    if (hospitalData) {
      form.setFieldsValue({
        fullName: hospitalData.fullName,
        licenseNumber: hospitalData.licenseNumber,
        experience: hospitalData.experience,
        specialty: hospitalData.specialty,
        email: hospitalData.email,
        location: hospitalData.location,
        workplaces: hospitalData.workplaces, // Make sure this matches your data structure
      });
    }
    setIsModalVisible(true);
  };
  //
  useEffect(() => {
    setHospitalData(data?.users);
  }, [hospitalData, data]);
  useEffect(() => {
    getRequest({ role: "hospital" });
  }, []);
  //
  const handleModalCancel = () => setIsModalVisible(false);
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await patchData(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="hospital-profile">
      <div className="container">
        <div className="form-container">
          {
            <Card
              className="antd-card"
              hoverable
              style={{
                width: 280,
              }}
              cover={<></>}
            >
              {" "}
              <img
                className=" "
                src={ProfileImg}
                alt="Hospital Profile Image"
              />
              <p>
                <strong>Hospital Name:</strong> {hospitalData?.organizationName}
              </p>
              <p>
                <strong>Address :</strong> {hospitalData?.address}
              </p>
              <p>
                <strong>Branch Number :</strong> {hospitalData?.branchNumber}
              </p>
              <p>
                <strong>Email:</strong> {hospitalData?.email}
              </p>
              <Button className="" onClick={() => handleEdit()}>
                Edit Profile
              </Button>
              <Modal
                title="Edit Post"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <HospitalEditProfileForm
                  form={form}
                  handleModalOk={handleModalOk}
                />
              </Modal>
            </Card>
          }
        </div>
      </div>
    </div>
  );
};

export default HospitalProfile;
