import { React, useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import ErrorPage from "../../../common/error-page/ErrorPage";
// import {
//   diagnosticMaterialData,
//   hospitalData,
// } from "../../../../services/data";
import "./DiagnosticMaterialFullInformation.css";

function DiagnosticMaterialFullInformation() {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const [hospitalData, setHospitalData] = useState();
  const [hospitalDataErr, setHospitalDataErr] = useState(false);
  const {
    data: hospitalDataRes,
    loading: hospitalLoading,
    error: hospitalError,
    getHospitalData,
  } = useFetch("/api/hospital");
  useEffect(() => {
    getHospitalData();
  }, []);
  useEffect(() => {
    console.log("hospital", hospitalDataRes);
    if (hospitalDataRes?.data) {
      setHospitalData(hospitalDataRes.data);
    }
    if (hospitalError) {
      setHospitalDataErr(true);
    }
  }, [hospitalDataRes, hospitalError]);

  const { id } = useParams();
  const selectedMaterial = diagnosticMaterialData.find(
    (material) => material.postId == id
  );

  function findHospitalsWithMaterial(material) {
    return hospitalData.filter((hosp) => {
      // Check if any service in the hospital offers the searched material
      // Check if the service's material objects include the searched material
      return hosp.services.some(
        (serviceMaterial) =>
          serviceMaterial.id === material.id ||
          serviceMaterial.name === material.name
      ); // Match by ID or name
    });
  }

  const matchingHospital = findHospitalsWithMaterial(selectedMaterial);
  console.log(matchingHospital);
  const { services } = selectedMaterial;
  return (
    <>
      {hospitalDataErr && <ErrorPage />}
      {hospitalData && (
        <div id="material-full-information">
          <div id="head" className="materialContainer">
            <h2>Hospitals offering {selectedMaterial.title}:</h2>
            <div className="underline"></div>
            {matchingHospital.length > 0 ? (
              <ul>
                {matchingHospital.map((hospital) => {
                  return (
                    <li key={hospital?.hospitalId}>
                      <div className="content-wrapper">
                        <div className="right-container">
                          <img src={hospital?.image}></img>
                        </div>
                        <div className="left-container">
                          <p>
                            <strong>Hospital Name: </strong>
                            {hospital.name}
                          </p>
                          <p>
                            <strong>Hospital Phone Number: </strong>
                            {hospital.phone}
                          </p>
                          <p>
                            <strong>Hospital Location: </strong>
                            {hospital.location}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p>No hospitals found offering {selectedMaterial.title}.</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DiagnosticMaterialFullInformation;
