import { React, useState, useEffect, useRef } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
// import {
//   hospitalData,
//   diagnosticMaterialData,
// } from "../../../../services/data";

import Footer from "../../../common/footer/Footer.jsx";
import ErrorPage from "../../../common/error-page/ErrorPage.jsx";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import "./DiagnosticsUserPortal.css";
function DiagnosticsUserPortal() {
  return (
    <>
      <div id="diagnosticsUserPortal">
        <Sidebar />
        <SearchEquipment />
        <ImgSlider />
        <Footer />
      </div>
    </>
  );
}

const SearchEquipment = () => {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null); // Track selected medicine
  const [material, setMaterial] = useState(diagnosticMaterialData);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const searchBarRef = useRef(null);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMedicineClick = (material) => {
    setSelectedMaterial(material); // Set selected medicine for detailed view
  };

  useEffect(() => {
    if (!searchTerm.toLowerCase().trim()) return setSearchResults([]);
    const filteredData = diagnosticMaterialData.filter((material) =>
      material.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(filteredData);
  }, [searchTerm]); // Update searchResults only when searchTerm changes

  const updateMedicines = (newMaterial) => {
    setMaterial([...material, newMaterial]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);

  return (
    <>
      <section id="diagnosticsUserPortal">
        <div className="equipment-nav-container">
          <div className="search-container">
            <div className="search-bar search">
              <div ref={searchBarRef} className="search-wrapper">
                <div>
                  <input
                    type="text"
                    placeholder="Search for Equipment"
                    value={searchTerm}
                    onFocus={() => setIsDropdownVisible(true)}
                    onChange={handleSearchTermChange}
                  ></input>
                </div>

                <div className="search-list-container">
                  <ul className="lists">
                    {searchResults.length > 0 // Check if results exist before rendering
                      ? searchResults.map((equipment, i) => (
                          <Link
                            key={i}
                            to={`/app/equipment/${equipment.postId}`}
                          >
                            {console.log("postId", equipment.postId)}
                            <li
                              className="mat-list"
                              key={equipment.postId}
                              onClick={() => handleMedicineClick(equipment)}
                            >
                              {equipment.title}
                            </li>
                          </Link>
                        ))
                      : searchTerm && <p>No results found.</p>}{" "}
                    {/* {cartItems.length > 0 && <ViewCart />} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ImgSlider = () => {
  const [slideImages, setSlideImages] = useState([
    { image: "../../../images/diagnostic-1.jpg" },
    { image: "../../../images/diagnostic-2.jpg" },
    { image: "../../../images/diagnostic-3.jpg" },
    { image: "../../../images/diagnostic-4.jpg" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % slideImages.length;
    setCurrentIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex =
      (currentIndex - 1 + slideImages.length) % slideImages.length; // Handle negative index
    setCurrentIndex(prevIndex);
  };

  return (
    <section className="imageSection">
      <div className="sectionCenter">
        <div className="imagePart1">
          {slideImages.map((image, imageIndex) => (
            <div
              key={imageIndex}
              style={{
                display: imageIndex === currentIndex ? "block" : "none",
              }}
              className="imagePart"
            >
              <img src={image.image} alt={`Slide ${imageIndex + 1}`} />
            </div>
          ))}
        </div>
        <button className="prev" onClick={handlePrev}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={handleNext}>
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
};

export default DiagnosticsUserPortal;
