import React, { useState } from "react";
import "./PrescriptionForm.css";
const PrescriptionForm = () => {
  const [prescription, setPrescription] = useState({
    medication: "",
    dosage: "",
    instructions: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrescription((prevPrescription) => ({
      ...prevPrescription,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Save prescription to local storage
    const existingPrescriptions =
      JSON.parse(localStorage.getItem("prescriptions")) || [];
    const updatedPrescriptions = [...existingPrescriptions, prescription];
    localStorage.setItem("prescriptions", JSON.stringify(updatedPrescriptions));

    // Clear form fields
    setPrescription({
      medication: "",
      dosage: "",
      instructions: "",
    });
  };

  return (
    <>
      <div id="prescription-form">
        <div className="container">
          <div className="form-container">
            <form className="registration-form">
              {" "}
              <h2>Doctor Prescription Form</h2>
              <div className="form-group">
                <label>Medication: </label>
                <input
                  type="text"
                  name="medication"
                  value={prescription.medication}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Dosage:</label>
                <input
                  type="text"
                  name="dosage"
                  value={prescription.dosage}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Instructions:</label>

                <input
                  type="text"
                  name="instructions"
                  value={prescription.instructions}
                  onChange={handleInputChange}
                />
              </div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit}
              >
                Submit Prescription
              </button>
            </form>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default PrescriptionForm;
