import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { medicalRecords } from "../../../../services/data.js";
import Footer from "../../../common/footer/Footer.jsx";
import Sidebar from "../../../common/sidebar/Sidebar.jsx";
import { SearchRecord } from "../hospital-record-list/HospitalRecordList.jsx";
import useFetch from "../../../../hooks/useFetch.js";
import ErrorPage from "../../../common/error-page/ErrorPage.jsx";
import "./HospitalRecordDetail.css";

const RecordDetail = () => {
  const [medicalRecordsData, setMedicalRecordsData] = useState();
  const [medicalRecordsDataErr, setMedicalRecordsDataErr] = useState(false);
  const {
    data: medicalRecordsDataRes,
    loading: medicalRecordsLoading,
    error: medicalRecordsError,
    getRequest,
  } = useFetch("/api/medicalRecords");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (medicalRecordsDataRes?.data) {
      setMedicalRecordsData(medicalRecordsDataRes.data);
    }
    if (medicalRecordsError) {
      setMedicalRecordsDataErr(true);
    }
  }, [medicalRecordsDataRes, medicalRecordsError]);

  const { id } = useParams();
  const selectedRecord = medicalRecordsData.find((record) => record.id == id);
  return (
    <>
      {medicalRecordsDataErr && <ErrorPage />}
      {medicalRecordsData && (
        <div id="hospital-record-detail">
          <Sidebar />
          <SearchRecord />
          <div className="container">
            <div className="form-container">
              <div
                key={selectedRecord?.id}
                className="border box-shadow py-3 px-3 registration-form"
              >
                <h3>
                  {selectedRecord?.date} - {selectedRecord?.type}
                </h3>

                <p>Patient Name: {selectedRecord?.patientName}</p>
                <p>Phone: {selectedRecord?.phoneNumber}</p>
                <p>Description {selectedRecord?.description}</p>

                <h4>Results:</h4>
                {selectedRecord?.results.map(({ name, value }) => (
                  <li key={name}>
                    {name}: {value}
                  </li>
                ))}
                <p>Explanation: {selectedRecord?.explanation}</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};
export default RecordDetail;
