import React, { createContext, useState, useContext } from "react";

const AccAuthContext = createContext();

export const AccAuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({});

  return (
    <AccAuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AccAuthContext.Provider>
  );
};

export const useAccAuth = () => useContext(AccAuthContext);
