import React, { useState, useEffect } from "react";

import "./ForgotPassword.css";
const DoctorForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitted(true);
    setErrorMessage(null);
  };

  return (
    <div id="doctorForgetPassword">
      <div className="form-container">
        <form action="#" method="" onSubmit={handleSubmit} className="form">
          <div id="form-group">
            <h2>Forgot Password</h2>
            <label className="label" htmlFor="email">
              Email Address:
            </label>
            <input
              type="email"
              className="doctor-forget-password-email"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter your registered email address"
              required
            />
            <button type="submit" className="btn btn-primary">
              Request Reset Link
            </button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoctorForgetPassword;
