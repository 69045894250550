import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PharmacyProfile.css";
import ProfileImg from "../../../../image/service-img-5.png";
import { Modal, Form, Card, Button } from "antd";
import usePatch from "../../../../hooks/usePatch";
import useFetch from "../../../../hooks/useFetch";
import PharmacyEditProfileForm from "../pharmacy-edit-profile-form/PharmacyEditProfileForm";

const { Meta } = Card;
const PharmacyProfile = () => {
  const [pharmacyData, setPharmacyData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  //crud
  const { patchData } = usePatch(`/api/users/${pharmacyData?.id}`);
  const { data, loading, getRequest } = useFetch("/api/users");
  //
  const handleEdit = async () => {
    if (pharmacyData) {
      form.setFieldsValue({
        fullName: pharmacyData.fullName,
        licenseNumber: pharmacyData.licenseNumber,
        experience: pharmacyData.experience,
        specialty: pharmacyData.specialty,
        email: pharmacyData.email,
        location: pharmacyData.location,
        workplaces: pharmacyData.workplaces, // Make sure this matches your data structure
      });
    }
    setIsModalVisible(true);
  };
  //
  useEffect(() => {
    setPharmacyData(data?.users);
  }, [pharmacyData, data]);
  useEffect(() => {
    getRequest({ role: "pharmacy" });
  }, []);
  //
  const handleModalCancel = () => setIsModalVisible(false);
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      await patchData(values);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="pharmacy-profile">
      <div className="container">
        <div className="form-container">
          {
            <Card
              className="antd-card"
              hoverable
              style={{
                width: 280,
              }}
              cover={<></>}
            >
              <img className="" src={ProfileImg} alt="Pharmacy Profile Image" />

              <p>
                <strong>Pharmacy Name:</strong> {pharmacyData?.organizationName}
              </p>
              <p>
                <strong>Address :</strong> {pharmacyData?.address}
              </p>
              <p>
                <strong>Branch Number :</strong> {pharmacyData?.branchNumber}
              </p>
              <p>
                <strong>Email:</strong> {pharmacyData?.email}
              </p>

              <Button className=" " onClick={() => handleEdit()}>
                Edit Profile
              </Button>

              <Modal
                title="Edit Post"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <PharmacyEditProfileForm
                  form={form}
                  handleModalOk={handleModalOk}
                />
              </Modal>
            </Card>
          }
        </div>
      </div>
    </div>
  );
};

export default PharmacyProfile;
