import React, { useState } from "react";
import "./AdminSignUpCategory.css";

import { Radio } from "antd";
import AdminRegistration from "../registration-forms/AdminRegFormContainer.jsx";

const AdminRoleSelection = ({ handleShowLogin }) => {
  const [selectedRole, setSelectedRole] = useState("patient");

  return (
    <>
      <div id="AdminSignUpCategory">
        <div className="form-container">
          <h2>Register</h2>
          <div className="radio-group-container">
            <Radio.Group
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className={"radiogroup"}
            >
              <Radio.Button value="patient" className={"radiobutton"}>
                Patient
              </Radio.Button>
              <Radio.Button value="doctor" className={"radiobutton"}>
                Doctor
              </Radio.Button>
              <Radio.Button value="hospital" className={"radiobutton"}>
                Hospital
              </Radio.Button>
            </Radio.Group>

            <Radio.Group
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className={"radiogroup"}
            >
              <Radio.Button value="pharmacy" className={"radiobutton"}>
                Pharmacy
              </Radio.Button>
              <Radio.Button value="diagnostic_center" className={"radiobutton"}>
                Diagnostics
              </Radio.Button>
              <Radio.Button value="staff" className={"radiobutton"}>
                Staff
              </Radio.Button>
            </Radio.Group>
          </div>
          <AdminRegistration
            role={selectedRole}
            handleShowLogin={handleShowLogin}
          />
        </div>
      </div>
    </>
  );
};

export default AdminRoleSelection;
