// services/api.js

import axios from "axios";

const baseUrl = window.location.origin;

const config = (config) => {
  const token1 = localStorage.getItem("userAccessToken") || "sampletoken2";
  const token2 = localStorage.getItem("firstTimeUseToken") || "sampletoken1";
  const token3 = localStorage.getItem("adminAccessToken") || "sampletoken3";
  if (token1) {
    config.headers.Authorization = `Bearer ${token1}`;
  }
  if (token2) {
    config.headers["firstTimeVisitAuthToken"] = token2;
  }
  if (token3) {
    config.headers["adminToken"] = token3;
  }
  return config;
};
const configError = (error) => {
  return Promise.reject(error);
};

const api = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});
const uploadsApi = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

api.interceptors.request.use(config, configError);
uploadsApi.interceptors.request.use(config, configError);

export const getApi = async (endpoint, params = {}) => {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await api.get(`${endpoint}?${query}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postApi = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    if (error.name === "TypeError" || error.name === "SyntaxError")
      throw new Error("System Error");
    throw new Error(error?.response.data.msg || error);
  }
};
export const uploadApi = async (endpoint, data) => {
  try {
    const response = await uploadsApi.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    if (error.name === "TypeError" || error.name === "SyntaxError")
      throw new Error("System Error");
    throw new Error(error?.response.data.msg || error);
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};
export const patchApi = async (endpoint, value) => {
  try {
    const response = await axios.patch(endpoint, value);
    return response.data;
  } catch (error) {
    throw error;
  }
};
