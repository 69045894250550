import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Spin } from "antd";
import { drSpecialties } from "../../../services/data.js";
import { handleFilterNumber } from "../../../utils/helper.js";
import { LocationRequest } from "../location-selector/LocationSelector.jsx";
import useFetch from "../../../hooks/useFetch.js";

const StaffSignUp = ({
  form,
  handleSubmit,
  submitAllowed = true,
  setSubmitAllowed,
  createLoading,
  PhoneNumberInput,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);

  const { data, getRequest } = useFetch("/api/users/search-hosp");

  useEffect(() => {
    if (data?.data) {
      setSearchData(data?.data);
    }
  }, [data]);

  const handleSearch = async (query) => {
    setSearching(true);
    await getRequest({ role: "hospital", query });
    setSearching(false);
  };

  return (
    <Form
      className="sign-up-form"
      form={form}
      layout="vertical"
      onChange={() => setSubmitAllowed(true)}
      onFinish={handleSubmit}
    >
      {PhoneNumberInput && <PhoneNumberInput form={form} />}
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[{ required: true, message: "Please fill your Full Name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Experience (year)"
        name="experience"
        rules={[{ required: true, message: "Please fill your Experience!" }]}
        onKeyPress={handleFilterNumber}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "Please fill your Email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please fill your Password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[{ required: true, message: "Please confirm your Password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={createLoading}>
          {"Register"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StaffSignUp;
