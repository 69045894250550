import React from "react";
import "./AccSidebar.css";
import { CiUser, CiSignpostDuo1 } from "react-icons/ci";
import { GiArchiveRegister } from "react-icons/gi";
import { PiSignOutThin } from "react-icons/pi";
import { MdClose } from "react-icons/md";
import { FaSignInAlt } from "react-icons/fa";
import useFetch from "../../../hooks/useFetch";
import { useState } from "react";
import { useAccSidebarToggle } from "../../../contexts/accSidebarVisibility";
import LoginForm from "../login-form/Login";
import RoleSelection from "../sign-up-category/SignUpCategory";
import ModalComponent from "../common-modal/CommonModal";
import AccSidebarComp from "./account-sidebar-comps/AccSidebarComp";
import { useAccAuth } from "../../../contexts/userAccountAuth";
import { LoginOutlined } from "@ant-design/icons";

const AccSidebar = () => {
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [componentProps, setComponentProps] = useState({});
  const { hideAccSidebar } = useAccSidebarToggle();
  const { getRequest } = useFetch("/logout");
  const [isPortalAuthenticated, setIsPortalAuthenticated] = useState(false);
  const { authUser, setAuthUser } = useAccAuth();

  const logout = async function () {
    localStorage.setItem("phone", "");
    localStorage.setItem("firstTimeUseToken", null);
    localStorage.setItem("userAccessToken", null);
    localStorage.setItem("adminAccessToken", null);
    localStorage.setItem("userRole", "");

    getRequest();
    window.location.href = window.location.origin;
  };

  const showModal = (title, component, props) => {
    setModalTitle(title);
    setCurrentComponent(() => component);
    setComponentProps(props);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setCurrentComponent(null);
    setComponentProps({});
  };

  const handleShowLogin = () => {
    handleClose();
    showModal("Portal Login", LoginForm, {
      message: "Login",
      setAuthUser,
      handleClose,
    });
  };

  return (
    <>
      {currentComponent && (
        <ModalComponent
          open={isModalOpen}
          onClose={handleClose}
          Component={currentComponent}
          componentProps={componentProps}
          className="acc-modal"
        />
      )}

      <div className="acc-sidebar">
        <div className="acc-sidebar-header">
          <div className="header-content">
            <img
              src="../images/acc-img/sample.png"
              alt=""
              className="acc-sidebar-logo"
            />
            <span className="acc-sidebar-title">
              {localStorage.getItem("phone")}
            </span>
          </div>
          <div className="account-close-btn" onClick={hideAccSidebar}>
            <MdClose />
          </div>
        </div>
        <ul className="acc-sidebar-menu">
          {!isPortalAuthenticated && (
            <li onClick={handleShowLogin}>
              <LoginOutlined /> <span>Login</span>
            </li>
          )}
          {authUser?.authenticated && authUser?.role && (
            <AccSidebarComp role={authUser?.role} />
          )}
        </ul>
        <div className="acc-sidebar-footer">
          <ul>
            <li onClick={logout}>
              <PiSignOutThin /> <span>Sign out</span>
            </li>
            <li
              onClick={() =>
                showModal("Register", RoleSelection, {
                  handleShowLogin,
                  message: "Register",
                })
              }
            >
              <GiArchiveRegister />
              <span>Register</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccSidebar;
