import React, { useState } from "react";
import "../../../App.css";
import "./Footer.css";
import { footerData } from "../../../services/data";

function Footer() {
  const [activeSections, setActiveSections] = useState({});

  const handleSectionClick = (index) => {
    setActiveSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <footer id="footer" className="py-3">
      <div className="container">
        <ul className="container-grid-7-1">
          {Object.keys(footerData).map((key, index) => {
            const section = footerData[key];
            return (
              <li key={index}>
                <div
                  data-set={index + 1}
                  className={`drop-li-${index + 1} plus`}
                  onClick={() => handleSectionClick(index + 1)}
                >
                  <h4 data-set={index + 1} className={`drop-li-${index + 1}`}>
                    {section.title}
                  </h4>
                  <span data-set={index + 1} className={`drop-li-${index + 1}`}>
                    +
                  </span>
                </div>
                <ul
                  className={`drop-ul drop-ul-${index + 1} ${
                    activeSections[index + 1] ? "active" : ""
                  }`}
                >
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>

        <div className="footer-content">
          <div className="footer-logo py-1">
            <img src="../images/medlogo.png" className="logo" alt="Logo" />
          </div>
          <p>Copyright &copy; 2023, YESMADE All right reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
