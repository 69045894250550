import { useState, useCallback } from "react";
import { patchApi } from "../services/api"; // Ensure you have this function defined in your api services

const usePatch = (endpoint, initialValue = {}) => {
  const [dataUpdated, setDataUpdated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const patchData = useCallback(
    async (value = initialValue) => {
      try {
        setLoading(true);
        setError(null);
        const result = await patchApi(endpoint, value);
        console.log(endpoint, value, result);
        if (result?.status === "success") setDataUpdated(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [endpoint, initialValue]
  );

  return { dataUpdated, loading, error, patchData };
};

export default usePatch;
