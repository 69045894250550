import React from "react";
import { Upload, Button, Form, Input, Select, message } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import { pharmacyMedicineFormFields } from "../../../../services/data";
const { Option } = Select;

const { TextArea } = Input;
const NewMedicineForm = ({
  form,
  handleModalOk,
  onChange,
  onPreview,
  fileList,
}) => {
  return (
    <>
      <Form
        className=" "
        onFinish={handleModalOk}
        form={form}
        layout="vertical"
      >
        {pharmacyMedicineFormFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={[
              {
                required: true,
                message: `Please fill the ${field.label.toLowerCase()}!`,
              },
            ]}
          >
            <Input placeholder={field.placeholder} />
          </Form.Item>
        ))}
        <Form.Item label="Other Detail Description" name="detail">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Post image"
          name="image"
          rules={[
            {
              validator: (_, value) => {
                if (fileList.length === 0) {
                  return Promise.reject(new Error("Please upload an image!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false} // Prevent auto-upload
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}> Upload</Button>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewMedicineForm;
