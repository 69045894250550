import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import ErrorPage from "../../common/error-page/ErrorPage";
import { CgProfile } from "react-icons/cg";
import "./Slider.css";
function Slider() {
  // const [people, setPeople] = useState(testimonialData);
  const [index, setIndex] = React.useState(0); ////////////
  //

  const [testimonialData, setTestimonialData] = useState();
  const [testimonialDataErr, setTestimonialDataErr] = useState(false);
  const {
    data: testimonialDataRes,
    loading: testimonialLoading,
    error: testimonialError,
    getRequest,
  } = useFetch("/api/testimonials");
  useEffect(() => {
    // getRequest();
  }, []);

  useEffect(() => {
    if (testimonialDataRes?.data) {
      setTestimonialData(testimonialDataRes.data);
    }
    if (testimonialError) {
      setTestimonialDataErr(true);
    }
  }, [testimonialDataRes, testimonialError]);

  let newIndex;
  useEffect(() => {
    const lastIndex = testimonialData?.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, testimonialData]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <></>
    // <div id="slider">
    //   <section className="slider-section border-bottom">
    //     <div className="title">
    //       <h2>What our users said</h2>
    //     </div>
    //     <div className="section-center">
    //       {testimonialDataErr && <ErrorPage />}
    //       {testimonialData &&
    //         testimonialData.map((person, personIndex) => {
    //           const { postId, img, detail, title, content } = person;

    //           newIndex = personIndex;
    //           let position = "nextSlide";

    //           if (personIndex === index) {
    //             position = "activeSlide";
    //           }
    //           if (
    //             personIndex === index - 1 ||
    //             (index === 0 && personIndex === testimonialData.length - 1)
    //           ) {
    //             position = "lastSlide";
    //           }

    //           return (
    //             <article className={position} key={postId}>
    //               <p className="text">{content}</p>
    //               <div className="name-div">
    //                 <CgProfile className="icon-profile" />
    //                 <h4 className="text-primary">{detail.name}</h4>
    //               </div>
    //             </article>
    //           );
    //         })}
    //       <button className="prev" onClick={() => setIndex(index - 1)}>
    //         <FiChevronLeft />
    //       </button>
    //       <button className="next" onClick={() => setIndex(index + 1)}>
    //         <FiChevronRight />
    //       </button>
    //       <div className="dots-content">
    //         {testimonialData?.map((_, i) => {
    //           return (
    //             <button
    //               key={i}
    //               className={`content-dots__dot ${
    //                 i === index ? "dots__dot--active-content" : ""
    //               }`}
    //               data-slide={i}
    //               onClick={() => setIndex(i)}
    //             ></button>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
}

export default Slider;
