import React, { useState, useEffect } from "react";
import { Form, Input, Select, Spin, Tag } from "antd";
import MultipleDatePicker from "../../../common/multiple-date-selector/MultipleDateSelector";
import useFetch from "../../../../hooks/useFetch";

const HospitalAddDoctorsForm = ({ form, handleModalOk }) => {
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);

  const { data, getRequest } = useFetch("/api/users/search");

  useEffect(() => {
    if (data?.data) {
      setSearchData(data?.data);
    }
  }, [data]);
  const handleSearch = async (query) => {
    setSearching(true);
    await getRequest({ role: "doctor", query });
    setSearching(false);
  };
  const handleChange = async (val) => {
    const { userId, fullName } = searchData.find((user) => user.userId === val);

    form.setFieldsValue({ userId, fullName });
  };

  return (
    <>
      <Form
        className=" "
        onFinish={handleModalOk}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Doctor"
          name="userId"
          rules={[{ required: true, message: "Please add your Workplaces!" }]}
        >
          <Select
            showSearch
            onChange={handleChange}
            placeholder="Search and select"
            tokenSeparators={[","]}
            notFoundContent={searching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={handleSearch}
            style={{ width: "100%" }}
          >
            {searchData.map((item) => (
              <Select.Option key={item.id} value={item.userId}>
                {item.fullName} {item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <MultipleDatePicker form={form} />
      </Form>
    </>
  );
};

export default HospitalAddDoctorsForm;
