import React, { useState } from "react";
import Footer from "../../../common/footer/Footer";
import Sidebar from "../../../common/sidebar/Sidebar";
import "./Appointment.css";
const Appointments = () => {
  const [appointments, setAppointments] = useState([
    { id: 1, date: "2024-05-15", time: "09:00 AM", patient: "John Doe" },
    { id: 2, date: "2024-05-18", time: "10:30 AM", patient: "Jane Smith" },
    { id: 3, date: "2024-05-20", time: "02:00 PM", patient: "Michael Brown" },
  ]);

  const cancelAppointment = (appointmentId) => {
    setAppointments((prevAppointments) =>
      prevAppointments.filter((appointment) => appointment.id !== appointmentId)
    );
  };

  const rescheduleAppointment = (appointmentId, newDateTime) => {
    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.id === appointmentId
          ? { ...appointment, date: newDateTime }
          : appointment
      )
    );
  };

  return (
    <>
      <div id="appointment">
        <Sidebar />
        <div className="container">
          <div className="form-container">
            <h2>Upcoming Appointments</h2>
            <ul>
              {appointments.map((appointment) => (
                <li key={appointment.id}>
                  <AppointmentItem
                    appointment={appointment}
                    onCancel={cancelAppointment}
                    onReschedule={rescheduleAppointment}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const AppointmentItem = ({ appointment, onCancel, onReschedule }) => {
  const handleCancel = () => {
    onCancel(appointment.id);
  };

  const handleReschedule = (newDateTime) => {
    onReschedule(appointment.id, newDateTime);
  };

  return (
    <div className="appointment-item">
      <p className="appointment-date">Date: {appointment.date}</p>
      <p className="appointment-time">Time: {appointment.time}</p>
      <p className="appointment-patient">Patient: {appointment.patient}</p>
      <button className="appointment-cancel-btn" onClick={handleCancel}>
        Cancel
      </button>
      <button
        className="appointment-reschedule-btn"
        onClick={() => handleReschedule("newDateTime")}
      >
        Reschedule
      </button>
    </div>
  );
};

export default Appointments;
