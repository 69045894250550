import React, { useState, useEffect } from "react";
import usePost from "../../../hooks/usePost";

import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";
import "./Login.css";
import { selectLoginData } from "../../../services/data";
import { Button, Form, Input, Select } from "antd";
import useToast from "../toast/toast";
import { useAccAuth } from "../../../contexts/userAccountAuth";
const LoginForm = ({ closeModal }) => {
  const { showToast } = useToast();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();
  const { setAuthUser } = useAccAuth();
  const { post, reset } = usePost("/login", {
    setData,
    setLoading,
    setError,
    setRedirect,
  });

  const handleSubmit = async (values) => {
    await form.validateFields();
    await post(values);
  };

  useEffect(() => {
    if (data) showToast(data.status);

    if (data?.status === "success" && data?.userAccessToken && data?.role) {
      localStorage.setItem("userAccessToken", data?.userAccessToken);
      setAuthUser({ authenticated: true, role: data.role });
      closeModal();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showToast(error.message?.replace("AxiosError:", ""));
    }
  }, [error]);

  return (
    <>
      <Form
        className="login-form"
        form={form}
        layout="vertical"
        variant="filled"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Role"
          rules={[
            {
              required: true,
              message: "Please select your role!",
            },
          ]}
          name="role"
          placeholder="role"
          required
        >
          <Select className="login-select">
            {selectLoginData.map((item) => (
              <Select.Option key={item.key} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please fill your Password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Link to="/doctor_forgot_password">Forgot Password ?</Link>
        {/* {error && <p className="error-message">{error}</p>}{" "} */}

        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
