import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Form,
  Select,
  Pagination,
  Card,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../../../admin/tables/DynamicTable";
import usePost from "../../../../hooks/usePost";
import usePatch from "../../../../hooks/usePatch";
import useToast from "../../../common/toast/toast";
import useFetch from "../../../../hooks/useFetch";
import useUpload from "../../../../hooks/useUpload";
import HospitalAddDoctorsForm from "../hospital-add-doctors-form/HospitalAddDoctorsForm";
const { Option } = Select;

const HospitalAddDoctorsTable = () => {
  const { showToast } = useToast();
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedField, setSelectedField] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();
  //// form modal state
  const [fileList, setFileList] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [addPost, setAddPost] = useState(false);
  //
  const [updateRes, setUpdateRes] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  //
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  //// form modal upload
  const { post, reset: resetUpload } = usePost("/api/doctor", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });

  //// post table getData
  const { data, loading, getRequest } = useFetch("/api/doctor");

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);
  const handleFieldChange = (value) => setSelectedField(value);

  const handleEdit = (record) => {
    setSelectedField(record.field);
    form.setFieldsValue(record);
    setEditPost(true);
    setAddPost(true);
    setIsModalVisible(true);
  };
  const handleAdd = async () => {
    setAddPost(true);
    setIsModalVisible(true);
  };

  const handleDelete = (record) => {
    // Perform  delete operation here
  };

  // form modal handlers
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleModalOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldValue();

      await post(values);

      setIsModalVisible(false);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  useEffect(() => {
    getRequest();
  }, [createRes]);

  useEffect(() => {
    if (createError) {
      showToast(createError.message?.replace("AxiosError:", ""));
    }
    if (createRes) showToast(createRes.status);

    if (createError || updateRes) resetUpload();
  }, [createLoading, createError, updateRes]);

  const handleModalCancel = () => setIsModalVisible(false);

  const filteredData = useMemo(() => {
    if (!data || !data?.doctors) return [];
    return data?.doctors?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [data, searchText, selectedColumn, selectedField]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData?.slice(startIndex, endIndex);
  }, [filteredData, currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !["updatedAt", "createdAt"].includes(key)
    );
    function editCols(col) {
      col = col.split("A");

      return col[0].charAt(0).toUpperCase() + col[0].slice(1);
    }
    const dynamicColumns = keys.map((key) => ({
      title:
        key === "createdAt" || key === "updatedAt"
          ? editCols(key)
          : key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      // title: "Actions",
      // key: "actions",
      render: (text, record) => (
        <span>
          {/* <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button> */}
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {/* <Button type="link" danger>
              Delete
            </Button> */}
          </Popconfirm>
        </span>
      ),
    });

    return [...commonColumns, ...dynamicColumns];
  };

  const customColumns = useMemo(() => generateColumns(data?.doctors), [data]);

  return (
    <div className="container-table">
      <Card style={{ marginBottom: 300 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
            >
              {customColumns.map(
                (col) =>
                  col.key !== "actions" && (
                    <Option key={col.key} value={col.key}>
                      {col.title}
                    </Option>
                  )
              )}
            </Select>
          </Col>

          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() => handleAdd()}
                icon={<PlusOutlined />}
              >
                Add Doctors
              </Button>
            </div>
          </Col>
        </Row>
        <DynamicTable
          data={paginatedData}
          tableActions={[]}
          loading={loading}
          customColumns={customColumns}
          scroll={{ x: 400 }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredData?.length,
            onChange: handlePageChange,
          }}
        />

        <Modal
          title="Edit Doctors"
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          <HospitalAddDoctorsForm
            form={form}
            onChange={onChange}
            fileList={fileList}
            handleModalOk={handleModalOk}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default HospitalAddDoctorsTable;
