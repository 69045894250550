import { React, useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch.js";
import { Link } from "react-router-dom";
// import { patientCategoriesData } from "../../services/data.js";
import "./Service.css";
import Footer from "../../components/common/footer/Footer.jsx";
import Sidebar from "../../components/common/sidebar/Sidebar.jsx";
import ErrorPage from "../../components/common/error-page/ErrorPage.jsx";
function Service() {
  const [patientCategoriesData, setPatientCategoriesData] = useState();
  const [patientCategoriesDataErr, setPatientCategoriesDataErr] =
    useState(false);
  const {
    data: patientCategoriesDataRes,
    loading: patientCategoriesLoading,
    error: patientCategoriesError,
    getRequest,
  } = useFetch("/api/patientCategories");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    console.log("data", patientCategoriesDataRes);
    if (patientCategoriesDataRes?.data) {
      setPatientCategoriesData(patientCategoriesDataRes.data);
    }
    if (patientCategoriesError) {
      setPatientCategoriesDataErr(true);
    }
  }, [patientCategoriesDataRes, patientCategoriesError]);

  return (
    <>
      <Sidebar />
      <main id="Service-main" className="container">
        <section id="home-consult" className="py-3 border-bottom">
          <div className="container">
            <div className="section-header">
              <h3> Consult top doctors for any health concern</h3>
              <p>
                Private consultations with verified doctors in all specialists
              </p>
            </div>
            <div className="card-container">
              {patientCategoriesDataErr && <ErrorPage />}
              {patientCategoriesData &&
                patientCategoriesData.map((data, i) => {
                  console.log("loop", data);
                  const { image, title, content, link } = data;

                  return (
                    <article key={i} className="card border">
                      <div className="circle-img bg-icon bg-icon">
                        <img src={image} alt="" />
                      </div>
                      <div className="card-content">
                        {/* <p className="heading">{title}</p> */}

                        <Link className="text-primary" to={`/patient/${link}`}>
                          {content}
                        </Link>
                      </div>
                    </article>
                  );
                })}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Service;
