import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerDotted } from "spinners-react";
import { useSpinner } from "./contexts/spinnerContext.js";
import { useAccSidebarToggle } from "./contexts/accSidebarVisibility.js";
import { useSession } from "./contexts/sessionData.js";
import { useAccAuth } from "./contexts/userAccountAuth.js";
import useFetch from "./hooks/useFetch.js";

// Components
import AccSidebar from "./components/common/account-sidebar/AccSidebar.jsx";
import Splash from "./components/common/splash-screen/Splash.jsx";
import SignUpCategory from "./components/common/sign-up-category/SignUpCategory.jsx";
import PhoneAuth from "./components/common/phone-auth/PhoneAuth.jsx";
import VerificationPage from "./components/common/verification/PhoneVerification.jsx";
import ForgotPassword from "./components/common/forgot-password/ForgotPassword.jsx";
import Posts from "./pages/posts/Posts.jsx";
import Profile from "./pages/profile/Profile.jsx";
import Pharmacy from "./pages/pharmacy/Pharmacy.jsx";
import DiagnosticPage from "./pages/diagnostic/DiagnosticPage.jsx";
import Home from "./pages/home/Home.jsx";
import Service from "./pages/service/Service.jsx";
import AdminPage from "./pages/admin/Admin.jsx";

// Doctor Components
import DoctorList from "./components/portals/doctor/doctor-list/DoctorList.jsx";
import DoctorInfo from "./components/portals/doctor/doctor-info/DoctorInfo.jsx";
import DoctorProfile from "./components/portals/doctor/doctor-profile/DoctorProfile.jsx";
import DoctorPost from "./components/portals/doctor/doctor-post-table/DoctorPostTable.jsx";

// Patient Components
import PatientGeneralForm from "./components/services/general-clinical-guidance/patient-general-form/PatientGeneralForm.jsx";
import PatientGeneralFormHistory from "./components/services/general-clinical-guidance/patient-general-form-history/PatientGeneralFormHistory.jsx";
import PatientHistory from "./components/portals/patient/patient-history/PatientHistory.jsx";
import PatientForm from "./components/services/general-clinical-guidance/patient-symptom-form/PatientForm.jsx";
import PatientNotification from "./components/portals/patient/patient-notification/PatientNotification.jsx";
import PatientCart from "./components/portals/patient/patient-cart/PatientCart.jsx";
import DiagnosticsUserPortal from "./components/portals/patient/diagnostics-user-portal/DiagnosticsUserPortal.jsx";
import PharmacyUserPortal from "./components/portals/patient/pharmacy-user-portal/PharmacyUserPortal.jsx";

// Hospital Components
import HospitalRecordDetail from "./components/portals/hospital/hospital-record-detail/HospitalRecordDetail.jsx";
import HospitalRecordList from "./components/portals/hospital/hospital-record-list/HospitalRecordList.jsx";
import ChatMessage from "./components/portals/hospital/chat-message/ChatMessage.jsx";
import Appointment from "./components/portals/hospital/appointment/Appointment.jsx";
import PatientMedicationDetails from "./components/portals/hospital/patient-medication-details/PatientMedicationDetails.jsx";
import PrescriptionForm from "./components/portals/hospital/prescription-form/PrescriptionForm.jsx";
import HospitalServiceTable from "./components/portals/hospital/hospital-service-table/HospitalServiceTable.jsx";
import HospitalAddDoctorsTable from "./components/portals/hospital/hospital-add-doctors-table/HospitalAddDoctorsTable.jsx";
// Pharmacy Components
import MedicineDetailedInfo from "./components/portals/pharmacy/medicine-detailed-info/MedicineDetailedInfo.jsx";
import PharmacyPrescriptionReplay from "./components/portals/patient/pharmacy-prescription-replay/PharmacyPrescriptionReplay.jsx";
import PharmacistCart from "./components/portals/pharmacy/pharmacist-cart/PharmacistCart.jsx";
import NewMedicineForm from "./components/portals/pharmacy/new-medicine-form/NewMedicineForm.jsx";
import MedicineMainInfo from "./components/portals/pharmacy/medicine-main-info/MedicineMainInfo.jsx";
import PharmacyPortal from "./components/portals/pharmacy/pharmacy-portal/PharmacyPortal.jsx";
import PharmacyMedicineTable from "./components/portals/pharmacy/pharmacy-medicine-table/PharmacyMedicineTable.jsx";
import PharmacyProfile from "./components/portals/pharmacy/pharmacy-profile/PharmacyProfile.jsx";
// Diagnostic Components
import DiagnosticMaterialFullInformation from "./components/portals/diagnostic/diagnostic-material-full-info/DiagnosticMaterialFullInformation.jsx";
import DiagnosticEquipmentMainInfo from "./components/portals/diagnostic/diagnostic-material/diagnostic-equipment-main-info/DiagnosticEquipmentMainInfo.jsx";
import EquipmentPost from "./components/portals/diagnostic/diagnostic-material/equipment-post/EquipmentPost.jsx";
import EquipmentMainInfoForUser from "./components/portals/diagnostic/diagnostic-material/equipment-main-info-for-user/EquipmentMainInfoForUser.jsx";
import DiagnosticPortal from "./components/portals/diagnostic/diagnostic-portal/DiagnosticPortal.jsx";
import DiagnosticServiceTable from "./components/portals/diagnostic/diagnostic-service-table/DiagnosticServiceTable.jsx";
import DiagnosticProfile from "./components/portals/diagnostic/diagnostic-profile/DiagnosticProfile.jsx";

// Payment Components
import Payment from "./components/portals/payment/Payment.jsx";
import HospitalProfile from "./components/portals/hospital/hospital-profile/HospitalProfile.jsx";

// Spinner Display Component
const SpinnerDisplay = () => {
  const { loading } = useSpinner();
  return loading ? <SpinnerDotted /> : null;
};

function App() {
  const phone = localStorage.getItem("phone");
  const [redirected, setRedirected] = useState(false);
  const { authenticated, setAuthenticated } = useSession(false);
  const { isAccSidebarVisible, hideAccSidebar } = useAccSidebarToggle();
  const { setAuthUser } = useAccAuth();

  const toggleVisibility = (e) => {
    if (e.target.closest(".ant-select-dropdown")) return;
    if (e.target.closest(".ant-modal-root")) return;
    if (e.target.closest(".acc-sidebar")) return;
    if (e.target.closest(".ant-picker-dropdown")) return;
    hideAccSidebar();
  };

  const {
    data: authenticateRes,
    loading: fetchLoading,
    error: fetchError,
    firstTimeUse,
    redirect,
    getRequest,
  } = useFetch("/authenticate", { phone });

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (
      authenticateRes?.status === "success" &&
      authenticateRes?.firstTimeUserVerified
    ) {
      setAuthenticated(true);
      if (authenticateRes?.userAuth) {
        setAuthUser({ authenticated: true, role: authenticateRes?.role });
      }
    }
    if (authenticateRes?.status === "success" && authenticateRes?.redirect) {
      localStorage.setItem("authenticated", "no");
      setRedirected("/auth");
    }
  }, [authenticateRes, fetchError, redirect]);

  return (
    <Router>
      <div className="App">
        {isAccSidebarVisible && (
          <div className="acc-sidebar-container" onClick={toggleVisibility}>
            <AccSidebar />
          </div>
        )}
        <div>
          {fetchLoading ? (
            <Splash />
          ) : (
            <Routes>
              <Route path="/auth" element={<PhoneAuth />} />
              <Route path="/auth/verify" element={<VerificationPage />} />
              <Route path="/app/service" element={<Service />} />
              {(redirect || redirected) && (
                <Route
                  path="*"
                  element={
                    <Navigate to={`${redirect || redirected}`} replace />
                  }
                />
              )}
              {firstTimeUse && (
                <Route path="/app" element={<Navigate to="/auth" replace />} />
              )}
              {authenticated && (
                <>
                  <Route path="/app" element={<Home />} />
                  <Route
                    path="/app/signup_category"
                    element={<SignUpCategory />}
                  />
                  <Route path="/admin/*" element={<AdminPage />} />
                  {/* <Route path="/app/create_post" element={<Posts />} /> */}
                  <Route
                    path="/app/doctor_Profile"
                    element={<Profile ProfileComp={DoctorProfile} />}
                  />
                  <Route
                    path="/app/doctor_post_detail"
                    element={<Profile ProfileComp={DoctorPost} />}
                  />
                  <Route
                    path="/app/forgot_password"
                    element={<ForgotPassword />}
                  />
                  <Route path="/app/doctor_list" element={<DoctorList />} />
                  <Route path="/app/doctor_info/:id" element={<DoctorInfo />} />
                  <Route path="/app/patient_form" element={<PatientForm />} />
                  <Route
                    path="/app/patient/:id"
                    element={<PatientGeneralForm />}
                  />
                  <Route
                    path="/app/patient_history"
                    element={<PatientHistory />}
                  />
                  <Route
                    path="/app/patient_general_form_history"
                    element={<PatientGeneralFormHistory />}
                  />
                  <Route
                    path="/app/hospital_record_detail/:id"
                    element={<HospitalRecordDetail />}
                  />
                  <Route
                    path="/app/hospital_record_list"
                    element={<HospitalRecordList />}
                  />
                  <Route
                    path="/app/hospital_profile"
                    element={<Profile ProfileComp={HospitalProfile} />}
                  />
                  <Route
                    path="/app/hospital_service_detail"
                    element={<Profile ProfileComp={HospitalServiceTable} />}
                  />
                  <Route
                    path="/app/hospital_add_doctors_detail"
                    element={<Profile ProfileComp={HospitalAddDoctorsTable} />}
                  />

                  <Route
                    path="/app/patient_medication_details"
                    element={<PatientMedicationDetails />}
                  />
                  <Route path="/app/viewcart" element={<PatientCart />} />
                  <Route
                    path="/app/Prescription_form"
                    element={<PrescriptionForm />}
                  />
                  <Route path="/app/appointment" element={<Appointment />} />
                  <Route path="/app/chat_message" element={<ChatMessage />} />
                  <Route
                    path="/app/medicine/:id"
                    element={<Pharmacy PharmacyComp={MedicineMainInfo} />}
                  />
                  <Route
                    path="/app/findPharmacy"
                    element={PharmacyUserPortal}
                  />
                  <Route
                    path="/app/pharmacy"
                    element={<Pharmacy PharmacyComp={PharmacyPortal} />}
                  />
                  <Route
                    path="/app/pharmacy_profile"
                    element={<Profile ProfileComp={PharmacyProfile} />}
                  />
                  <Route
                    path="/app/pharmacy_medicine_detail"
                    element={<Profile ProfileComp={PharmacyMedicineTable} />}
                  />
                  <Route
                    path="/app/medicineForm"
                    element={<Pharmacy PharmacyComp={NewMedicineForm} />}
                  />
                  <Route
                    path="/app/pharmacistCart"
                    element={<Pharmacy PharmacyComp={PharmacistCart} />}
                  />
                  <Route
                    path="/app/detailInformationForm/:id"
                    element={
                      <Pharmacy PharmacyComp={PharmacyPrescriptionReplay} />
                    }
                  />
                  <Route
                    path="/app/notificationCenter"
                    element={<Pharmacy PharmacyComp={PatientNotification} />}
                  />
                  <Route path="/app/payment/:id" element={<Payment />} />
                  <Route
                    path="/app/medicineFullInformation/:id"
                    element={<Pharmacy PharmacyComp={MedicineDetailedInfo} />}
                  />
                  <Route
                    path="/app/diagnostic_center_suggestion"
                    element={<DiagnosticsUserPortal />}
                  />
                  <Route
                    path="/app/diagnosticPortal"
                    element={<DiagnosticPortal />}
                  />
                  <Route
                    path="/app/material/:id"
                    element={<DiagnosticEquipmentMainInfo />}
                  />
                  <Route
                    path="/app/diagnostic_service_detail"
                    element={
                      <DiagnosticPage
                        DiagnosticPageComp={DiagnosticServiceTable}
                      />
                    }
                  />
                  <Route
                    path="/app/diagnostic_profile"
                    element={
                      <DiagnosticPage DiagnosticPageComp={DiagnosticProfile} />
                    }
                  />
                  <Route
                    path="/app/equipmentForm/:id"
                    element={<EquipmentPost />}
                  />
                  <Route
                    path="/app/equipment/:id"
                    element={<EquipmentMainInfoForUser />}
                  />
                  <Route
                    path="/app/materialFullInformation/:id"
                    element={<DiagnosticMaterialFullInformation />}
                  />
                  {!firstTimeUse && !redirect && (
                    <Route path="/" element={<Navigate to="/app" replace />} />
                  )}
                </>
              )}
            </Routes>
          )}
        </div>
        <div className="AppSpinner">
          <SpinnerDisplay />
        </div>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
