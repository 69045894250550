// ModalComponent.jsx
import React from "react";
import { Modal } from "antd";

const ModalComponent = ({
  open,
  onClose,
  Component,
  componentProps,
  title,
}) => {
  const closeModal = onClose;
  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null}>
      <Component {...componentProps} closeModal={closeModal} />
    </Modal>
  );
};

export default ModalComponent;
