import React, { createContext, useState, useContext } from "react";

const AdminAccAuthContext = createContext();

export const AdminAccAuthProvider = ({ children }) => {
  const [authAdmin, setAuthAdmin] = useState({});

  return (
    <AdminAccAuthContext.Provider value={{ authAdmin, setAuthAdmin }}>
      {children}
    </AdminAccAuthContext.Provider>
  );
};

export const useAdminAccAuth = () => useContext(AdminAccAuthContext);
