import React, { useState, useEffect, cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import "./Verification.css";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import useToast from "../toast/toast";
import { useSession } from "../../../contexts/sessionData";
const FORM_COMP = "FORM";
const AUTH_COMP = "VERIFICATION";
const UserVerification = ({ setCurr }) => {
  const { showToast } = useToast();
  const { authenticated, setAuthenticated } = useSession(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const phone = localStorage.getItem("phone");
  const navigate = useNavigate();

  let timerLength = 10;
  const [timer, setTimer] = useState(timerLength);
  const [authCode, setAuthCode] = useState("");
  const [isResendVisible, setResendVisible] = useState(false);
  const {
    data: resendResp,
    loading: resendLoading,
    error: resendError,
    getRequest,
  } = useFetch("/api/users/auth/resendCode");
  const {
    data: postApi,
    loading: postLoading,
    error: postError,
    post,
  } = usePost("/api/users/create/verify");

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendVisible(true);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (resendResp?.status === "success") {
      setTimer(timerLength);
      setResendVisible();
    }

    if (resendError) {
      console.log(resendError);
      setResendVisible();
    }
  }, [resendResp, resendError]);

  // check for code

  useEffect(() => {
    if (postApi) {
      console.log(postApi);
      if (postApi?.redirect) {
        setCurr(FORM_COMP);
        navigate("/app");
      }
      if (postApi?.status && postApi?.msg) {
        showToast(postApi.msg);
      }
    }

    if (postError) {
      if (postError?.data?.redirect) setCurr(FORM_COMP);
    }
  }, [postApi, postError]);

  useEffect(() => {
    if (authCode.length >= 6) {
      post({ code: authCode });
    }
  }, [authCode]);

  const handleResend = async (e) => {
    e.preventDefault();
    getRequest();
  };
  const handleSubmit = () => {
    console.log(authCode);
    if (authCode.length >= 6) post({ code: authCode });
  };

  const handleCodeComplete = () => {
    setButtonEnabled(true);
  };

  const handleCodeChange = (value) => {
    setAuthCode(value);
  };

  return (
    <>
      <div id="verification">
        <div className="main-container">
          <div id="form-container">
            <form id="verification-form">
              <div className="from-heading">
                <h2>Verify</h2>
              </div>
              <div id="verification-input-group">
                <VerificationInput
                  validChars="0-9"
                  inputProps={{ inputMode: "numeric" }}
                  length={6}
                  classNames={{
                    container: "box-container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",
                  }}
                  onChange={handleCodeChange}
                  onComplete={handleCodeComplete}
                />

                {buttonEnabled && (
                  <button type="button" onClick={handleSubmit}>
                    Verify
                  </button>
                )}
              </div>
              {isResendVisible ? (
                <p style={{ marginTop: "1rem" }}>
                  didn't get the code?{" "}
                  <a
                    style={{ color: "blue", cursor: "pointer" }}
                    className="p-resend"
                    onClick={handleResend}
                  >
                    resend
                  </a>
                </p>
              ) : (
                <p className="">Resend Code in {timer} seconds</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserVerification;
