import React from "react";
import { Form, Input, Select, Button } from "antd";
import { LocationRequest } from "../location-selector/LocationSelector.jsx";
import { handleFilterNumber } from "../../../utils/helper.js";

const PatientSignUpForm = ({
  form,
  handleSubmit,
  submitAllowed = true,
  createLoading,
  PhoneNumberInput,
}) => {
  const handleKeyPress = (e) => {
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Form
      className="sign-up-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      {PhoneNumberInput && <PhoneNumberInput form={form} />}
      <Form.Item
        label="Full Name"
        name="fullName"
        rules={[{ required: true, message: "Please fill your name!" }]}
      >
        <Input placeholder="Full Name" />
      </Form.Item>

      <Form.Item
        label="Age"
        name="age"
        rules={[{ required: true }]}
        onKeyPress={handleKeyPress}
        placeholder="Age"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Sex"
        name="sex"
        rules={[{ required: true, message: "Please select your sex!" }]}
      >
        <Select showSearch>
          <Select.Option value=""></Select.Option>
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "Please fill your Email!" },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default PatientSignUpForm;
