import { Form, Select, DatePicker, message } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";

const MultipleDatePickerWithTags = ({ form, initialDates }) => {
  const [selectedDates, setSelectedDates] = useState([]);

  // Set the initial form values when the component mounts
  useEffect(() => {
    if (initialDates) {
      const formattedDates = initialDates.map((date) =>
        moment(date).format("YYYY-MM-DD HH:mm")
      );
      setSelectedDates(formattedDates);
      form.setFieldsValue({ dates: formattedDates });
    }
  }, [form, initialDates]);

  const handleDateSelect = (date) => {
    if (date && date.format("HH:mm") !== "00:00") {
      // Check if time is selected
      const dateString = date.format("YYYY-MM-DD HH:mm");
      if (!selectedDates.includes(dateString)) {
        const updatedDates = [...selectedDates, dateString];
        setSelectedDates(updatedDates);
        form.setFieldsValue({ dates: updatedDates });
      }
    } else {
      message.warning("Please select both date and time.");
    }
  };

  const handleTagChange = (values) => {
    setSelectedDates(values);
    form.setFieldsValue({ dates: values });
  };

  return (
    <Form.Item
      label="Select Dates and Times"
      name="dates"
      rules={[
        { required: true, message: "Please select your dates and times!" },
      ]}
    >
      <>
        <DatePicker
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={handleDateSelect}
          style={{ width: "100%" }}
        />
        <Select
          mode="tags"
          placeholder="Selected dates and times"
          value={selectedDates}
          onChange={handleTagChange}
          style={{ width: "100%", marginTop: "10px" }}
        >
          {selectedDates.map((date) => (
            <Select.Option key={date} value={date}>
              {date}
            </Select.Option>
          ))}
        </Select>
      </>
    </Form.Item>
  );
};

export default MultipleDatePickerWithTags;
