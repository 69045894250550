import { useCallback, useState } from "react";
import { getApi } from "../services/api";
const useFetchUsers = (endpoint, initialParams = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(
    async (params = initialParams) => {
      try {
        setLoading(true);
        const result = await getApi(endpoint, params);
        console.log(result);

        if (result.users) {
          return setData(result);
        }
      } catch (err) {
        console.error(err);
        setData([]);
      } finally {
        setLoading(false);
      }
    },
    [endpoint, initialParams]
  );
  return {
    data: data?.users || [],
    total: data?.total || 0,
    loading,
    fetchUsers,
  };
};

export default useFetchUsers;
