import { useState, useCallback } from "react";
import { useSpinner } from "../contexts/spinnerContext.js";
import { getApi } from "../services/api";

const useFetch = (endpoint, initialParams = {}) => {
  const [data, setData] = useState(null);
  const [firstTimeUse, setFirstTimeUse] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showSpinner, hideSpinner } = useSpinner();

  const getRequest = useCallback(
    async (params = initialParams) => {
      try {
        showSpinner();
        setLoading(true);
        setError(null);
        const result = await getApi(endpoint, params);
        console.log("---------result------", result);
        if (result?.status === "success" && result?.redirect)
          return setRedirect(result.redirect);

        if (result?.status === "success") {
          return setData(result);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        hideSpinner();
      }
    },
    [endpoint, initialParams, showSpinner, hideSpinner]
  );

  return {
    data,
    loading,
    error,
    firstTimeUse,
    redirect,
    getRequest,
  };
};

export default useFetch;
