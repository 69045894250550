import React from "react";

import PhoneNumberInput from "../../common/phoneInput/PhoneInput.jsx";
import DoctorForm from "../../common/update-forms/Doctor.jsx";
import PatientForm from "../../common/update-forms/Patient.jsx";
import HospitalForm from "../../common/update-forms/Hospital.jsx";
import PharmacyForm from "../../common/update-forms/Pharmacy.jsx";
import DiagnosticsForm from "../../common/update-forms/Diagnostics.jsx";

const AdminUpdate = ({ role, form }) => {
  const formProps = {
    form,
    PhoneNumberInput,
  };

  const renderForm = () => {
    switch (role) {
      case "patient":
        return <PatientForm {...formProps} />;
      case "doctor":
        return <DoctorForm {...formProps} />;
      case "hospital":
        return <HospitalForm {...formProps} />;
      case "pharmacy":
        return <PharmacyForm {...formProps} />;
      case "diagnostic_center":
        return <DiagnosticsForm {...formProps} />;
      default:
        return null;
    }
  };
  return <>{renderForm(role)}</>;
};

export default AdminUpdate;
