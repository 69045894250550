import { React, useState, useEffect } from "react";
import useFetch from "../../../../hooks/useFetch.js";
import { useParams } from "react-router-dom";
import { notificationList } from "../../../../services/data";
import "./MedicineDetailedInfo.css";

function MedicineDetailedInfo() {
  const { id } = useParams();
  const selectedMedicine = notificationList.find(
    (medicine) => medicine.medicineId == id
  );

  return (
    <>
      <div id="full-information">
        <div id="head" className="Info-container">
          <h2>Here is your medicine . . . </h2>
          <div id="content-wrapper">
            <div className="right-container">
              <img src={selectedMedicine?.image} alt={selectedMedicine?.name} />
            </div>
            <div className="left-container">
              <h3>Name: {selectedMedicine?.name}</h3>
              <p>Dosage: {selectedMedicine?.dosage}</p>
              <p>It cures from: {selectedMedicine?.pain}</p>
              <h4>Description</h4>
              <p>{selectedMedicine?.desc}</p>
              <p>Price: {selectedMedicine?.price}</p>
              <p>Address: {selectedMedicine?.address}</p>
              <p>Location: {selectedMedicine?.location}</p>
              <p>Phone-Number: {selectedMedicine?.phone}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedicineDetailedInfo;
