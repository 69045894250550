import { React, useState, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import ErrorPage from "../../../../common/error-page/ErrorPage";
// import {
//   hospitalData,
//   diagnosticMaterialData,
// } from "../../../../../services/data";
import "./DiagnosticEquipmentMainInfo.css";

function DiagnosticEquipmentMainInfo() {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState();
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");
  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const { id } = useParams();
  const selectedMaterial = diagnosticMaterialData.find(
    (equip) => equip.postId == id
  );

  return (
    <>
      {diagnosticMaterialError && <ErrorPage />}
      {selectedMaterial && (
        <div id="equipmentDetail">
          <h2> Equipment Detail</h2>
          <div id="content-wrapper">
            <div className="right-container">
              <img
                src={selectedMaterial?.image}
                alt={selectedMaterial?.title}
              />
            </div>
            <div className="left-container">
              <h3>{selectedMaterial?.title}</h3>
              {selectedMaterial?.detail.map((detail) => {
                const { description, name } = detail;
                return (
                  <p>
                    {" "}
                    <strong>{name}: </strong> {description}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DiagnosticEquipmentMainInfo;
