import React from "react";
import { Upload, Button, Form, Input, Select, message, DatePicker } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import {
  diagnosticServiceFormFields,
  categoryFormOptions,
  fieldFormOptions,
  serviceTypeFormOptions,
} from "../../../../services/data";
const { Option } = Select;

const AdminServiceForm = ({
  form,
  handleModalOk,
  onChange,
  onPreview,
  fileList,
}) => {
  return (
    <>
      <Form
        className=" "
        onFinish={handleModalOk}
        form={form}
        layout="vertical"
      >
        {diagnosticServiceFormFields.map((field) => (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            rules={[
              {
                required: true,
                message: `Please fill the ${field.label.toLowerCase()}!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ))}
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please type description!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Select service area"
          name="category"
          rules={[
            {
              required: true,
              message: "Please select a service area!",
            },
          ]}
        >
          <Select>
            {categoryFormOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select service type"
          name="serviceType"
          rules={[
            {
              required: true,
              message: "Please select a service type!",
            },
          ]}
        >
          <Select placeholder="Please select an option">
            {serviceTypeFormOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Available Date"
          name="availableDate"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker
            placeholder="Select available date"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Post image"
          name="image"
          rules={[
            {
              validator: (_, value) => {
                if (fileList.length === 0) {
                  return Promise.reject(new Error("Please upload an image!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false} // Prevent auto-upload
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}> Upload</Button>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </>
  );
};

export default AdminServiceForm;
