import React, { useState, useEffect } from "react";
import Footer from "../../../common/footer/Footer";
import Sidebar from "../../../common/sidebar/Sidebar";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

function ChatMessageComponent() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [currentUser, setCurrentUser] = useState("User1");

  useEffect(() => {
    // Load messages from local storage when the component mounts
    const storedMessages =
      JSON.parse(localStorage.getItem("chatMessages")) || [];
    setMessages(storedMessages);
  }, []);

  const sendMessage = (text) => {
    if (text.trim()) {
      const newMessage = {
        message: text,
        sentTime: new Date().toLocaleTimeString(), // Include the sent time
        sender: currentUser,
        direction: currentUser === "User1" ? "outgoing" : "incoming",
      };

      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);
      // Save the updated messages to local storage
      localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
      setInputValue(""); // Clear the input field
    }
  };

  // Function to toggle between User1 and User2
  const toggleUser = () => {
    setCurrentUser(currentUser === "User1" ? "User2" : "User1");
  };

  return (
    <>
      <Sidebar />
      <div className="container">
        <button className="btn btn-primary" onClick={toggleUser}>
          Toggle User
        </button>
        <div style={{ position: "relative", height: "500px" }}>
          <MainContainer>
            <ChatContainer>
              <ConversationHeader>
                <ConversationHeader.Content
                  userName={currentUser === "User1" ? "User2" : "User1"}
                />
              </ConversationHeader>

              <MessageList>
                {messages.map((msg, index) => (
                  <Message
                    key={index}
                    model={{
                      ...msg,
                      direction:
                        msg.sender === "User1" ? "outgoing" : "incoming",
                    }}
                  ></Message>
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                value={inputValue}
                onChange={(val) => setInputValue(val)}
                onSend={() => sendMessage(inputValue)}
              />
              {/* Button to toggle between users */}
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChatMessageComponent;
