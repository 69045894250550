import React, { useState, useEffect, cloneElement } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";

import usePost from "../../../hooks/usePost";
import useToast from "../toast/toast";

const PhoneAuth = () => {
  const navigate = useNavigate();

  const [phone, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const { showToast } = useToast();
  const [postApi, setPostApi] = useState();
  const [postLoading, setPostLoading] = useState();
  const [postError, setPostError] = useState();
  const { post } = usePost("/auth/code", {
    setData: setPostApi,
    setLoading: setPostLoading,
    setError: setPostError,
  });

  // Load phone number from localStorage on component mount
  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phone");
    if (storedPhoneNumber) {
      setValue(storedPhoneNumber);
    }
  }, []);

  useEffect(() => {
    if (postError) showToast(postError.message?.replace("AxiosError:", ""));
  }, [postError]);

  useEffect(() => {
    console.log(postApi);
    if (
      postApi &&
      postApi.status === "success" &&
      postApi.redirect &&
      postApi.accessToken
    ) {
      localStorage.setItem("firstTimeUseToken", postApi.accessToken);
      navigate(postApi.redirect);
    }
  }, [postApi]);

  useEffect(() => {
    if (!phone) {
      setError("  ");
      setIsValid(false);
    } else if (!isPossiblePhoneNumber(phone)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [phone]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone) localStorage.setItem("phone", phone);

    if (isValid) {
      post({ phone });
    } else {
      showToast("Invalid phone number");
    }
  };

  return (
    <>
      <div id="Phone-auth">
        <div className="container">
          <div className="form-container">
            <form action="#" method="" className="form form-wrapper">
              <div id="form-group">
                <h4>Phone</h4>
                <div className="phone-input-wrapper">
                  <PhoneInput
                    className="phone"
                    style={{ border: "none" }}
                    placeholder="Enter phone"
                    name="phoneInput"
                    defaultCountry="ET"
                    phone={phone}
                    value={phone}
                    onChange={handleChange}
                  />
                </div>

                <button
                  className="btn"
                  type="submit"
                  style={{
                    backgroundColor: isValid ? "#02959c" : "#4444",
                  }}
                  onClick={handleSubmit}
                >
                  Confirm
                </button>

                <a href="tel:9080">Need Help? Call 9080</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneAuth;
