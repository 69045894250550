import React from "react";
import { Form, Input, Select, Button, TimePicker } from "antd";
import { locationLists } from "../../../services/data.js";
import { handleFilterNumber } from "../../../utils/helper.js";
import { LocationRequest } from "../location-selector/LocationSelector.jsx";
import WorkTimePicker from "../work-time-picker/WorkTimePicker.jsx";
const PharmacySignUp = ({
  form,
  handleSubmit,
  submitAllowed = true,
  setSubmitAllowed,
  createLoading,
  PhoneNumberInput,
  initialValues,
}) => {
  return (
    <Form
      className="sign-up-form"
      form={form}
      onChange={() => setSubmitAllowed(true)}
      layout="vertical"
      onFinish={handleSubmit}
    >
      {PhoneNumberInput && <PhoneNumberInput form={form} />}
      <Form.Item
        label="Pharmacy name"
        name="organizationName"
        rules={[{ required: true, message: "Please fill the Pharmacy name!" }]}
      >
        <Input placeholder="Pharmacy name" />
      </Form.Item>

      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please fill the address!" }]}
      >
        <Select showSearch>
          {locationLists.map((place) => (
            <Select.Option key={place}>{place}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Branch Number"
        name="branchNumber"
        rules={[{ required: true, message: "Please fill your Branch Number!" }]}
        onKeyPress={handleFilterNumber}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "Please fill your email!" },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please fill the password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[{ required: true, message: "Please confirm your password!" }]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>
      <WorkTimePicker form={form} />

      <Form.Item
        label="Location"
        name="location"
        rules={[{ required: true, message: "Please add your location!" }]}
      >
        <LocationRequest form={form} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={createLoading}>
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PharmacySignUp;
