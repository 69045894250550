import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePost from "../../../hooks/usePost";
import { Form } from "antd";

import useToast from "../toast/toast";
import UserVerification from "../verification/UserVerification.jsx";
import { FORM_COMP, AUTH_COMP } from "../../../config/config.js";
import DoctorForm from "./Doctor.jsx";
import PatientForm from "../sign-up-forms/Patient.jsx";
import HospitalForm from "../sign-up-forms/Hospital.jsx";
import PharmacyForm from "../sign-up-forms/Pharmacy.jsx";
import DiagnosticsForm from "../sign-up-forms/Diagnostics.jsx";

const Registration = ({ role, handleShowLogin }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [form] = Form.useForm();
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [currentComponent, setCurrentComponent] = useState(FORM_COMP);
  const [createRes, setCreateRes] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createRedirect, setCreateRedirect] = useState(null);

  const { post, reset: resetPost } = usePost("/api/users/create", {
    setData: setCreateRes,
    setLoading: setCreateLoading,
    setError: setCreateError,
  });

  const handleSubmit = async (values) => {
    values.role = role;
    console.log(role, values);
    await form.validateFields();

    await post(values);

    if (createRedirect) {
      navigate(createRedirect);
    }
  };

  const disableSubmitButton = () => {
    setSubmitAllowed(false);
  };
  const enableSubmitButton = () => {
    setSubmitAllowed(true);
  };

  useEffect(() => {
    console.log(createLoading, createError, createRes);
    if (createError) {
      showToast(createError.message?.replace("AxiosError:", ""));
      enableSubmitButton();
    }
    if (createRes) showToast(createRes.status);
    if (createRes?.status === "success") handleShowLogin();
    if (createLoading) disableSubmitButton();

    if (createError || createRes) resetPost();
  }, [createLoading, createError, createRes]);

  const formProps = {
    form,
    handleSubmit,
    submitAllowed,
    setSubmitAllowed,
    createLoading,
  };

  const renderForm = () => {
    switch (role) {
      case "patient":
        return <PatientForm {...formProps} />;
      case "doctor":
        return <DoctorForm {...formProps} />;
      case "hospital":
        return <HospitalForm {...formProps} />;
      case "pharmacy":
        return <PharmacyForm {...formProps} />;
      case "diagnostic_center":
        return <DiagnosticsForm {...formProps} />;
      default:
        return null;
    }
  };
  return (
    <>
      {currentComponent === FORM_COMP && renderForm(role)}
      {currentComponent === AUTH_COMP && (
        <UserVerification setCurr={setCurrentComponent} />
      )}
    </>
  );
};

export default Registration;
