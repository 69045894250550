import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  medicineData,
  cartItems,
  notificationList,
} from "../../../../services/data";
import "./PharmacyPrescriptionReplay.css";

function PharmacyPrescriptionReplay() {
  const { id: medicineId } = useParams();

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false); //for the popup message

  const [formData, setFormData] = useState({
    price: "",
    address: "",
    phone: "",
    location: "Ayertena",
    desc: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddToNotificationClick = () => {
    setShowConfirmationPopup(true);
  };
  const handleClosePopup = () => {
    setShowConfirmationPopup(false); // Hide the confirmation popup
  };

  const handleSubmit = (e) => {
    const selectedMedicine = cartItems.find(
      (medicine) => medicine.id === medicineId
    );

    const compId = Math.random().toString(36).substring(2, 15);

    const notificationLists = {
      ...selectedMedicine,
    }; // Create a copy

    console.log(notificationLists);
    // Add the item to the cart (if not already done in handleAddToCartClick)
    setShowConfirmationPopup(false); // Hide the confirmation popup after confirmation
    e.preventDefault();
    const replayData = {
      id: notificationLists.id,
      name: notificationLists.name,
      dosage: notificationLists.dosage,
      pain: notificationLists.pain,
      image: notificationLists.image,
      price: formData.price,
      address: formData.address,
      phone: formData.phone,
      location: formData.location,
      desc: formData.desc,
    };

    notificationList.push(replayData);
    console.log(notificationList);
  };

  return (
    <>
      <div id="give-feedback">
        <div className="medicine-container">
          <div className="container">
            <div className="medicine-form-container">
              <form className="form">
                <h2>Detail Medicine Information </h2>

                <div>
                  <label htmlFor="name" name="medicine name">
                    Medicine Price
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    name="price"
                    id="price"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="name" name="medicine name">
                    Pharmacy Address
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="name" name="medicine name">
                    Phone Number
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="image" name="medicine name">
                    Map Location
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    name="image"
                    value="Ayer Tena"
                    onChange={(e) => {}}
                  />
                </div>
                <div>
                  <label htmlFor="name" name="medicine name">
                    Other Description
                  </label>{" "}
                  <br />
                  <textarea
                    name="desc"
                    id="desc"
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="add-to-cart-button">
                  <button type="button" onClick={handleAddToNotificationClick}>
                    Submit
                  </button>
                </div>
              </form>
              <div className="popup">
                {showConfirmationPopup && (
                  <div id="confirmation-popup">
                    <div className="popup-container">
                      <h3 className="popup-title">Confirm Action</h3>
                      <p className="popup-message">
                        Are you sure you want to send a replay?
                      </p>
                      <div className="button-container">
                        <button
                          id="confirm-button"
                          className="primary-button"
                          onClick={handleSubmit}
                        >
                          Yes
                        </button>
                        <button
                          id="cancel-button"
                          className="secondary-button"
                          onClick={handleClosePopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PharmacyPrescriptionReplay;
