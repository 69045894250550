import { React, useState, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch.js";
import { Link, useParams } from "react-router-dom";
import "./EquipmentMainInfoForUser.css";
import ErrorPage from "../../../../common/error-page/ErrorPage";

function EquipmentMainInfoForUser() {
  const [diagnosticMaterialData, setDiagnosticMaterialData] = useState([]);
  const [diagnosticMaterialDataErr, setDiagnosticMaterialDataErr] =
    useState(false);
  const {
    data: diagnosticMaterialDataRes,
    loading: diagnosticMaterialLoading,
    error: diagnosticMaterialError,
    getRequest,
  } = useFetch("/api/diagnosticMaterials");

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (diagnosticMaterialDataRes?.data) {
      setDiagnosticMaterialData(diagnosticMaterialDataRes.data);
    }
    if (diagnosticMaterialError) {
      setDiagnosticMaterialDataErr(true);
    }
  }, [diagnosticMaterialDataRes, diagnosticMaterialError]);

  const { id } = useParams();
  console.log("id", id);

  const selectedMaterial = diagnosticMaterialData?.find(
    (equip) => equip?.postId == id
  );

  return (
    <>
      {diagnosticMaterialDataErr && <ErrorPage />}
      {selectedMaterial && (
        <div id="equipmentInfo">
          <div className="materialContainer">
            <h2>Material Detail</h2>
            <div className="underline"></div>
            <div id="content-wrapper">
              <div className="right-container">
                <img
                  src={selectedMaterial?.image}
                  alt={selectedMaterial?.title}
                />
              </div>
              <div className="left-container">
                <h3>{selectedMaterial?.title}</h3>
                {selectedMaterial?.detail.map((detail, index) => {
                  const { name, description, postId } = detail;
                  return (
                    <p key={index}>
                      <strong>{name}: </strong> {description}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="button-container-mat">
              <Link to={`/app/payment/${selectedMaterial?.postId}`}>
                Detail
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EquipmentMainInfoForUser;
